import React from "react";
const HistoryItem = (props) => {
    const dateStyles = {
        "fontSize": 'x-small',
    }

    return (

        <tr key={props.item.id + 'historyItem'}
            style={dateStyles}>
            <td>{props.item.date_created}</td>
            <td>{props.item.fortimanager_name}</td>
            <td>{props.item.lic_file}</td>
            <td>{props.item.user_full_name}</td>
        </tr>
    );
}

export default HistoryItem;