import Api from '.';
import { limit } from '../utils/constants';
export default class AssetService {

    static async getList(id, filters, nextpage) {
        var url =  'api/v1/assets/'
        const method = 'GET';
        const params = {};
        params.limit = limit
       
        if (filters) {
            if (filters.platformFilter) {
                params.platform__id = filters.platformFilter.id;

            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.cityFilter) {
                params.city = filters.cityFilter;
            };
            if (filters.countryFilter) {
                params.country = filters.countryFilter;
            };
            if (filters.slugFilter) {
                params.slug = filters.slugFilter.map((item) =>   item).join(',')              
            };
            if (filters.rmaFilter) {

                params.rma = filters.rmaFilter;
            };
        };
   
        if (nextpage) {
            params.page = nextpage;
        }
        if (id) {
            params.page='all';
            params.organisation__id = id
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newAsset(asset) {

        const url = 'api/v1/assets/';
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getStats() {

        const url = 'api/v1/asset-stats/';
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async getCities(filters) {

        var url = 'api/v1/asset-cities/?page=all'
        const method = 'GET';
        const params = {};

        if (filters) {
            params.search = filters.searchText;
        };

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async getCountries(filters) {

        var url = 'api/v1/asset-countries/?page=all'
        const method = 'GET';
        const params = {};

        if (filters) {
            params.search = filters.searchText;
        };

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async getItem(id) {

        const url = `api/v1/assets/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async patchAsset(asset) {
        const url  = `api/v1/assets/${asset.id}/`;
        const data =asset;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    
    static async postSubAsset(id, sub) {
        const url  = `api/v1/assets/${id}/subscriptions/`;
        const data =sub;
        const method = 'POST';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }

    static async patchSubAsset(id, subId, sub) {
        const url  = `api/v1/assets/${id}/subscriptions/${subId}/`;
        const data =sub;

        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
  
    static async deleteSubAsset(id, subId ) {
        const url  = `api/v1/assets/${id}/subscriptions/${subId}/`;
     

        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }

    static async postServiceAsset(id, service) {
        const url  = `api/v1/assets/${id}/service/`;
        const data =service;
        const method = 'POST';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }

    static async deleteServiceAsset(id, serviceId ) {
        const url  = `api/v1/assets/${id}/service/${serviceId}/`;
        
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }

    static async patchServiceAsset(id, serviceId, service) {
        const url  = `api/v1/assets/${id}/service/${serviceId}/`;
        const data =service;

        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    static async postCloudAsset(id, cloud) {
        const url  = `api/v1/assets/${id}/clouds/`;
        const data =cloud;
        const method = 'POST';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }

    static async deleteCloudAsset(id, cloudId ) {
        const url  = `api/v1/assets/${id}/clouds/${cloudId}/`;
     

        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }

    static async patchCloudAsset(id, cloudId, cloud) {
        const url  = `api/v1/assets/${id}/clouds/${cloudId}/`;
        const data =cloud;

        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    static async getSlug() {

        var url = 'api/v1/asset-slugs/?page=all'
        const method = 'GET';
      

        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    };

    static async dublicateAsset(id, serial) {
        const url  = `api/v1/assets/${id}/duplicate/`;
        const data ={};
        data.serial_nr = serial;
        const method = 'POST';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    static async deleteAsset(id ) {
        const url  = `api/v1/assets/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }
}