import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Colors } from 'chart.js';

function DashboardQuota(props) {

    const [quotasStats, setQuotasStats] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
           await DashboardService.getQuotas().then((res)=>setQuotasStats(res)).catch((e) => changeStatus());
           
        })()
    }, [])
    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, Colors);


 const data = {
        labels: ['EXPIRED', 'ACTIVE', 'SOLD'],
        datasets: [
           {
          datalabels: {
                color: 'black',
                opacity: 0
      
              },
     
          data: quotasStats !==null && [quotasStats.expired_count, quotasStats.active_count,quotasStats.sold_count] ,
         
            backgroundColor: [
              'rgba(255, 99, 132)',
   
              'rgba(75, 192, 192)',
              'rgba(153, 102, 255)',
         
            ],
            borderColor: [
              'rgba(255, 255, 255, 1)',
     
            ],
            borderWidth: 1,
          },
        ],
      };
      const options= {
        colors: {
            enabled: true,
            forceOverride: true
          },
        plugins: {
            datalabels: {
                color: '#36A2EB'
              },
            legend: {
                position: 'top' ,
                labels:{ boxWidth: 20}

            },
          tooltip: {
              enabled: true
          },
          
        }

      }

    return (

        <div style={{position:'relative', width: '100%', height: '100%'}}>
           
            <p style={{position:"absolute", top: "45%", left: "48%",  fontSize: '30px',color:'rgb(13, 110, 253, .8)'}}><b>{quotasStats?.total_count}</b></p>
            <Doughnut  data={data} options={options} />
        </div>
    );
}
export default DashboardQuota;