import React, { useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Auth from "../../API/Auth";
import Alert from 'react-bootstrap/Alert';

function ChangePasswordForm(props) {

    const passInputRef = useRef();
    const newPassInputRef = useRef();
    const confirmPassInputRef = useRef();
    const [error, setError] = useState([]);
    const [message, setMessage] = useState(false);
    const [passwordChanging, setPasswordChanging] = useState(true);

    const changePassword = async event => {
        event.preventDefault();
        
        const curPass = passInputRef;
        const newPass = newPassInputRef;
        const confirmPass = confirmPassInputRef;

        if (newPass.current.value !== confirmPass.current.value) {

            setError(["Введенные новые пароли не совпадают"]);
            setMessage(false);
        } else {
            try {
                const status = await Auth.passSet(newPass.current.value, curPass.current.value);
                if (status === 204) {
                    setError([]);
                    setMessage("Ваш пароль был успешно изменен");
                    setPasswordChanging(false);
                } else {
                    setError([`Ошибка статус ${status}`]);
                }
                
            } catch(e) {
                setError(e.message);
            };
        }
        
        // props.setCategoryAdding(false)
        // props.setCategoryAdded(true)
    }
    
    
    return (
        <Form onSubmit={changePassword} >
            {error.length > 0 &&
                error.map(item =>
                    <Alert key="danger" variant="danger">
                        {item}
                    </Alert>
                )
            }
            {message &&
                    <Alert key="primary" variant="primary">
                        {message}
                    </Alert>
            }
            <Form.Group className="mb-4">
                <Form.Label>Старый пароль</Form.Label>
                <Form.Control ref={passInputRef} size="sm" type="password" placeholder="Введите текущий пароль.." />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Новый пароль</Form.Label>
                <Form.Control ref={newPassInputRef} size="sm" type="password" placeholder="Введите новый пароль.." />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Повторите новый пароль</Form.Label>
                <Form.Control ref={confirmPassInputRef} size="sm" type="password" placeholder="Повторите новый пароль.." />
            </Form.Group>
            {
                passwordChanging &&
                    <Button variant="outline-primary" size="sm" type="submit">
                        Сохранить изменения
                    </Button>
            }
            
            <Button variant="outline-danger" size="sm" onClick={(e) => {e.preventDefault(); props.setVisible(false); setError([]); setMessage(false); setPasswordChanging(false);}}>
                Закрыть
            </Button>
        </Form>
    );
}
export default ChangePasswordForm;