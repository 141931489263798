import React from "react";
import Table from 'react-bootstrap/Table';
import QuotaPriceItem from "./QuotaPriceItem";


const QuotaItemsList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <Table striped bordered hover>
            <thead style={basicStyles}>
                <tr>
                    <th>Парт-номер</th>
                    <th>Наименование</th>
                    <th>Цена за ед. USD</th>
                    <th width={75}>Скидка</th>
                    <th width={75}>Кол-во</th>
                    <th>Сумма USD</th>
                    <th></th>
                </tr>
            </thead>
            <tbody style={basicStyles}>
                {props.quotaItems?.map((item, index) =>
                    <QuotaPriceItem
                        key={item.id + 'q' + index}
                        item={item}
                        index={index}
                        removeItem={props.removeItem}
                        changeItemQty={props.changeItemQty}
                        changeDiscount={props.changeDiscount}
                    />
                )}
                <tr>
                    <td colSpan={6}>
                        <b>Сумма GPL: {props.quotaSum[0]} USD</b>
                        <br />
                        <span style={{ color: "green" }}><b>Сумма со скидкой: {
                            props.quotaSum[1]
                        } USD</b></span>
                        <br />
                        <span style={{ color: "green" }}><b>Сумма продажи руб.: {

                            ((props.quotaSum[1] * props.currencyRate).toFixed(2))


                        } </b></span>
                    </td>
                </tr>
            </tbody>

        </Table>
    )
}

export default QuotaItemsList;