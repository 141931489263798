import React, { useEffect, useState, useContext } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AuthContext } from "../../context";

function DashboardSubDoug() {

    const [subsStats, setSubsStats] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
          await DashboardService.getSubsStats().then((res)=> setSubsStats(res)).catch((e) => changeStatus());
  
        })()
    }, [])
    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


 const data = {
         labels: subsStats!== null ? subsStats.map((item, index)=>{return item.slug}) : '',
        datasets: [
           {
          datalabels: {
                color: 'black',
                opacity: 0

              },
    
          data: subsStats !==null && subsStats.map((item, index)=>{return item.equipment_cnt}),
           
            borderWidth: 1,
          },
        ],
      };
      const options= {
        plugins: {
            colors: {
                enabled: true,
                forceOverride: true
              },
            datalabels: {
                color: '#36A2EB'
              },
            legend: {
                position: 'top' ,
                labels:{ boxWidth: 20}

            },

          tooltip: {
              enabled: true
          },
          
        }

      }

    return (

        <div style={{position:'relative', width: '100%', height: '100%'}}>
            <Doughnut  data={data} options={options} />
        </div>
    );
}
export default DashboardSubDoug;