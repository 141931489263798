import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import LicenseService from "../../API/LicenseService";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";

function FortimanagerEditForm(props) {


    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [fortimanagerName, setFortimanagerName] = useState(props.fortimanager?.name);
    const [fortimanagerStartName, setFortimanagerStartName] = useState('')
    const [fortimanagerHost, setFortimanagerHost] = useState(props.fortimanager?.host);
    const [fortimanagerUserName, setFortimanagerUserName] = useState(props.fortimanager?.username);
    const [fortimanagerUserPassword, setFortimanagerUserPassword] = useState("");

    useEffect(() => {
        setIsPreloader(true);
        (async () => {
            await LicenseService.fortimanagerDetails(props.fortimanager?.id).then((res) => {
                setFortimanagerName(res.name);
                setFortimanagerStartName(res.name);
                setFortimanagerHost(res.host);
                setFortimanagerUserName(res.username);
                setFortimanagerUserPassword(null)
            }).catch((e) => { setErrors(e.message); }).finally(setIsPreloader(false))
        })();

    }, [])


    const editFortimanager = async event => {
        event.preventDefault();
        setIsPreloader(true);
        const fortimanager = {};
        fortimanager.name = fortimanagerName;
        fortimanager.username = fortimanagerUserName;
        fortimanager.host = fortimanagerHost;
        if (fortimanagerUserPassword !== null){
            fortimanager.password = fortimanagerUserPassword;
        }
       

        await LicenseService.patchFortimanager(fortimanager, props.fortimanager?.id)
            .then(() => { setTimeout(() => {props.setVisible(false); setErrors(null); setIsPreloader(false) }, 500) })
            .catch((e) => { setErrors(e.message); setIsPreloader(false);});
    }

    return (
        <div style={{ position: 'relative', width: 400}}>
            {isPreloader && <Preloader/>}
            <Form
                onSubmit={editFortimanager}
                autoComplete="off"
                className='was-validated'
                style={{ position: 'relative', width: '100%' }}
            >
                {errors.length > 0 &&
                    errors?.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }

                <h3 className="mb-3">Редактировать "{fortimanagerStartName}"</h3>

                <Form.Group className='mb-4'>
                    <Form.Label>Название</Form.Label>
                    <Form.Control type="text" required={true} value={fortimanagerName} onChange={(e) => setFortimanagerName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Хост</Form.Label>
                    <Form.Control type="text" required={true} value={fortimanagerHost} onChange={(e) => setFortimanagerHost(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Имя пользователя</Form.Label>
                    <Form.Control type="text" required={true} value={fortimanagerUserName} onChange={(e) => setFortimanagerUserName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control autoComplete="new-password" type="password" value={fortimanagerUserPassword} onChange={(e) => setFortimanagerUserPassword(e.target.value)} />
                </Form.Group>
                <Row>
                    <Col sm={6}>
                        <Button variant="outline-primary" type="submit" size="sm" style={{ width: '100%' }}>
                            Изменить
                        </Button></Col>
                    <Col sm={6}>
                        <Button variant="outline-danger" size="sm" type="button" style={{ width: '100%' }} onClick={() => {props.setVisible(false)}}>
                            Отменить
                        </Button></Col></Row>
            </Form>
        </div>
    );
}
export default FortimanagerEditForm;