import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "./styles/App.css"
import AppRouter from "./components/AppRouter"
import Container from 'react-bootstrap/Container';
import { AuthContext, UserContext } from './context';
import User from "./API/User";
import { BrowserRouter } from "react-router-dom";


function App() {
  const [isAuth, setIsAuth] = useState(true);
  const [status, setStatus] = useState(false);
  const [userinfo, setUserinfo] = useState(null);
 

  useEffect(() => {
    const token =localStorage.getItem('tokenFP')
    if (token!==null) {
      User.getMe().then((res) => {
        if (["VENDOR", "PARTNER", "ENDUSER"].indexOf(res.organizationType) > -1 && (res.isActive === true)) {
          setUserinfo(res)
        } else { setIsAuth(false); logout() }
      }).catch((err)=> { setIsAuth(false); logout() })
    }
  }, [isAuth]);

  const logout = () => {
    setIsAuth(false);
    setUserinfo({});
    localStorage.removeItem('tokenReviewFP');
    localStorage.removeItem('tokenFP');
    localStorage.removeItem('freshTokenFP');

  }

  return (<BrowserRouter>
    <AuthContext.Provider value={{
      isAuth,
      setIsAuth,
      logout,
      status, setStatus
    }}>
      <UserContext.Provider value={{
        userinfo,
        setUserinfo,
      }}>
        <Container fluid>
          <AppRouter isAuth={isAuth} />
        </Container>
      </UserContext.Provider>
    </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
