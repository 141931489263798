import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import PlatformService from "../../API/PlatformService";
import AssetService from "../../API/AssetService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import SolutionService from "../../API/SolutionService";
import GeoService from "../../API/GeoService";
import Preloader from "../UI/Preloader/Preloader";
import { AuthContext } from "../../context";

function AddAssetForm(props) {
    registerLocale('ru', ru)
    const [isPreloader, setIsPreloader] = useState(false);
    const [serialNumber, setSerialNumber] = useState('');
    const [valid, setVaild] = useState(false);
    const [validPlatform, setValidPlatform] = useState(false)
    const [assetOrg, setAssetOrg] = useState(null);
    const [assetPlatform, setAssetPlatform] = useState(null);
    const [allPlatforms, setAllPlatforms] = useState(null);
    const [country, setCountry] = useState({ country_ru: "Россия" });
    const [city, setCity] = useState({ city_ru: 'Москва' });
    const [cityList, setCityList] = useState([]);
    // const [patchDate, setPatchDate] = useState(new Date());
    const [patchDate, setPatchDate] = useState(null);
    const [assetDescription, setAssetDescription] = useState('');
    const [softVersion, setSoftVersion] = useState(null)
    const [validSolution, setValidSolution] = useState(false)
    //решения
    const [allSolutions, setAllSolutions] = useState(null)
    const [assetSolution, setAssetSolution] = useState(null)
    ///
    const [allOrganisations, setAllOrganisations] = useState(null);
    const [errors, setErrors] = useState([]);
    const [countryFilter, setCountryFilter] = useState('Европа');
    const [countryList, setCountryList] = useState([]);
    const [cityFilter, setCityFilter] = useState('Россия');
        //контекст
        const { setStatus } = useContext(AuthContext);
        const changeStatus = () => setStatus(true);
    useEffect(() => {

        (async () => {
          await OrgService.getList(false, false, true).then((orgs)=>setAllOrganisations(orgs.results) ).catch(() => changeStatus());;
           await PlatformService.getList().then((platforms)=>setAllPlatforms(platforms) ).catch(() => changeStatus());;
            await SolutionService.getList().then((sol)=> setAllSolutions(sol)).catch(() => changeStatus());;
            
        })();

    }, []);
    useEffect(() => {

        (async () => {
        await GeoService.getAllCountries(countryFilter).then((country)=> setCountryList(country.results)).catch(() => changeStatus());;
        })();

    }, [countryFilter]);
    useEffect(() => {

        (async () => {
            await GeoService.getAllCities(cityFilter).then((city)=> setCityList(city.results)).catch(() => changeStatus());;
        })();

    }, [cityFilter]);

    const saveAsset = async event => {
        event.preventDefault();
        const asset = {};
        setIsPreloader(true)
        asset.serial_nr = serialNumber;
        asset.platform = assetPlatform.name;
        if (assetSolution !== null) asset.solution = assetSolution.id
        asset.organisation = assetOrg;
        asset.country = country.country_ru;
        if (softVersion !== null) asset.sw_version = softVersion;
        // if (currentHypervisor !== null) asset.hyper_visor = currentHypervisor.id;
        if (assetDescription !== '') asset.description = assetDescription;
        if (patchDate !== null) asset.patched_date = patchDate.toISOString().split('T')[0];
        // asset.country = country;
        // asset.postal_code = postal;
        asset.city = city.city_ru;
        asset.lon = city.lon;
        asset.lat = city.lat;
        // asset.house_nr = house;

        await AssetService.newAsset(asset).then(() => { setTimeout(() => { setIsPreloader(false); props.setIsAddingAsset(false) }, 1000) }).catch((e) => { setIsPreloader(false); setErrors(e.message) })

    }
    const dateCheckValid = (date) => {
        if (date !== null) date.setHours(15, 0, 0, 0);
        setPatchDate(date)
    }
    return (
        <Form id="form" onSubmit={saveAsset} className="was-validated" >
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <Row >
                <Col className="mb-3 col-3" >
                    <Form.Group className="mb-4"  >
                        <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                            props.setIsAddingAsset(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-3" >
                    <Form.Group className="mb-4">
                        <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit">
                            Добавить устройство
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>
            {/* {addHypervisor === true && <Row>
                    <HyperTypeAddForm
                        setHypervisorAdd={props.setHypervisorAdd}
                        hypervisorAdd={props.hypervisorAdd}
                        setAddHypervisor={setAddHypervisor}
                        setCurrentHypervisor={setCurrentHypervisor}
                    />
                </Row>} */}
            <Row>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер</Form.Label>
                        <Form.Control size="sm" type="text" value={serialNumber} required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Организация</Form.Label>

                        <Dropdown style={!valid ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {assetOrg || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assOrg'}
                                filterQa={true}
                            >
                                {allOrganisations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setAssetOrg(org.name); setVaild(true) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                
                <Col sm={3}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Платформа</Form.Label>

                        <Dropdown style={!validPlatform ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {assetPlatform?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assPlat'}
                                filterQa={true}
                            >
                                {allPlatforms?.map(platform =>
                                    <Dropdown.Item eventKey={"e" + platform.id} key={"e" + platform.id} onClick={() => { setAssetPlatform(platform); setValidPlatform(true) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Решение</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                {assetSolution?.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assSol'}
                                filterQa={true}
                            >
                                {allSolutions?.map(solution =>
                                    <Dropdown.Item eventKey={"sol" + solution.id} key={"sol" + solution.id} onClick={() => { setAssetSolution(solution) }}>{solution.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col sm={3} >
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Регион</Form.Label>
                        <Form.Select size="sm" id="geoReg" onChange={(e) => setCountryFilter(e.target.value)} disabled>
                            <option value="Европа">Европа</option>
                            <option value="Азия">Азия</option>
                            <option value="Америка">Америка</option>
                            <option value="Африка">Африка</option>
                            <option value="Океания">Океания</option>

                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group className="mb-3">
                        <Form.Label size="sm">Страна</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-country" >
                                {country?.country_ru || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assCount'}
                                filterQa={true}
                            >
                                {countryList?.map(country =>
                                    <Dropdown.Item eventKey={"country" + country.id} key={"country" + country.id} onClick={() => { setCountry(country); setValidPlatform(true); setCityFilter(country.country_ru); setCity(null) }}>{country.country_ru}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Город</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-country" >
                                {city?.city_ru || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assCity'}
                                filterQa={true}
                            >
                                {cityList?.map(city =>
                                    <Dropdown.Item eventKey={"city" + city.id} key={"city" + city.id} onClick={() => { setCity(city); setValidPlatform(true) }}>{city.city_ru}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>

            </Row>

            <Row>
                <Col sm={3}>
                    <Form.Group>
                        <Form.Label size="sm">ОС (опционально)</Form.Label>
                        <Form.Control size="sm" type="text" value={softVersion || ""} placeholder={'Введите версию ОС'}
                            onChange={(e) => setSoftVersion(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    <Form.Group className="mb-4" style={{display: 'flex', flexDirection:'column', justifyContent:'center'}} >
                        <Form.Label size="sm">Выберите дату патча</Form.Label>
                        <DatePicker
                            // id="datePicker_addAsset"
                            dateFormat="dd.MM.yyyy"
                            selected={patchDate}
                            onChange={(date) => { dateCheckValid(date) }}
                            locale="ru"
                        />
                    </Form.Group>
                </Col>
                {/* {assetPlatform?.type === "VM" && <Col sm={4}>
                    <Form.Group className="mb-4" >
                        <Form.Label size="sm">Гипервизор</Form.Label>

                        <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }} >
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs"  >
                                Тип гипервизора: {currentHypervisor?.hv_type || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                type={'assTypeHyp'}
                                filterQa={true}
                                clickFunction={setAddHypervisor}
                            >
                                {hypervisor !== null && hypervisor?.map(visor =>
                                    <Dropdown.Item eventKey={"vis" + visor.id} key={"vis" + visor.id} onClick={() => { setCurrentHypervisor(visor) }}>{visor.hv_type}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>

                    </Form.Group>
                </Col>} */}
            </Row>

            <Row>
                <Col sm={3}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={assetDescription || ""}
                            size="sm"
                            onChange={(e) => setAssetDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>

            </Row>
            <Row>

            </Row>
            <hr />
        </Form >
    );
}
export default AddAssetForm;
