import React, { useEffect, useState, useContext } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AuthContext } from "../../context";
function DashboardServDoug() {

    const [servsStats, setServsStats] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
           await DashboardService.getServsStats().then((res)=>setServsStats(res) ).catch((e) => changeStatus());
       
        })()
    }, [])
    ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

let counter =0;
 const data = {
         labels: servsStats!== null ? servsStats.map((item, index)=>{counter+=item.equipment_cnt; return item.slug}) : '',
        datasets: [
           {
          datalabels: {
                color: 'black',
                opacity: 0
      
              },
   
          data: servsStats !==null && servsStats.map((item, index)=>{return item.equipment_cnt}),

            borderWidth: 1,
          },
        ],
      };
      const options= {
        plugins: {
            colors: {
                enabled: true,
                forceOverride: true
              },
            datalabels: {
                color: '#36A2EB'
              },
            legend: {
                position: 'top' ,
                labels:{ boxWidth: 20}

            },
 
          tooltip: {
              enabled: true
          },
          
        }

      }

    return (

        <div style={{position:'relative', width: '100%', height: '100%'}}>

              <p style={{position:"absolute", top: "45%", left: "48%",  fontSize: '30px',color:'rgb(13, 110, 253, .8)'}}><b>{counter}</b></p>
            <Doughnut  data={data} options={options} />
        </div>
    );
}
export default DashboardServDoug;