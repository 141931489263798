import Api from '.';
import { limit } from '../utils/constants';
export default class WarehouseService {

    static async getList(id, filters, nextpage) {

        var url = `api/v1/asset-equipment-on-warehouse/?limit=${limit}`

        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.platformFilter) {
                params.platform__name = filters.platformFilter.name;

            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.newFilter) {
                params.condition = filters.newFilter;
            };
            if (filters.whFilter) {
                params.equipment_location= filters.whFilter==='Да'? 'warehouse': 'tests';
            };

        };

        if (nextpage) {
            // url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
            params.page = nextpage;
        }

        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newAsset(asset) {

        const url = 'api/v1/asset-equipment-on-warehouse/';
        const method = 'POST';
        const data = asset;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async getStats() {

        const url = 'api/v1/asset-equipment-on-warehouse-stats/';
        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }


    static async getItem(id) {

        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async patchAsset(asset, id) {
        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const data = asset;

        // data.contract_nr=contract.contract_nr;
        // data.contract_duration=contract.contract_duration;
        // data.payment_method=contract.payment_method;
        // data.comment=contract.comment;
        // data.contract_date = contract.contract_date;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.data;
    }



    static async deleteAsset(id) {
        const url = `api/v1/asset-equipment-on-warehouse/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

    static async deletePhoto(photoId, assetId) {
        const url = `api/v1/asset-equipment-on-warehouse/${assetId}/remove_image/${photoId}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.data;
    }

}