import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LicenseService from "../../API/LicenseService";
import Preloader from "../UI/Preloader/Preloader";


function FortimanagersAddForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [name, setName] = useState();
    const [host, setHost] = useState();
    const [password, setPassword] = useState();
    const [userName, setUserName] = useState();
    const [errors, setErrors] = useState([]);

    const saveFortimanager = async event => {
        event.preventDefault();
        setIsPreloader(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('host', host);
        formData.append('username', userName);
        formData.append('password', password);
        await LicenseService.newFortimanager(formData).then(() => { setTimeout(() => { setIsPreloader(false); props.setIsAddingFortimanager(false) }, 1000) }).catch((e) => { setIsPreloader(false); setErrors(e.message) })
    }


    return (
        <div style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <Form id="form" autoComplete="off" onSubmit={saveFortimanager} className="was-validated" style={{ position: "relative" }}>
                {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }

                <Row >
                    <Col className="mb-3 col-3" >
                        <Form.Group className="mb-4"  >
                            <Button style={{ width: '100%' }} variant="outline-danger" size="sm" onClick={() => {
                                props.setIsAddingFortimanager(false);

                            }}>
                                Отмена
                            </Button>
                        </Form.Group>
                    </Col>
                    <Col className="mb-3 col-3" >
                        <Form.Group className="mb-4">
                            <Button style={{ width: '100%' }} variant="outline-primary" size="sm" type="submit" >
                                Сохранить
                            </Button><br />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col className='mb-3' sm={4}>
                        <Form.Group>
                            <Form.Label size="sm">Название</Form.Label>
                            <Form.Control size="sm" type="text" value={name} required={true} placeholder={'Введите название'}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>

                    <Col sm={4}>
                        <Form.Group>
                            <Form.Label size="sm">Хост</Form.Label>
                            <Form.Control size="sm" type="text" value={host} required={true} placeholder={'Введите хост'}
                                onChange={(e) => setHost(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col className="mb-3 col-4">
                        <Form.Group>
                            <Form.Label size="sm">Имя пользователя</Form.Label>
                            <Form.Control size="sm" type="text" placeholder={'Введите имя пользователя'} required={true}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col className="mb-3 col-4">
                        <Form.Group>
                            <Form.Label size="sm">Пароль</Form.Label>
                            <Form.Control autoComplete="new-password" size="sm" type="password" placeholder={'Введите пароль'} required={true}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Form.Group>
                    </Col>

                </Row>
                <hr />
            </Form >
        </div>
    );
}
export default FortimanagersAddForm;
