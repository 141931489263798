import React from "react";
import Table from 'react-bootstrap/Table';
import ContractItem from "./ContractItem";

const ContractList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <Table striped bordered hover >
            <thead style={basicStyles}>
                <tr>
                
                    <th style={{width: "10%"}}>Дата договора</th>
                    <th style={{width: "12%"}}>Дата окончания</th>
                    <th style={{width: "12%"}}>Номер</th>
                    <th style={{width: "12%"}}>Тип оплаты</th>
                    <th>Покупатель</th>
                    <th>Конечник</th>
                    <th style={{width: "6%"}}>Квота</th>
                    <th>Сумма квоты</th>
                    <th>Сумма контракта</th>
                    <th>Комментарий</th>

                </tr>
            </thead>
            <tbody>
                {props.contractItems?.map(item =>

                    <ContractItem
                        item={item}
                        key={item.id}
                        setViewQuota={props.setViewQuota}
                        setCurrentQuota={props.setCurrentQuota}
                        viewOrg={props.viewOrg}
                        setViewOrg={props.setViewOrg}
                        viewQuota={props.viewQuota}
                    />
                )
                }
                <tr ref={props.lastItem} style={{ height: 20 }} />
            </tbody>
        </Table>

    )
}

export default ContractList;