import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import ServiceService from "../../API/ServiceService";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";

function SubscriptionDeleteForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);


    const deleteSub = async () => {
        setIsPreloader(true)
        await ServiceService.deleteSub(props?.currentSub?.id)
            .then(() => { setTimeout(() => { props.setDeleteSub(false); setErrors(null); setIsPreloader(false); props.setSuccessDelete(true)}, 500) })
            .catch((e) => {setErrors(e.message); setIsPreloader(false) });
    }

    return (
        <div style={{ position: 'relative', width:'100%'}}>
            {isPreloader && <Preloader />}
            <Row>
                <h3 className="mb-3">Удалить подписку {props?.currentSub?.id}?</h3>
            </Row>
            <Row> {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }</Row>
            <div style={{display:'flex', flexDirection:'column', gap: '2rem'}}>
            <Row>
                <Col sm={6}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); deleteSub() }}>
                        Да
                    </Button>

                </Col>
                <Col sm={6}>
                    <Button variant="outline-danger" size="sm" onClick={() => props.setDeleteSub(false)} style={{ width: '100%' }}>
                        нет
                    </Button>
                </Col>
            </Row>
            </div>
        </div>

    );
}
export default SubscriptionDeleteForm;