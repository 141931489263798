/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Preloader from "../UI/Preloader/Preloader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import PlatformService from "../../API/PlatformService";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import MyModal from "../UI/MyModal/MyModal";
import AddWarehouseForm from "./AddWarehouseForm";
import WarehouseService from "../../API/WarehouseService";
import WarehouseList from "./WarehouseList";
import EditWarehouseForm from "./EditWarehouseForm";
import WarehousePhoto from "./WarehousePhoto";
import WarehouseCopyForm from "./WarehouseCopyForm";

function WarehouseArea(props) {

    const [isPreloader, setIsPreloader] = useState(false);
    const [assetsStats, setAssetsStats] = useState(null)
    const [errors, setErrors] = useState([]);
    const [successDelete, setSuccessDelete] = useState(false);
    const [deletingAsset, setDeletingAsset] = useState(false);
    const [currentAsset, setCurrentAsset] = useState({});

    //пагинация
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingAsset, setIsAddingAsset] = useState(false);
    const [copyAsset, setCopyAsset] = useState(false);
    const [editAsset, setEditAsset] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    // перечень всех устройств
    const [assetItems, setAssetItems] = useState();
    const [totalAssetItems, setTotalAssetItems] = useState();

    // поиск и фильтрация
    const [searchText, setSearchText] = useState("");
    const [platformList, setPlatformList] = useState(null);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [newFilter, setNewFilter] = useState(null)
    const [whFilter, setWhFilter] = useState(null);
    const [text, setText] = useState('')
    // eslint-disable-next-line no-unused-vars
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


    const fetchData = async (add) =>{
        let filters = false;

            if (platformFilter || searchText || newFilter || whFilter) {
                filters = {
                    platformFilter: platformFilter,
                    searchText: searchText,
                    newFilter: newFilter,
                    whFilter: whFilter

                };
            }

            setIsPreloader(true);
            setIsAssetLoading(true);
            const id = props.orgId ? props.orgId : false;
            await WarehouseService.getList(id, filters, add? nextPage : 1).then((res) => {

                if (add==='add'){setNextPage(res.next ? nextPage + 1 : null);  res.results?.map(item => setAssetItems(arr => [...arr, item])); }
                else{setAssetItems(res.results); setNextPage(res.next ? 2 : null); }
                setTotalAssetItems(res.count);
                setTimeout(()=> setIsAssetLoading(false), 500)
            }).catch(() => changeStatus()).finally(() => setIsPreloader(false))
            await WarehouseService.getStats().then((res) => setAssetsStats(res)).catch(() => changeStatus());
    }


    useEffect(() => {


        if (!isAddingAsset){
            setIsPreloader(true)
            setIsAssetLoading(true);
            const timerRefresh = setTimeout(fetchData, 800);
            return () => {
    
                clearTimeout(timerRefresh)
            }}
        // (async () => {
        //     setNextPage(null);
        //     let filters = false;

        //     if (platformFilter || searchText || newFilter || whFilter) {
        //         filters = {
        //             platformFilter: platformFilter,
        //             searchText: searchText,
        //             newFilter: newFilter,
        //             whFilter: whFilter

        //         };
        //     }

        //     setIsPreloader(true);
        //     setIsAssetLoading(true);

        //     const id = props.orgId ? props.orgId : false;

        //     await WarehouseService.getList(id, filters, false).then((res) => {
        //         setAssetItems(res.results);
        //         setTotalAssetItems(res.count);
        //         setNextPage(res.next);
        //         setIsAssetLoading(false);
        //     }).catch(() => changeStatus()).finally(() => setIsPreloader(false))
        //     await WarehouseService.getStats().then((res) => setAssetsStats(res)).catch(() => changeStatus());
        // })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingAsset, platformFilter, searchText, newFilter, copyAsset, editAsset, successDelete, whFilter]);

    useEffect(() => {
        (async () => {
            await PlatformService.getList().then((res) => setPlatformList(res)).catch(() => changeStatus());

        })();
    }, []);


    useEffect(() => {


        (async () => {

            if (needToLoad && nextPage) {
               
                fetchData('add');
                setNeedToLoad(false);
            }
        })();

        // (async () => {

        //     if (needToLoad) {

        //         setIsPreloader(true);
        //         setIsAssetLoading(true);
        //         await WarehouseService.getList(false, false, nextPage).then((res) => {
        //             res.results?.map(item => setAssetItems(arr => [...arr, item]));
        //             setNextPage(res.next);
        //             setIsAssetLoading(false);
        //             setNeedToLoad(false);
        //         }).finally(() => setIsPreloader(false));

        //     }
        // })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {

        if (isAssetLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isAssetLoading]);

    const clearFilters = () => {
        setPlatformFilter(false);
        setSearchText("");
        setNewFilter(null);
        setWhFilter(null);
        setText('')
    }

    const removeAsset = async (assetId) => {

        await WarehouseService.deleteAsset(assetId).then(() => { setSuccessDelete(!successDelete); setDeletingAsset(false); setErrors([]) }).catch((err) => setErrors(err.message))
    }

    return (
        <>

            <Col>
                {<h3 className="mb-5">Склад (всего {totalAssetItems} устройств)</h3>}

                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                {copyAsset && 
                <MyModal visible={copyAsset} setVisible={setCopyAsset}>
                    <WarehouseCopyForm asset={currentAsset} setIsCopyAsset={setCopyAsset}/>
                </MyModal>
                }
                {deletingAsset &&
                    <MyModal visible={deletingAsset} setVisible={setDeletingAsset}>
                        <Row>

                            <h5 className="mb-3">Удалить устройство?</h5>
                        </Row>
                        <Row> {errors.length > 0 &&
                            errors.map(error =>
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            )
                        }</Row>
                        <Row>
                            <Col >
                                <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeAsset(currentAsset.id) }}>
                                    Да
                                </Button>

                            </Col>
                            <Col >
                                <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingAsset(false); setErrors([]) }} style={{ width: '100%' }}>
                                    нет
                                </Button>
                            </Col>
                        </Row>

                    </MyModal>}
                {showPhoto &&
                    <MyModal visible={showPhoto} setVisible={setShowPhoto}>

                        <WarehousePhoto images={currentAsset?.images} />

                    </MyModal>}


                <Row>
                    <Col className="mb-3 col-3">
                        <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { setIsAddingAsset(true) }}

                        >Добавить устройство</Button>

                    </Col>



                </Row>  <hr />

                {/* ФИЛЬТРЫ */}

                <Row className="justify-content-start">
                    {isAddingAsset && <AddWarehouseForm setIsAddingAsset={setIsAddingAsset}
                    />}
                    {editAsset && <EditWarehouseForm setIsAddingAsset={setIsAddingAsset} currentAsset={currentAsset} setEditAsset={setEditAsset}
                    />}
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">Поиск</Form.Label>
                        <Form.Control onChange={(e) => { setSearchText(e.target.value)}}
                            placeholder="Начните вводить..."
                            size="sm"
                            style={{ fontSize: '1rem', height: 38 }}
                            value={searchText}
                            // disabled={isAssetLoading}
                        />
                    </Col>
                    <Col className="mb-3 col-3 ">
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Dropdown style={{ width: "100%" }}>
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platforms">
                                {platformFilter.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setPlatformFilter={setPlatformFilter}
                                type='platform'
                            >
                                {platformList?.map(platform =>
                                    <Dropdown.Item eventKey={"apl" + platform.id} key={"apl" + platform.id} onClick={() => { setPlatformFilter(platform) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">Новое или Б/У</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-newF" >
                                {newFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setNewFilter}
                                type='newF'
                            >
                                <Dropdown.Item eventKey={"rm" + 1} key={"rm" + 1} onClick={() => { setNewFilter('new') }}>{'New'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 2} key={"rm" + 2} onClick={() => { setNewFilter('mint') }}>{'Mint'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 3} key={"rm" + 3} onClick={() => { setNewFilter('near-mint') }}>{'Near-mint'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 4} key={"rm" + 4} onClick={() => { setNewFilter('used') }}>{'Used'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 5} key={"rm" + 5} onClick={() => { setNewFilter('parts') }}>{'Parts'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-3" >
                        <Form.Label size="sm">На складе</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-newWH" >
                                {whFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setWhFilter}
                                type='newWh'
                            >
                                <Dropdown.Item eventKey={"wh" + 1} key={"wh" + 1} onClick={() => { setWhFilter('Да') }}>{'Да'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"wh" + 2} key={"wh" + 2} onClick={() => { setWhFilter('Нет') }}>{'Нет'}</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                {<Row>
                    {(platformFilter || searchText || newFilter || whFilter) &&
                        <Col className="col-2">

                            <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0' }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                        </Col>}
                </Row>}

                <Row>
                    <Col style={{ position: "relative" }}>

                        <WarehouseList showPhoto={showPhoto} setShowPhoto={setShowPhoto} assetItems={assetItems} editAsset={editAsset} setEditAsset={setEditAsset} copyAsset={copyAsset} setCopyAsset={setCopyAsset} close={props.close} removeAsset={setDeletingAsset} currentAsset={currentAsset} setCurrentAsset={setCurrentAsset} />
                        {isPreloader && <Preloader />}
                    </Col>
                </Row>
                <div ref={lastItem} style={{ height: 20 }} />
            </Col>
        </>
    );
}
export default WarehouseArea;