import React, { useEffect, useState, useRef, useContext } from "react";
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OrgCreateForm from "../Organizations/OrgCreateForm";
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";
import Row from "react-bootstrap/esm/Row";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { organisationRating } from "../../utils/constants";
import MyModal from "../UI/MyModal/MyModal";
import OrgDeleteForm from "./OrgDeleteForm";

function OrgList() {
    const navigate = useNavigate();
    const [isPreloader, setIsPreloader] = useState(false);
    const [isOrgListLoading, setIsOrgListLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    // const search = useRef();
    const basicStyles = {
        "position": 'relative',
        "fontSize": 'smaller',
    }
    const [orgList, setOrgList] = useState();
    const [searchText, setSearchText] = useState("");
    const [isOrgAdding, setIsOrgAdding] = useState(false);
    const [deleteOrg, setDeleteOrg] = useState(false);
    const [succesDelete, setSuccessDelete] = useState(false);
    const [currentOrg, setCurrentOrg] = useState(null);
    const [orgAddingType, setOrgAddingType] = useState("ENDUSER");
    const [orgAdded, setOrgAdded] = useState(false);
    const [errors, setErrors] = useState([]);
    const [orgsStats, setOrgsStats] = useState(null);
    const [rateFilter, setRateFilter] = useState(false);
    // const [text, setText] = useState('')

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const fetchData = async (add) => {
        setErrors([]);
        if (orgAdded) {
            setOrgAdded(false);
        };
        (async () => {

            setIsPreloader(true);
            let filters = false;

            if (searchText || rateFilter) {
                filters = {
                    searchText: searchText,
                    rateFilter: rateFilter,
                };
            }
            // const filters = { 'searchText': searchText };
            setIsOrgListLoading(true)
            // const id = props.orgId?  props.orgId : false
            await OrgService.getList(filters, add ? nextPage : 1, false).then((res) => {
                if (add === 'add') { setNextPage(res.next ? nextPage + 1 : null); res.results?.map(item => setOrgList(arr => [...arr, item])); }
                else { setOrgList(res.results); setNextPage(res.next ? 2 : null); }
                setTimeout(() => setIsOrgListLoading(false), 500)
            }).catch(() => changeStatus()).finally(() => setIsPreloader(false));

            await OrgService.getStats().then((res) => setOrgsStats(res));
        })();
    }


    useEffect(() => {
        if (!isOrgAdding){
            setIsPreloader(true)
            setIsOrgListLoading(true);
            const timerRefresh = setTimeout(fetchData, 800);
            return () => {
                clearTimeout(timerRefresh)
            }}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, orgAdded, rateFilter, succesDelete]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
               
                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);
    useEffect(() => {
        
        if (isOrgListLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && orgList?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrgListLoading]);
    // async function removeOrg(id) {
    //     try {
    //         await OrgService.delete(id);
    //         setOrgAdded(true);
    //         setErrors([]);
    //     } catch (e) {

    //         search.current.focus()
    //         setErrors(e.message);

    //     };
    // }

    // useEffect(() => {

    //     (async () => {

    //         if (needToLoad) {
    //             setIsPreloader(true);
    //             setIsOrgListLoading(true);
    //             const orglist = await OrgService.getList(false, nextPage, false).finally(() => setIsPreloader(false));
    //             await orglist.results?.map(item => setOrgList(arr => [...arr, item]));
    //             await setNextPage(orglist.next);
    //             setIsOrgListLoading(false);
    //             setNeedToLoad(false);
    //         }
    //     })();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [needToLoad]);

    // useEffect(() => {

    //     if (isOrgListLoading) return;

    //     if (observer.current) observer.current.disconnect();

    //     var callback = function (entries, observer) {
    //         if (entries[0].isIntersecting && nextPage) {
    //             setNeedToLoad(true);
    //         }
    //     };
    //     observer.current = new IntersectionObserver(callback);
    //     observer.current.observe(lastItem.current);

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOrgListLoading]);

    const clearFilters = () => {
        setRateFilter(false);
        setSearchText("");
        // setText('')
    }
    return (

        <Col>
            {isPreloader && <Preloader />}
            <Row className="mb-4">
                <div><h3>Список всех организаций</h3></div>
            </Row>
            <Row className="mb-4">
                <b>Организаций "PARTNER" : {orgsStats?.partner_orgs}</b>
            </Row>
            <Row className="mb-4">
                <b>Организаций "ENDUSER": {orgsStats?.enduser_orgs}</b>
            </Row>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            <Button variant="outline-success" size="sm" onClick={() => setIsOrgAdding(true)} >Добавить новую организацию</Button>
            {
                isOrgAdding
                &&
                <OrgCreateForm
                    setIsOrgAdding={setIsOrgAdding}
                    orgAddingType={orgAddingType}
                    setOrgAdded={setOrgAdded}
                    allTypes={true}
                    setOrgAddingType={setOrgAddingType}
                />
            }
            <hr />
            <Row className="mb-4 mt-3 align-items-center">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Col sm={2} onClick={() => setRateFilter('1')} style={{ width: '15%', backgroundColor: rateFilter === '1' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid red', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-danger`}>1</span> - свыше 10 млн</Col>
                    <Col sm={2} onClick={() => setRateFilter('2')} style={{ width: '15%', backgroundColor: rateFilter === '2' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid blue', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '2px 3px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-primary`}>2</span> - от 5 до 10 млн</Col>
                    <Col sm={2} onClick={() => setRateFilter('3')} style={{ width: '15%', backgroundColor: rateFilter === '3' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid green', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-success`}>3</span> - от 1 до 5 млн</Col>
                    <Col sm={2} onClick={() => setRateFilter('4')} style={{ width: '15%', backgroundColor: rateFilter === '4' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid #0dcaf0', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-info`}>4</span> - до 1 млн</Col>
                    <Col sm={2} onClick={() => setRateFilter('5')} style={{ width: '15%', backgroundColor: rateFilter === '5' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid black', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-dark`}>5</span> - без активных договоров</Col>
                    <Col sm={2} onClick={() => setRateFilter('6')} style={{ width: '15%', backgroundColor: rateFilter === '6' ? "rgba(0, 0, 0, .1)" : 'transparent', cursor: "pointer", border: '1px solid black', borderRadius: '4px', height: '40px', "fontSize": 'smaller', padding: '0 0 0 3px' }}><span style={{ marginRight: 5, padding: '3px 5px', fontSize: '10px', boxSizing: 'border-box' }} className={`badge bg-dark`}>6</span> - lost</Col>
                </div>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                <Col sm={8}>
                    <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                        placeholder="Начните вводить..."
                        size="sm"
                        style={{
                            width: '100%',
                            height: 38
                        }}
                        value={searchText}
                        // ref={search}
                        // disabled={isOrgListLoading}
                    />
                </Col>
                <Col sm={2} className="col-3" style={{ padding: ' 0 40px 0 0', }}>
                    {searchText &&
                        <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0', padding: 0 }} onClick={() => clearFilters()}>Сбросить</Button>
                    }
                </Col>
            </Row>

            {deleteOrg && <MyModal visible={deleteOrg} setVisible={setDeleteOrg}>

                <OrgDeleteForm setSuccessDelete={setSuccessDelete} setDeleteOrg={setDeleteOrg} deleteOrg={deleteOrg} currentOrg={currentOrg} />

            </MyModal>}
            <Row>
                <Table striped bordered hover >

                    <thead style={basicStyles}>
                        <tr >
                            <th>Тип</th>
                            <th>Название</th>
                            <th>ИНН</th>
                            <th>Веб-сайт</th>
                            <th >Квоты</th>
                            <th >Контракты</th>
                            <th >Устройства</th>
                            <th>Пользователей</th>
                        </tr>

                    </thead>
                    <tbody style={basicStyles}>
                        {orgList?.map(org =>
                            <tr key={org.id} onClick={(e) => { e.preventDefault(); navigate(`/orglist/${org.id}`) }}>
                                <td>
                                    {org.type}
                                </td>
                                <td>
                                    {organisationRating(org.rating, org.name)}
                                </td>
                                <td>
                                    {org.vat}
                                </td>
                                <td>
                                    {org.website}
                                </td>
                                <td>
                                    {org.quotes} шт
                                </td>
                                <td>
                                    {org.contracts} шт
                                </td>
                                <td>
                                    {org.devices} шт
                                </td>
                                {org.users_amount === 0 ? <td>{0}</td> :
                                    <td>
                                        активных: {org.active_users}<br />
                                        не активных: {org.passive_users}
                                    </td>}

                                <td onClick={(e) => { e.stopPropagation() }}>
                                    {
                                        org.type !== "VENDOR" &&
                                        <Button variant="outline-danger" size="sm" onClick={
                                            () => {
                                                setCurrentOrg(org);
                                                setDeleteOrg(true);
                                            }
                                        } >Удалить</Button>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Row>
            <div ref={lastItem} style={{ height: 20 }} ></div>
        </Col>
    );
}
export default OrgList;