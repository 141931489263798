import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import ImgRemove from '../../icons/remove.png';

function EditServiceForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);

    const [slugService, setSlugService] = useState(props.currentService.slug || '');
    const [slaDocument, setSlaDocument] = useState(props.currentService.sla_document);
    const [serviceType, setServiceType] = useState(props.currentService.service_type || '');
    const [сommentService, setCommentService] = useState(props.currentService.comment || '');
    const [descriptionService, setDescriptionService] = useState(props.currentService.description || '');
    const [descriptionSlaService, setDescriptionSlaService] = useState(props.currentService.sla_description || '');
    const [contactService, setContactService] = useState(props.currentService.service_center_contact || '')
    const [rmaService, setRmaService] = useState((props.currentService.rma === true ? "Да" : "Нет") || 'Да');

    const [errors, setErrors] = useState(null);
    const [file, setFile] = useState(props.currentService.sla_document);

    const patchingService = async event => {
        event.preventDefault()
        setIsPreloader(true);
        const item = {};
        item.slug = slugService;
        item.address = serviceType;
        item.description = descriptionService;
        if (contactService !== '' || contactService !== null) item.service_center_contact = contactService;
        if (descriptionSlaService !== "") item.sla_description = descriptionSlaService;
        if (сommentService !== '') item.comment = сommentService;
        switch (rmaService) { case "Да": item.rma = true; break; case 'Нет': item.rma = true; break; default: item.rma = null; }
        const formData = new FormData();
        formData.append('slug', slugService);
        if (file === null) formData.append('sla_document', '')
        else formData.append('sla_document', file)

        formData.append('service_type', serviceType);
        formData.append('description', descriptionService);
        if (contactService !== '') formData.append('service_center_contact', contactService);
        if (descriptionSlaService !== "") formData.append('sla_description', descriptionSlaService);
        if (сommentService !== '') formData.append('comment', сommentService);
        await ServiceService.patchService(formData, props.currentService.id).then(() => props.setEditService(!props.editService)).catch((err) => { setIsPreloader(false); setErrors(err.message) })
    }

    return (
        <Form onSubmit={patchingService} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row >
                <h3 className="mb-3">Изменить сервис</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="ServiceSlug" >
                        <Form.Label>SLUG</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={slugService}
                            placeholder="Введите.." onChange={(e) => setSlugService(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="ServiceAddr" >
                        <Form.Label>Тип сервиса</Form.Label>
                        <Form.Control size="sm" type="text" value={serviceType} placeholder="Введите.." onChange={(e) => setServiceType(e.target.value)} />
                        <Form.Text muted>
                            "full" "light"
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="ServiceContact" >
                        <Form.Label>Контакты сервисного центра</Form.Label>
                        <Form.Control size="sm" type="text" value={contactService} placeholder="Введите.." onChange={(e) => setContactService(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание</Form.Label>
                        <Form.Control
                            as="textarea"
                            required={true}
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={descriptionService}
                            size="sm"
                            onChange={(e) => setDescriptionService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Комментарий (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Комментарий "
                            rows={2}
                            type="text"
                            value={сommentService}
                            size="sm"
                            onChange={(e) => setCommentService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">SLA описание (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Описание "
                            rows={2}
                            type="text"
                            value={descriptionSlaService}
                            size="sm"
                            onChange={(e) => setDescriptionSlaService(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">RMA</Form.Label>
                        <Form.Select size="sm" id="selRma" onChange={(e) => setRmaService(e.target.value)} >
                            <option value="Да">Да</option>
                            <option value="Нет">Нет</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                {file === null && <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Файл </Form.Label>
                        <Form.Control
                            type="file"
                            size="sm"

                            onChange={(e) => setFile(e.target.files[0])}
                            accept='application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .docx'
                        />
                        <Form.Text muted>поддерживаемые типы файлов: ['pdf', 'xlsx', 'docx']
                        </Form.Text>
                    </Form.Group>
                </Col>}
                {file !== null &&
                    <Col className="mb-3 col-4">
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Файл SLA </Form.Label>
                            <img width="20" style={{ marginLeft: '10px' }} className="interactive_hover" alt="delete" src={ImgRemove} disabled onClick={
                                () => {

                                    setFile(null)

                                }
                            } />
                            <Form.Control size="sm" type="text" value={slaDocument} placeholder="Введите.." disabled />
                        </Form.Group>
                    </Col>}
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Изменить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setEditService(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default EditServiceForm;