import React, { useEffect, useContext, useRef } from 'react'
import { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Alert from 'react-bootstrap/Alert';
import LicenseService from '../../API/LicenseService';
import { AuthContext } from "../../context";
import Preloader from '../UI/Preloader/Preloader';
import HistoryList from './HistoryList';


function HistoryTab(props) {

    const [errors, setErrors] = useState([]);
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [history, setHistory] = useState();
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);


    const fetchHistoryData = async (add) => {
        setIsLoading(true);
        setIsHistoryLoading(true)
        await LicenseService.getHistoryList(add ? nextPage : 1).then((res) => {
            if (add === 'add') { setNextPage(res.next ? nextPage + 1 : null); res.results?.map(item => setHistory(arr => [...arr, item])); }
            else { setHistory(res.results); setNextPage(res.next ? 2 : null); }
            setTimeout(() => setIsHistoryLoading(false), 500)
        })
            .catch((err) => { setErrors([err]); changeStatus() }).finally(() => setIsLoading(false));

    }


    useEffect(() => {
        if (props.tabKey === 'history') {
            setIsLoading(true)
            setIsHistoryLoading(true)
            const timerRefresh = setTimeout(() => { fetchHistoryData() }, 800);
            return () => {
                clearTimeout(timerRefresh)
            }
        }

    }, [props.tabKey])

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {

                fetchHistoryData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);


    useEffect(() => {

        if (isHistoryLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && history?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isHistoryLoading]);

    return (
        <>
            <Col style={{ position: 'relative' }}>
                {<h3 className="mb-5">История загрузки</h3>}
                {isLoading && <Preloader leftCoord={true} />}
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>

                <HistoryList
                    historyItems={history}
                />
            </Col>
            <div ref={lastItem} style={{ height: 20 }} />
        </>
    )
}
export default HistoryTab