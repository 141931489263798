import React from "react";
import ImgRemove from '../../icons/remove.png';
import ImgEdit from '../../icons/edit.png'
import ImgAdd from '../../icons/add.svg';
const FortimanagersItem = (props) => {
    const dateStyles = {
        "fontSize": 'x-small',
    }

    return (

        <tr key={props.item.id + 'fortimanagerItem'}
            style={dateStyles}>
            <td>{props.item.name}</td>
            <td>{props.item.host}</td>
            <td>{props.item.date_modified}</td>
            <td><img width="25" src={ImgAdd} alt="add-file" onClick={
                (e) => { e.stopPropagation(); props.setIsFileUploading(true); props.setCurrentItem(props.item) }
            } />
            </td>
            <td>  <img width="25" src={ImgEdit} alt="edit" onClick={
                (e) => { e.stopPropagation(); props.setIsEditItem(true); props.setCurrentItem(props.item);}
            } /></td>
            <td>
                <img width="25" src={ImgRemove} alt="delete" onClick={
                    (e) => { e.stopPropagation(); props.removeItem(true); props.setCurrentItem(props.item) }
                } />
            </td>
        </tr>
    );
}

export default FortimanagersItem;