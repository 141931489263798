import React, {useState} from "react";
import QuotaItem from "./QuotaItem";
import Table from 'react-bootstrap/Table';
import CloneQuotaForm from './CloneQuotaForm';
import MyModal from "../UI/MyModal/MyModal";
const QuotaList = (props) => {
    const [currentQuota, setCurrentQuota] = useState(null)
    const basicStyles = {
        "fontSize": 'smaller',
    }
    const resetDateSumm = (arrow) => {
        props.setIsSortId(arrow);
        props.setIsSortDate(false);
        props.setIsSortSumm(false);
    }
    const resetIdSumm = (arrow) => {
        props.setIsSortDate(arrow);
        props.setIsSortId(false);
        props.setIsSortSumm(false);
    }
    const resetIdDate = (arrow) => {
        props.setIsSortSumm(arrow);
        props.setIsSortId(false);
        props.setIsSortDate(false);
    }
    return (
        <>
          { props.isCloneQuota && <MyModal visible={props.isCloneQuota} setVisible={props.setIsCloneQuota} align={true}>
           {currentQuota &&  <CloneQuotaForm quota={currentQuota}  setIsCloneQuota={props.setIsCloneQuota}/>}
    </MyModal>}
        <Table striped bordered hover >
            <thead style={basicStyles}>
                <tr>
                    <th style={{ width: "3%" }}>{'ID'}{<span style={{ color: props.isSortId === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => props.isSortId !== '↑' ? resetDateSumm('↑') : props.setIsSortId(false)}>↑</span>}{<span style={{ color: props.isSortId === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => props.isSortId !== '↓' ? resetDateSumm('↓') : props.setIsSortId(false)}>↓</span>}</th>
                    <th style={{ width: "4%" }}>Main</th>
                    <th style={{ width: "8%" }}>Изменено{<span onClick={() => props.isSortDate !== '↑' ? resetIdSumm('↑') : props.setIsSortDate(false)} style={{ color: props.isSortDate === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }}>↑</span>}{<span style={{ color: props.isSortDate === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => props.isSortDate !== '↓' ? resetIdSumm('↓') : props.setIsSortDate(false)}>↓</span>}</th>
                    <th style={{ width: "10%" }}>Конечник</th>
                    <th style={{ width: "10%" }}>Партнер</th>
                    <th style={{ width: "15%" }}>Описание</th>
                    <th style={{ width: "7%" }}>Sale руб{<span onClick={() => props.isSortSumm !== '↑' ? resetIdDate('↑') : props.setIsSortSumm(false)} style={{ color: props.isSortSumm === '↑' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }}>↑</span>}{<span style={{ color: props.isSortSumm === '↓' ? "blue" : "black", marginLeft: '5px', fontWeight: 900, fontSize: '16px', cursor: "pointer" }} onClick={() => props.isSortSumm !== '↓' ? resetIdDate('↓') : props.setIsSortSumm(false)}>↓</span>}</th>
                    <th style={{ width: "5%" }}>Скидка</th>
                    <th style={{ width: "5%" }}>Курс</th>
                    <th style={{ width: "6%" }}>Статус</th>
                    <th style={{ width: "6%" }}>Дата договора</th>
                    <th style={{ width: "3%" }}>Clone</th>
                </tr>
            </thead>
            <tbody>
                {props.quotaitems?.map(item =>

                    <QuotaItem
                        item={item}
                        key={item.id}
                        setViewQuota={props.setViewQuota}
                        viewOrg={props.viewOrg}
                        setViewOrg={props.setViewOrg}
                        setIsCloneQuota={props.setIsCloneQuota}
                        setCurrentQuota={setCurrentQuota}

                    />
                )
                }
                {/* <div ref={props.lastItem} style={{ height: 20 }} /> */}
                <tr ref={props.lastItem} style={{ height: 20 }}></tr>
            </tbody>
        </Table>
</>
        

    )
}

export default QuotaList;