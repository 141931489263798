import React, { useEffect, useState, useContext } from "react";
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import User from "../../API/User";
import UserCreateForm from "./UserCreateForm";
import { UserContext } from "../../context";
import Preloader from "../UI/Preloader/Preloader";

function UserList(props) {
    // eslint-disable-next-line no-unused-vars
    const { userinfo, setUserinfo } = useContext(UserContext);
    const userRole = userinfo.role;
    const basicStyles = {
        "position": 'relative',
        "fontSize": 'smaller',
    }
    const [isPreloader, setIsPreloader] = useState(false)
    const [userList, setUserList] = useState();
    const [userChanged, setUserChanged] = useState(false);
    const [isUserAdding, setIsUserAdding] = useState(false);
    // const [searchText, setSearchText] = useState("");

    const [errors, setErrors] = useState([]);

    useEffect(() => {

        (async () => {
            if (userChanged) {
                setUserChanged(false);
            };
            if (!props.orgId) {
                // const filters = {'searchText':searchText};
                const org = await OrgService.my();
                
                setUserList(org.users);
            } else {
                setIsPreloader(true);
                const org = await OrgService.getItem(props.orgId).finally(()=> setIsPreloader(false));
                setUserList(org.users);
            }

        })();

    }, [userChanged, props.userChanged, props.orgId]);

    async function deactivate(id) {
        try {
            await User.deactivate(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }

    async function activate(id) {
        try {
            await User.activate(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }

    async function makePrimary(id) {
        try {
            await User.makePrimary(id);
            // setOrgAdded(true);
            setUserChanged(true);
            setErrors([]);
        } catch (e) {
            setErrors(e.message);

        };
    }


    return (
        <Col sm={10}>
            <div><h5>Список пользователей</h5></div>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {!props.orgId &&
                <Button variant="outline-primary" size="sm" onClick={() => setIsUserAdding(true)}>Добавить пользователя</Button>
            }
            {
                (isUserAdding)
                &&
                <UserCreateForm
                    setIsUserAdding={setIsUserAdding}
                    setUserChanged={setUserChanged}
                />
            }
            {/* {
                isOrgAdding
                    &&
                        <OrgCreateForm 
                            setIsOrgAdding={setIsOrgAdding}
                            orgAddingType={orgAddingType}
                            setOrgAdded={setOrgAdded}
                        />
                } */}
            <hr />
            {/* <Form.Control onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Начните вводить..."
                    size="sm"
                    value={searchText}
                    ref={search}
                    />
                
                <hr /> */}
                                  
            <Table striped bordered hover>
                <thead style={basicStyles}>
                    <tr>
                        <th>Фамилия</th>
                        <th>Имя</th>
                        <th>Email (логин)</th>
                        <th>Роль</th>

                        <th>Должность</th>
                        <th>Телефон</th>

                        <th>Передать права</th>
                        <th>Статус</th>
                        <th>Дата входа</th>
                    </tr>

                </thead>
                <tbody style={basicStyles}>
                    {userList?.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.last_name}
                            </td>
                            <td>
                                {user.first_name}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td>
                                {user.role}
                            </td>

                            <td>
                                {user.job_title}
                            </td>
                            <td>
                                {user.phone}
                            </td>
                            <td>
                                {(user.is_active && user.role !== "PRIMARY_USER" && userRole === "PRIMARY_USER") &&
                                    <Button variant="outline-success" size="sm" onClick={async () => await makePrimary(user.id)} >Сделать основным</Button>
                                }
                            </td>
                            <td>
                                {(user.is_active && userRole === "PRIMARY_USER")
                                    ?
                                    <Button variant="outline-danger" size="sm" onClick={async () => await deactivate(user.id)} >Деактивировать</Button>
                                    :
                                    userRole === "PRIMARY_USER" &&
                                    <Button variant="outline-primary" size="sm" onClick={async () => await activate(user.id)} >Активировать</Button>
                                }
                            </td>
                            <td>
                                {user.last_login}
                            </td>

                        </tr>
                    )}
                </tbody>

            </Table>
            {isPreloader &&  <Preloader />}
        </Col>
    );
}
export default UserList;