import React, { useEffect, useState, useRef, useContext } from "react";
import Table from 'react-bootstrap/Table';
import ServiceService from "../../API/ServiceService";
import Preloader from "../UI/Preloader/Preloader";
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import AddSubForm from "./AddSubForm";
import EditSubForm from "./EditSubForm";
import ImgRemove from '../../icons/remove.png';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import SubscriptionDeleteForm from "./SubscriptionDeleteForm";

const SubscriptionList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }
    const [addSub, setAddSub] = useState(false);
    const [currentSub, setCurrentSub] = useState({});
    const [editSub, setEditSub] = useState(false);
    const [deleteSub, setDeleteSub] = useState(false)
    const [subList, setSubList] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [subAmount, setSubAmount] = useState(0);
    const [succesDelete, setSuccessDelete] = useState(false);
    const [errors, setErrors] = useState([]);

    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isSubLoading, setIsSubLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    useEffect(() => {
        (async () => {
            setNextPage(null)
            setIsSubLoading(true)
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getSubList(false).then((res) => {
                setSubList(res.results);
                setSubAmount(res.count);
                setNextPage(res.next);
                setIsSubLoading(false);
            }).catch(() => changeStatus());

            setIsPreloader(false)
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSub, editSub, succesDelete]);


    useEffect(() => {

        if (isSubLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubLoading]);

    useEffect(() => {

        (async () => {

            if (needToLoad) {

                setIsPreloader(true);
                setIsSubLoading(true);
                await ServiceService.getSubList(nextPage).then((res => {
                    res.results?.map(item => setSubList(arr => [...arr, item]));
                    setNextPage(res.next);
                    setIsSubLoading(false);
                    setNeedToLoad(false);
                })).finally(() => setIsPreloader(false));

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    const removeSub = async (subId) => {

        await ServiceService.deleteSub(subId).then(() => { setSuccessDelete(!succesDelete); setErrors([]) }).catch((err) => setErrors(err.message))
    }

    return (
        <>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row className="mb-3">
                <h5>Подписки (всего {subAmount})</h5>
            </Row>
            <Row>
                <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => setAddSub(true)}

                    >Добавить подписку</Button>
                </Col>
            </Row>
            {addSub === true && <AddSubForm setAddSub={setAddSub} addSub={addSub} />}
            {editSub === true &&
                <MyModal visible={editSub} setVisible={setEditSub}>

                    <EditSubForm setEditSub={setEditSub} editSub={editSub} currentSub={currentSub} />

                </MyModal>}

                {deleteSub === true &&
                <MyModal visible={deleteSub} setVisible={setDeleteSub}>

                    <SubscriptionDeleteForm setSuccessDelete={setSuccessDelete} setDeleteSub={setDeleteSub} deleteSub={deleteSub} currentSub={currentSub} />

                </MyModal>}
            <Table striped bordered hover >
                <thead style={basicStyles}>
                    <tr>
                        <th>ID</th>

                        <th >SLUG</th>
                        <th >Описание</th>
                        <th >Комментарий</th>
                        <th >Изменить </th>
                        <th >Удалить </th>
                    </tr>
                </thead>
                <tbody>
                    {subList?.map(item => (
                        <tr style={basicStyles} key={'service' + item.id}>

                            <td >{item.id}

                            </td>
                            <td>{item.slug}</td>
                            <td>{item.description}</td>
                            <td>{item.comment}</td>
                            <td>
                                <img width="20" className="interactive_hover" src={ImgEdit} alt={'edit'} disabled onClick={
                                    () => {

                                        setCurrentSub(item);
                                        setEditSub(true)
                                    }
                                } />
                            </td>
                            <td> <img width="20" className="interactive_hover" alt="change" src={ImgRemove} disabled onClick={
                                () => {

                                    setCurrentSub(item);
                                    setDeleteSub(true)

                                }
                            } /> </td>
                        </tr>))}
                    <tr ref={lastItem} style={{ height: 20 }} />
                </tbody>
            </Table>
            {isPreloader && <Preloader />}
        </>

    )
}

export default SubscriptionList;