import React, { useState} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Alert from 'react-bootstrap/Alert';


function PriceItemEditForm(props) {

    const [description, setDescription] = useState(props.currentdescription);
    const [price, setPrice] = useState(props.currentprice);
    const [errors, setErrors] = useState([]);

    const editItem = async event => {
        event.preventDefault();

        const edititem = {
            'id': props.itemToEdit.id,
            'name': description,
            'price': price,
        };
        if (description === "") {
            edititem.name = props.itemToEdit.name;
        }
        if (price === "") {
            edititem.price = props.itemToEdit.price;
        }
        try {
            await PriceService.updateItem(edititem);
            props.setPriceChanged(!props.priceChanged);
            props.setIsItemEditing(false);
            props.setVisible(false);
            props.setItemToEdit({});
            setErrors([]);
        } catch(e) {
            setErrors(e.message);
        };
        
    }
    
    return (
        <Form onSubmit={editItem}>
            {errors.length > 0 &&
                errors.map(error => 
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group className="mb-4">
                <Form.Label>Платформа</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.platform_name} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Категория</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.category} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Парт-номер</Form.Label>
                <Form.Control type="text" value={props.itemToEdit.part_number} disabled />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Control as="textarea" rows={5} type="text" defaultValue={props.currentdescription} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Цена за ед.</Form.Label>
                <Form.Control type="text" value={price} onChange={(e) => setPrice(e.target.value?.replace(',','.'))} />
            </Form.Group>
            <Button variant="primary" type="submit">
                Сохранить
            </Button>
        </Form>
    );
}
export default PriceItemEditForm;