import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import Button from 'react-bootstrap/Button';
import AssetList from "./AssetList";
import AssetService from "../../API/AssetService";
import AddAssetForm from "./AddAssetForm";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import PlatformService from "../../API/PlatformService";
import SolutionService from "../../API/SolutionService";
import SolutionList from "./SolutionList";
import { AuthContext } from "../../context";
import Alert from 'react-bootstrap/Alert';
import MyModal from "../UI/MyModal/MyModal";
import { downloadExcel } from "react-export-table-to-excel";
import Auth from "../../API/Auth";

function AssetArea(props) {

    const [arrSlugFilter, setArrSlugFilter] = useState([]);
    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [successDelete, setSuccessDelete] = useState(false);
    const [deletingAsset, setDeletingAsset] = useState(false);
    const [currentAsset, setCurrentAsset] = useState({})
    //пагинация
    const [isAssetLoading, setIsAssetLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isAddingAsset, setIsAddingAsset] = useState(false);
    const [copyAsset, setCopyAsset] = useState(false)

    // перечень всех устройств
    const [assetItems, setAssetItems] = useState();
    const [totalAssetItems, setTotalAssetItems] = useState();

    // поиск и фильтрация
    const [searchText, setSearchText] = useState("");
    const [platformList, setPlatformList] = useState(null);
    const [platformFilter, setPlatformFilter] = useState(false);
    const [citiesList, setCitiesList] = useState(null);
    const [countriesList, setCountriesList] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [citySearchText, setCitySearchText] = useState("");
    const [text, setText] = useState('')

    // eslint-disable-next-line no-unused-vars
    const [countrySearchText, setCountryySearchText] = useState("");
    const [cityFilter, setCityFilter] = useState(false);
    const [countryFilter, setCountryFilter] = useState(false);
    const [slugFilter, setSlugFilter] = useState(false);
    const [slugList, setSlugList] = useState([]);
    const [rmaFilter, setRmaFilter] = useState(false);

    //гипервизоры
    // const [viewHypervisorList, setViewHypervisorList] = useState(false);
    // const [hypervisor, setHypervisor] = useState(null);
    // const [hypervisorAdd, setHypervisorAdd] = useState(false)
    // const [hypervisorEdit, setHypervisorEdit] = useState(false);

    //решения
    const [viewSolutionList, setViewSolutionList] = useState(false);
    const [solution, setSolution] = useState(null);
    const [solutionAdd, setSolutionAdd] = useState(false)
    const [solutionEdit, setSolutionEdit] = useState(false);
    const [solutionDelete, setSolutionDelete] = useState(false);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

const fetchData=async(add)=> {
            let filters = false;

            if (platformFilter || searchText || cityFilter || countryFilter || arrSlugFilter.length > 0 || rmaFilter) {
                filters = {
                    platformFilter: platformFilter,
                    searchText: searchText,
                    cityFilter: cityFilter,
                    countryFilter: countryFilter,
                    slugFilter: arrSlugFilter,
                    rmaFilter: rmaFilter
                };
            }

            setIsPreloader(true);
            setIsAssetLoading(true);

            const id = props.orgId ? props.orgId : false;
            await AssetService.getList(id, filters, add ? nextPage : 1)
            .then((res) => {

                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);  res.results?.map(item => setAssetItems(arr => [...arr, item])); }
                else { setAssetItems(res.results); setNextPage(res.next ? 2 : null); }
          
                setTotalAssetItems(res.count);
                setTimeout(()=>setIsAssetLoading(false), 500);
            }).catch((err) => {changeStatus()}).finally(() => setIsPreloader(false))
}
    
    useEffect(() => {
if (!isAddingAsset){
        setIsPreloader(true)
        setIsAssetLoading(true);
        const timerRefresh = setTimeout(fetchData, 800);
        return () => {

            clearTimeout(timerRefresh)
        }}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingAsset, platformFilter, searchText, cityFilter, countryFilter, rmaFilter, arrSlugFilter, copyAsset, successDelete, solutionAdd, solutionEdit, solutionDelete]);

    //Решения

    useEffect(() => {

        (async () => {

            await SolutionService.getList().then((res) => setSolution(res)).catch(() => changeStatus());

        })();
    }, [solutionAdd, solutionEdit, solutionDelete]);

    //Платформа
    useEffect(() => {
        (async () => {
            await PlatformService.getList().then((res) => setPlatformList(res)).catch(() => changeStatus());
            await AssetService.getSlug().then((res) => setSlugList(res)).catch(() => changeStatus());
            // setSlugList(slugs&&slugs)
            // setPlatformList(platforms&&platforms);

        })();
    }, []);
    //Города
    useEffect(() => {
        (async () => {
            let filters = false;
            if (citySearchText) {
                filters.searchText = citySearchText;
            }
            await AssetService.getCities(filters).then((res) => setCitiesList(res?.results)).catch(() => changeStatus());
            // setCitiesList(response?.results);

        })();
    }, [citySearchText, isAddingAsset]);
    //Страны
    useEffect(() => {
        (async () => {
            let filters = false;
            if (countrySearchText) {
                filters.searchText = countrySearchText;
            }
            await AssetService.getCountries(filters).then((res) => setCountriesList(res?.results)).catch(() => changeStatus());
            // setCountriesList(response?.results);

        })();
    }, [countrySearchText, isAddingAsset]);

    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {
               
                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);
    useEffect(() => {
        
        if (isAssetLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && assetItems?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isAssetLoading]);

    const clearFilters = () => {
        setPlatformFilter(false);
        setCityFilter(false)
        setCountryFilter(false);
        setSearchText("");
        setSlugFilter(false);
        setRmaFilter(false);
        setArrSlugFilter([]);
        setText('');
    }

    function changeHandler(currentSlug) {

        if (arrSlugFilter.some((item) => item === currentSlug) !== true) {
            setArrSlugFilter([...arrSlugFilter, currentSlug]);
            setSlugFilter(true)
        } else { const index = arrSlugFilter.findIndex((item) => currentSlug === item); setArrSlugFilter([...arrSlugFilter.slice(0, index), ...arrSlugFilter.slice(index + 1)]) }
    }
    const refreshToken = async (id) => {
        await Auth.refreshToken(localStorage.getItem('freshTokenFP'))
            .then((res) => {
                localStorage.setItem('tokenFP', res.data.access); setErrors([]);
                removeAsset(id);
            })
            .catch(() => changeStatus())
    }


    const removeAsset = async (assetId) => {

        await AssetService.deleteAsset(assetId).then(() => { setSuccessDelete(!successDelete); setDeletingAsset(false); setErrors([]) })
        .catch((err) => {
            if (err.message[0].includes('token')) refreshToken(assetId);
            else {
                setErrors(err.message); setIsPreloader(false);
            }
        })
    }

    function handleDownloadExcel(arr, header) {
        var now = (new Date()).toISOString();
        now = now.replace('.', '-');

        downloadExcel({
            fileName: `assets-${now}`,
            sheet: "assets",
            tablePayload: {
                header,
                body: arr,
            },
        });
    }
    function exportData() {


        var table = document.getElementById("tableExcell");

        var rows = [];
        var headRows = [];


        for (var i = 0, row; row = table.rows[i]; i++) {
            if (i === 0) {
                let column1 = row.cells[1].innerText;
                let column2 = row.cells[2].innerText;
                let column3 = row.cells[3].innerText;
                let column4 = row.cells[4].innerText;
                let column5 = row.cells[5].innerText;
                let column6 = row.cells[6].innerText;
                let column7 = row.cells[7].innerText;
                let column8 = row.cells[8].innerText;
                let column9 = row.cells[9].innerText;
                let column10 = row.cells[10].innerText;

                headRows.push(

                    column1,
                    column2,
                    column3,
                    column4,
                    column5,
                    column6,
                    column7,
                    column8,
                    column9,
                    column10,

                )
            } else {
                let column1 = row.cells[1].innerText;
                let column2 = row.cells[2].innerText;
                let column3 = row.cells[3].innerText;
                let column4 = row.cells[4].innerText;
                let column5 = row.cells[5].innerText;
                let column6 = row.cells[6].innerText;
                let column7 = row.cells[7].innerText;
                let column8 = row.cells[8].innerText;
                let column9 = row.cells[9].innerText;
                let column10 = row.cells[10].innerText;

                rows.push(
                    [
                        column1,
                        column2,
                        column3,
                        column4,
                        column5,
                        column6,
                        column7,
                        column8,
                        column9,
                        column10,

                    ])
            }


        };
        handleDownloadExcel(rows, headRows)
        // let csvContent = "data:dataType/xlsx;charset=ufeff,";
        // rows.forEach(function(rowArray){
        //     row = rowArray.join(",");
        //     csvContent += row + "\r\n";
        // });

        // var encodedUri = encodeURI(csvContent);
        // var link = document.createElement("a");
        // link.setAttribute("href", encodedUri);
        // link.setAttribute("download", `Stock_Price_Report-${now}.xlsx`);
        // document.body.appendChild(link);
        // link.click();
    }

    return (
        <>

            <Col>
                {!location.pathname.includes("orglist") && isAddingAsset === false &&
                    <h3 className="mb-3">Устройства (отображено {totalAssetItems} устройств)</h3>}
                {location.pathname !== "/orglist" && isAddingAsset === true &&
                    <h3 className="mb-3">Добавить устройство</h3>}
                {/* {!location.pathname.includes("orglist") && assetsStats !== null && assetsStats?.assets_per_platform_type.map((platform, index) => (<Row key={'plat' + platform.platform__type + index} className="mb-2">
                    <b style={{ color: "green" }}>Устройств по типу платформы "{platform.platform__type}": {platform.count} шт.</b>
                </Row>))}  */}
                {/* {!location.pathname.includes("orglist") && <Row className="mt-4 mb-3"> */}
                    {/* <Col className="mb-3 col-3 ">
                        <Button style={{ width: '100%' }} onClick={() => setViewAllStats(!viewAllStats)} variant="outline-dark" > {viewAllStats === false ? 'Показать всю информацию по платформам' : 'Скрыть информацию'}</Button></Col>
                    <Col className="mb-3 col-3 ">
                        <Button style={{ width: '100%' }} onClick={() => setViewHypervisorList(!viewHypervisorList)} variant="outline-dark" > {viewHypervisorList === false ? 'Гипервизоры' : 'Скрыть информацию'}</Button>
                    </Col> */}
                {/* </Row>} */}
                {/* {viewHypervisorList === true && <Row>
                    <HypervisorList
                        hypervisor={hypervisor}
                        setViewHypervisorList={setViewHypervisorList}
                        setHypervisorAdd={setHypervisorAdd}
                        hypervisorAdd={hypervisorAdd}
                        setHypervisorEdit={setHypervisorEdit}
                        hypervisorEdit={hypervisorEdit}

                    />

                </Row>} */}
                {!location.pathname.includes("orglist") && <Row className="mt-4 mb-3">
                    <Col className="mb-3 col-3 ">
                        <Button style={{ width: '100%' }} onClick={() => setViewSolutionList(!viewSolutionList)} variant="outline-dark" > {viewSolutionList === false ? 'Решения' : 'Скрыть информацию'}</Button></Col></Row>}

                {viewSolutionList === true && <Row>
                    <SolutionList
                        solution={solution}
                        setViewSolutionList={setViewSolutionList}
                        setSolutionAdd={setSolutionAdd}
                        solutionAdd={solutionAdd}
                        setSolutionEdit={setSolutionEdit}
                        solutionEdit={solutionEdit}
                        setSolutionDelete = {setSolutionDelete}
                        solutionDelete={solutionDelete}
                    />
                </Row>}
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                {deletingAsset &&
                    <MyModal visible={deletingAsset} setVisible={setDeletingAsset}>
                        <Row>

                            <h5 className="mb-3">Удалить устройство?</h5>
                        </Row>
                        <Row> {errors.length > 0 &&
                            errors.map(error =>
                                <Alert key="danger" variant="danger">
                                    {error}
                                </Alert>
                            )
                        }</Row>
                        <Row>
                            <Col >
                                <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }} onClick={(e) => { e.preventDefault(); removeAsset(currentAsset.id) }}>
                                    Да
                                </Button>

                            </Col>
                            <Col >
                                <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); setDeletingAsset(false); setErrors([]) }} style={{ width: '100%' }}>
                                    нет
                                </Button>
                            </Col>
                        </Row>

                    </MyModal>}

                <Row>
                    <Col className="mb-3 col-3">
                        {location.pathname === '/asset' && !isAddingAsset && <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { setIsAddingAsset(true) }}

                        >Добавить устройство</Button>}

                    </Col>

                    <Col className="mb-3 col-3">
                        {location.pathname === '/asset' && !isAddingAsset && <Button type="link" variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { exportData() }}

                        >Скачать в Excel</Button>}

                    </Col>

                </Row>  <hr />

                {/* ФИЛЬТРЫ */}

                {location.pathname === '/asset' && <Row className="justify-content-start">
                    {isAddingAsset && <AddAssetForm setIsAddingAsset={setIsAddingAsset} />}
                    <Col className="mb-3 col-2" >
                        <Form.Label size="sm">Поиск</Form.Label>
                        <Form.Control onChange={(e) => { setSearchText(e.target.value) }}
                            placeholder="Начните вводить..."
                            size="sm"
                            style={{ fontSize: '1rem', height: 38 }}
                            //disabled={isAssetLoading}
                            value={searchText}
                        />
                    </Col>
                    <Col className="mb-3 col-2 ">
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Dropdown style={{ width: "100%" }}>
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-platforms">
                                {platformFilter.name || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setPlatformFilter={setPlatformFilter}
                                type='platform'
                            >
                                {platformList?.map(platform =>
                                    <Dropdown.Item eventKey={"apl" + platform.id} key={"apl" + platform.id} onClick={() => { setPlatformFilter(platform) }}>{platform.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 " >
                        <Form.Label size="sm">Город</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-cities" >
                                {cityFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setCityFilter={setCityFilter}
                                type='city'
                            >
                                {citiesList?.map(city =>
                                    <Dropdown.Item eventKey={"ci" + city.city} key={"ci" + city.city} onClick={() => { setCityFilter(city.city) }}>{city.city}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 ">
                        <Form.Label size="sm">Страна</Form.Label>
                        <Dropdown style={{ width: "100%" }} >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-countries" >
                                {countryFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                data-bs-auto-close="outside"
                                as={MyMenu}
                                setCountryFilter={setCountryFilter}
                                type='country'
                            >
                                {countriesList?.map((country, index) => {
                                    return <Dropdown.Item eventKey={"co" + country.country} key={"co" + country.country} onClick={(e) => { setCountryFilter(country.country) }}>{country.country}</Dropdown.Item>
                                }
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col className="mb-3 col-2 ">

                        {/* <Form.Label size="sm">SLUG:
    {arrSlugFilter?.map((item, index) =>{ return 
        <span key={'col' + item.id + index} className='col-md-auto ' style={{ padding: "0 2px", margin: "0 3px", borderRadius: '5px', border: ".5px solid #0d6efd", boxSizing: "border-box" }} onClick={(e) => { e.stopPropagation(); setArrSlugFilter([...arrSlugFilter.slice(0, index), ...arrSlugFilter.slice(index + 1)]) }}>{item}
        </span>})}</Form.Label> 
<MyFilterSelect slug={slugList} arr={arrSlugFilter} setArr={setArrSlugFilter}
    setSlugFilter={setSlugFilter} slugFilter={slugFilter}
/> */}
                        <Form.Label size="sm">SLUG:
                            {/* {arrSlugFilter?.map((item, index) =>
                                <span key={'col' + item.id + index} className='col-md-auto ' style={{ padding: "0 2px", margin: "0 3px", borderRadius: '5px', border: ".5px solid #0d6efd", boxSizing: "border-box" }} onClick={(e) => { e.stopPropagation(); setArrSlugFilter([...arrSlugFilter.slice(0, index), ...arrSlugFilter.slice(index + 1)]) }}>{item}
                                </span>
                            )} */}
                        </Form.Label>
                        <Dropdown style={{ width: "100%" }} >
                            <Dropdown.Toggle as={MyToggleAsset} size={arrSlugFilter?.length > 1 ? 'min' : 'normal'}>
                                {(arrSlugFilter?.length && arrSlugFilter?.map((item, index) => item + ' ')) || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu

                                as={MyMenu}

                                type='multi'
                            >
                                {slugList?.map((slug, index) =>
                                    <Dropdown.Item className={arrSlugFilter.some((item) => item === slug) === true ? 'active' : ""} eventKey={"slug" + slug + index} key={"slug" + slug + index} onClick={(e) => { e.stopPropagation(); changeHandler(slug) }}>{slug}</Dropdown.Item>

                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <Dropdown style={{ width: "100%" }} multiple>
                                <Dropdown.Toggle as={MyToggleAsset} id="dropdown-slug" >
                                    {slugFilter || "выбрать"}
                                                            </Dropdown.Toggle>
                        <Dropdown.Menu
                                        as={MyMenu}
                                    filter={setSlugFilter}
                                        type='slug'
                                        
                                    >
                                        {slugList?.map((slug, idx) =>
                                            <Dropdown.Item eventKey={"slu" + idx} key={"slu" + idx} onClick={() => { setSlugFilter(slug) }}>{slug}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown> */}
                    </Col>
                    <Col className="mb-3 col-2" >
                        <Form.Label size="sm">RMA</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-rma" >
                                {rmaFilter || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setRmaFilter}
                                type='rma'
                            >
                                <Dropdown.Item eventKey={"rm" + 1} key={"rm" + 1} onClick={() => { setRmaFilter('true') }}>{'Да'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"rm" + 2} key={"rm" + 2} onClick={() => { setRmaFilter('false') }}>{'Нет'}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>


                </Row>}
                {location.pathname === '/asset' && <Row>


                    {(platformFilter || cityFilter || countryFilter || platformFilter || searchText || slugFilter || rmaFilter) &&
                        <Col className="col-2">

                            <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', margin: '10px 0' }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                        </Col>}
                </Row>}

                <Row>
                    <Col style={{ position: "relative" }}>

                        <AssetList assetItems={assetItems} copyAsset={copyAsset} setCopyAsset={setCopyAsset} close={props.close} removeAsset={setDeletingAsset} currentAsset={currentAsset} setCurrentAsset={setCurrentAsset} />
                        {isPreloader && <Preloader />}
                    </Col>    
                    <div ref={lastItem} style={{ height: 20 }} />
                </Row>
            
            </Col>
        </>
    );
}
export default AssetArea;