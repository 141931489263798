import React from "react";
import Table from 'react-bootstrap/Table';
import HistoryItem from "./HistoryItem";

const HistoryList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <>

            <Table striped bordered hover id='tableExcell' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Дата</th>
                        <th>Fortimanager</th>
                        <th>Файл</th>
                        <th>Пользователь</th>
                    </tr>
                </thead>
                <tbody>

                    {props.historyItems?.map((item, index) =>

                        <HistoryItem
                            item={item}
                            key={item.id + "histIt" + index}
                        />
                    )
                    }
                </tbody>
            </Table></>
    )
}

export default HistoryList;

