import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DashboardService from "../../API/DashboardService";

function DashboardOrgDoug(props) {

    const [orgsStats, setOrgsStats] = useState(null);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    ChartJS.register(ArcElement, Tooltip, Legend);
    useEffect(() => {
        (async () => {
            await DashboardService.getOrgStats().then((res) => setOrgsStats(res)).catch(() => changeStatus());
            // setOrgsStats(orgs)
        })()
    }, [])

    const data = {
        labels: ['КОНЕЧНИКИ', 'ПАРТНЕРЫ'],
        datasets: [
            {
                datalabels: {
                    color: 'black',
                    opacity: 0

                },

                data: orgsStats !== null && [orgsStats.enduser_orgs, orgsStats.partner_orgs],

                borderWidth: 1,
            },
        ],
    };
    const options = {

        plugins: {
            colors: {
                enabled: true,
                forceOverride: true
            },
            datalabels: {
                color: '#36A2EB'
            },
            legend: {
                position: 'top',
                labels: { boxWidth: 20 }

            },
            tooltip: {
                enabled: true
            },

        },

        rotation: (180 / 2) * -1,
        circumference: 180,
        cutout: "60%",
    }



    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <p style={{ position: "absolute", top: "60%", left: "48%", fontSize: '30px', color: 'rgb(13, 110, 253, .8)' }}><b>{orgsStats?.total_orgs}</b></p>
            <Doughnut data={data} options={options} />
        </div>
    );
}
export default DashboardOrgDoug;