import React, {  useState } from "react";
import Form from 'react-bootstrap/Form';
import { useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';

const MyMenu = React.forwardRef(
  ({ children, style, filterActiveAssets, filterAssets, className, setOrgAddingType, orgType, setIsOrgAdding, isOrgAdding, hideOrgMenu, orgAdded, setHideOrgMenu, clickFunction, 'aria-labelledby': labeledBy, setPlatformFilter, setCityFilter, setPaymentFilter, setCountryFilter, filter, filterQa, type }, ref) => {
    const [value, setValue] = useState('');
    const location = useLocation();
    let myMenu = document.getElementById(`dropMenu${type}`);
    const closeMenu = () => myMenu.classList?.remove('show');

    // useEffect(()=> {
    //   if (orgAdded===true) closeMenu()},[orgAdded])


    // if (isOrgAdding || hideOrgMenu) {
    //   return (
    //     <div></div>
    //   );
    // } else {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby="dropdownMenuClickableInside"
        // aria-labelledby={labeledBy}
        id={`dropMenu${type}`}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto sm"
          placeholder="Ведите название..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
         { type=== 'assTypeHyp' &&
          <Button variant="light mb-2 mt-2" onClick={(e) => { e.preventDefault(); clickFunction(true); closeMenu() }}>Добавить</Button>
        }
        {location.pathname === "/quotator" && setIsOrgAdding &&
          <Button variant="light mb-2 mt-2" onClick={(e) => { e.preventDefault(); setIsOrgAdding(true); setOrgAddingType(orgType); closeMenu() }}>Добавить</Button>
        }
        <li><hr className="dropdown-divider" /></li>
        {filterQa && <Button className="mb-2" variant="light " onClick={() => { setValue('') }}>Показать все </Button>}
        {filter && <Button className="mb-2" variant="light " onClick={() => { filter(false); closeMenu(); myMenu = null }}>Показать все </Button>}
        {filterAssets && filterActiveAssets&&<Button className="mb-2" variant="light " onClick={() => { filterAssets(false); closeMenu(); myMenu = null; filterActiveAssets(null)}}>Показать все</Button>}
        <hr className="dropdown-divider" />
        {setCityFilter && <Button className="mb-2" variant="light " onClick={() => { setCityFilter(false); closeMenu(); myMenu = null }}>Показать все </Button>}
        {setCountryFilter && <Button className="mb-2" variant="light " onClick={() => { setCountryFilter(false); closeMenu(); myMenu = null }}>Показать все </Button>}
        {setPlatformFilter && <Button className="mb-2" variant="light" onClick={() => { setPlatformFilter(false); closeMenu(); myMenu = null }}>Показать все </Button>}
        {setPaymentFilter && <Button variant="light mb-2" onClick={() => { setPaymentFilter(false); closeMenu(); myMenu = null }}>Показать все </Button>}
        {/* {location.pathname === "/quotator" && setIsOrgAdding && 
          <Button variant="light mb-2 text-decoration-underline" onClick={(e) => { e.preventDefault(); setIsOrgAdding(true); setOrgAddingType(orgType); closeMenu() }}>Добавить</Button>
          } */}
        <li><hr className="dropdown-divider" /></li>
        <ul className="list-unstyled" aria-labelledby="dropdownMenuClickableInside">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.trim().toLowerCase().includes(value.toLowerCase()),
          )}
        </ul>
      </div>
    );
  }

);

export default MyMenu;