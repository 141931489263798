import React from "react";
import Table from 'react-bootstrap/Table';
import AssetItem from "./WarehouseItem";

const WarehouseList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <>

        <Table striped bordered hover id='tableExcell' >
            <thead style={basicStyles}>
                <tr>
       
                <th>Серийный номер</th>
                    <th>Платформа</th>
                    
                    <th>Документы</th>
                    <th>Состояние</th>
                    <th>Коробка</th>
                    <th>Находится</th>
                    <th>Коммент</th>
                    <th>Цена</th>
                    <th>Дата покупки</th>
                    <th>Поставщик</th>
                    <th>Фото</th>
                    <th>Copy</th>
                    <th>Изменить</th>
                    <th>Удалить</th>
                </tr>
            </thead>
            <tbody>
               
               { props.assetItems?.map((item, index) =>
              
                    <AssetItem
                        item={item}
                        key={item.id + "assIt" + index}
                        viewOrg={props.viewOrg}
                        setViewOrg={props.setViewOrg}
                        close={props.close}
                        setIsCopyAsset={props.setCopyAsset}
                        setIsEditAsset={props.setEditAsset}
                        setCurrentAsset={props.setCurrentAsset}
                        removeAsset={props.removeAsset}
                        showPhoto={props.showPhoto}
                        setShowPhoto={props.setShowPhoto}
                    />
                )
                }
            </tbody>
        </Table></>
    )
}

export default WarehouseList;

