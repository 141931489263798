import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import MyLoader from "../UI/MyLoader/MyLoader";
import MyValidation from "../../core/MyValidation";
import Alert from 'react-bootstrap/Alert';


function FileUploadForm(props) {

    const [uploadingPrice, setUploadingPrice] = useState("");
    const [switchingVersion, setSwitchingVersion] = useState("");
    const [errorUploadFile, setErrorUploadFile] = useState(false);
    const [priceDescription, setPriceDescription] = useState("");
    const [errors, setErrors] = useState([]);

    const upload = async event => {
        event.preventDefault();

        const fileCheck = MyValidation.priceFileValidate(props.file);
        if (fileCheck.status) {
            setErrorUploadFile(false);
            try {
                setUploadingPrice("progress");
                const data = await PriceService.uploadFilePrice(props.file);
                setUploadingPrice("done");
                setSwitchingVersion("progress");
                const data2 = await PriceService.switchVersion(data.new_pricelist_version, priceDescription);
                if (data2) {
                    setSwitchingVersion("done");
                    props.PriceChanged(true);
                }
                props.setFileUploading(false);
                props.setFile();
                setErrors([]);
            } catch(e) {
       
                setErrors(e.message);
                setUploadingPrice("");
            };
            
            
        } else {
            setErrorUploadFile(fileCheck.errors);
            props.setFileUploading(false);
            props.setFile();
        }
    }
    
    
    return (
        <Form onSubmit={upload} >
            {errorUploadFile &&
                errorUploadFile?.map(error => 
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {errors.length > 0 &&
                errors.map(error => 
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Форма загрузки прайс-листа</Form.Label>
                <Form.Control type="file" onChange={(e) => props.setFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Описание</Form.Label>
                <Form.Control type="text" value={priceDescription} onChange={(e) => setPriceDescription(e.target.value)} />
            </Form.Group>
            <Button variant="primary" type="submit">
                Загрузить
            </Button>
            <hr />
            {(uploadingPrice === "progress")
                ?
                    <MyLoader />
                :
                    (uploadingPrice === "done")
                    &&
                        <h5>Файл загружен!</h5>
                        
            }
            {(switchingVersion === "progress")
                ?
                    <div>Переключение на новую версию</div>
                :
                    (switchingVersion === "done")
                    && 
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label><h5>Переключение на новую версию прошло успешно</h5></Form.Label>
                        <br />
                        <Button variant="primary" onClick={() => {
                                props.setVisible(false);
                                setUploadingPrice("");
                                setSwitchingVersion("");
                            }}>
                            Закрыть окно
                        </Button>
                    </Form.Group>
            
            }
        </Form>
        
    );
}
export default FileUploadForm;