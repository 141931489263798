import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import SolutionService from "../../API/SolutionService";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";

function SolutionEditForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);
    const [solutionName, setSolutionName] = useState(props?.solution?.name);
    const [solutionDescription, setSolutionDescription] = useState(props?.solution?.description || "");

    const editSolution = async event => {
        event.preventDefault();
        setIsPreloader(true)
        const solution = {};
        solution.name = solutionName;
        solution.description = solutionDescription;

        await SolutionService.patchSolution(solution, props.solution?.id)
            .then(() => { setTimeout(() => { props.setVisible(false); props.setEditSol(!props.solEdit); setErrors(null); setIsPreloader(false) }, 500) })
            .catch((e) => {setErrors(e.message); setIsPreloader(false)});
    }

    return (
        <Form
            onSubmit={editSolution}
            className='was-validated'
            style={{ position: 'relative', width:'100%' }}
        >
            {errors.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            
            <h3 className="mb-3">Редактировать решение</h3>
            {isPreloader && <Preloader />}
            <Form.Group className='mb-4'>
                <Form.Label>Название</Form.Label>
                <Form.Control type="text" required={true} value={solutionName} onChange={(e) => setSolutionName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Описание (опционально)</Form.Label>
                <Form.Control type="text" value={solutionDescription} onChange={(e) => setSolutionDescription(e.target.value)} />
            </Form.Group>
            <Row>
                <Col sm={6}>
                    <Button variant="outline-primary" type="submit" size="sm" style={{width:'100%'}}>
                        Изменить
                    </Button></Col>
                <Col sm={6}>
                    <Button variant="outline-danger" size="sm" type="button" style={{width:'100%'}} onClick={() => props.setVisible(false)}>
                        Отменить
                    </Button></Col></Row>
        </Form>
    );
}
export default SolutionEditForm;