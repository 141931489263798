import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import CloudList from "./CloudList";
import ServiceList from "./ServiceList";
import SubscriptionList from "./SubscriptionList";
function ServiceArea(props) {

    const [key, setKey] = useState('service');
    return (
        <Col>
            <h3>Сервисы</h3>
            <hr />
            <Tabs
                id="service-tabs"
                activeKey={key}
                onSelect={(key) => setKey(key)}
                className="mb-3"
            >
                <Tab eventKey="service" title="Сервисы" >
                    < ServiceList />
                </Tab>
                <Tab eventKey="subs" title="Подписки">
                    {key === 'subs' && <SubscriptionList />}
                </Tab>
                <Tab eventKey="clouds" title="Облака" >
                    {key === 'clouds' && <CloudList />}
                </Tab>
            </Tabs>
        </Col>
    )
}

export default ServiceArea;