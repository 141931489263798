import React from "react";
import PriceItem from "./PriceItem";
import Table from 'react-bootstrap/Table';

const PriceList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <Table striped bordered hover>
        {!props.isQuotaAdding
            ?
            <thead style={basicStyles}>
                <tr>
                <th>Тип</th>
                <th>Платформа</th>
                <th>Категория</th>
                <th>Парт-номер</th>
                <th>Описание</th>
                <th>Стоимость</th>
                <th>Изменить</th>
                <th>Удалить</th>
                </tr>
            </thead>
            :
            <thead style={basicStyles}>
                <tr>
                <th>Тип</th>
                <th>Платформа</th>
                <th>Категория</th>
                <th>Парт-номер</th>
                <th>Описание</th>
                <th>Стоимость</th>
                <th></th>
                </tr>
            </thead>
        }
        <tbody style={basicStyles}>
        {props.priceitems?.map(item =>
            <PriceItem 
                item={item}
                key={item.id}
                itemChanged={props.itemChanged}
                setModal={props.setModal}
                setDeletePriceItem={props.setDeletePriceItem}
                setCurrentPriceItem = {props.setCurrentPriceItem}
                setIsItemEditing={props.setIsItemEditing}
                setItemToEdit={props.setItemToEdit} 
                setCurrentDescription={props.setCurrentDescription}
                setCurrentPrice={props.setCurrentPrice}
                isQuotaAdding={props.isQuotaAdding}
                addItem={props.addItem}
            />
            )
        }
        <tr></tr>
        </tbody>
        </Table>
    )
}

export default PriceList;