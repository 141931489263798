import PricePage from "../pages/PricePage"
import QuotatorPage from "../pages/QuotatorPage";
import LoginPage from "../pages/LoginPage";
import PassRecoverPage from "../pages/PassRecoverPage";
import MyOrgPage from "../pages/MyOrgPage";
import AllOrgsPage from "../pages/AllOrgsPage";
import PasswordRecoverConfirm from "../components/UI/PasswordRecoverConfirm";
import ContractPage from "../pages/ContractPage";
import AssetPage from "../pages/AssetPage";
import OrgDetailPage from "../pages/OrgDetailPage";
import ContractDetailPage from "../pages/ContractDetailPage";
import AssetDetailPage from "../pages/AssetDetailPage";
import ServicePage from "../pages/ServicePage";
import Dashboard from "../pages/Dashboard";
import WarehousePage from "../pages/WarehousePage";
import LicensePage from "../pages/LicensePage";
// import StatusFileRenewPage from "../pages/StatusFileRenewPage";

export const vendorRoutes = [
    
    { path: 'price', component: <PricePage /> },
    { path: 'quotator', component: <QuotatorPage /> },
    { path: 'orglist', component: <AllOrgsPage /> },
    { path: 'myorg', component: <MyOrgPage /> },
    { path: 'contract', component: <ContractPage /> },
    { path: 'asset', component: <AssetPage /> },
    { path: 'service', component: <ServicePage /> },
    { path: '*', component: <Dashboard /> },
    { path: 'orglist/:idOrg', component: <OrgDetailPage/> },
    { path: 'contract/:idCont', component: <ContractDetailPage/> },
    { path: 'asset/:idAss', component: <AssetDetailPage /> },
    { path: 'dashboard', component: <Dashboard /> },
    { path: 'warehouse', component: <WarehousePage /> },
    {path: 'license', component: <LicensePage/>}
    // { path: 'status_files', component: <StatusFileRenewPage /> },
]

export const otherPrivateRoutes = [
    { path: 'myorg', component: <MyOrgPage /> },
    { path: '*', component: <MyOrgPage /> },
]

export const publicRoutes = [
    { path: 'login', component: <LoginPage /> },
    { path: 'passrecover', component: <PassRecoverPage /> },
    { path: 'passrecoverconfirm/:uid/:token/', component: <PasswordRecoverConfirm /> },
    { path: '*', component: <LoginPage /> },
]