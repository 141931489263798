import React, { useEffect, useContext, useRef } from 'react'
import { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FortimanagersTab from './FortimanagersTab';
import HistoryTab from './HistoryTab';

function LicenseArea(props) {
    const [key, setKey] = useState('fortimanagers');
    return (
        <Col>
            <h3>Лицензирование</h3>
            <hr />
            <Tabs
                id="license-tabs"
                activeKey={key}
                onSelect={(key) => setKey(key)}
                className="mb-3"
            >
                <Tab eventKey="fortimanagers" title="Fortimanagers" >
                    <FortimanagersTab tabKey={key}
                        close={props.close} />
                </Tab>
                <Tab eventKey="history" title="История загрузки" >
                    <HistoryTab tabKey={key}
                        close={props.close} />
                </Tab>
            </Tabs>
        </Col>
    )
}

export default LicenseArea;