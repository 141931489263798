import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { organisationRating } from "../../utils/constants";
import DashboardService from "../../API/DashboardService";
import { AuthContext } from "../../context";
import { useNavigate } from "react-router-dom";
const url = process.env.REACT_APP_API_SERVER;
function DashboardContract(props) {
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const [page, setPage] = useState(null)

    const [contractStats, setContractStats] = useState([]);
    const navigate = useNavigate();

    const basicStyles = {
        'fontSize': '10px'
    }
    const linkStyles = {
        'color': 'blue',
        'textDecoration': 'underline',
        'cursor': "pointer"
    }

    useEffect(() => {
        (async () => {
          await DashboardService.getContracts(page).then((res)=> setContractStats(res)).catch(() => changeStatus());
       
        })()
    }, [page])

    const contractStatus = (status) => {
        if (status.includes('дн') && (Number(status.split(' ')[0]) <= 14)) return <span style={{ marginRight: 10, width: 50 }} className="badge bg-danger">{status}</span>;
        else if (status.includes('дн') && (Number(status.split(' ')[0]) > 14)) return <span style={{ marginRight: 10, width: 50 }} className="badge bg-warning">{status}</span>;
        else return <span style={{ marginRight: 10, width: 50 }} className="badge bg-success">{status}</span>
    }
    const paginationString = (count) => {
    
        let arr = [];
        let cof = Math.floor(count / 10);
        if (count % 10 === 0) cof = Math.floor(count / 10) -1;
        if (cof >= 5) cof =4
        for (let i = 0; i <= cof; i++) { arr.push(i) }
        return arr.map((item, index) => <li key={'cont' + item}className="page-item"><button className="page-link" onClick={() => handleClick(index)} >{index + 1}</button></li>)
    }
    const handleClick = (index) => {

        if (index === 0) setPage(null)


        else {
            if (url.includes('local')) setPage(`http://${url}/api/v1/contracts/?status=ACTIVE&ordering=contract_enddate&limit=10&page=${index + 1}`)
            else setPage(`https://${url}/api/v1/contracts/?status=ACTIVE&ordering=contract_enddate&limit=10&page=${index + 1}`)
        }
        
    }
    return (

        <>

            <Table className="mb-3" striped bordered hover>
                <thead style={basicStyles}>
                    <tr>
                        <th >Дата истечения</th>
                        <th >Организация</th>
                        <th >Номер договора</th>

                    </tr>
                </thead>
                <tbody style={basicStyles}>
                    {contractStats?.results?.map((item, index) =>
                        <tr key={'stats' + item.id} style={{height: '37px'}}>
                            <td >{contractStatus(item.time_left_verbose)}
                                {item.contract_enddate}
                            </td>
                            <td onClick={() => { item.enduser_org ? navigate(`/orglist/${item.organisation_info[1].id}`) : navigate(`/orglist/${item.organisation_info[0].id}`) }}>
                            {item.enduser_org?organisationRating(item.organisation_info[1].rating):organisationRating(item.organisation_info[0].rating)} {item.contract_partner_org}
                            </td>
                            <td style={linkStyles} onClick={() => navigate(`/contract/${item.id}`)}>
                                {item.contract_nr}
                            </td>

                        </tr>)}

                </tbody>

            </Table>
            <nav aria-label="pagination" >
  <ul className="pagination justify-content-center" id='pagination-menu'>
  {paginationString(contractStats.count)}
  </ul>
</nav>
        </>
    );
}
export default DashboardContract;