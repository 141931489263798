import axios from 'axios';
import Api from '.';

export default class ServiceService {


    //ОБЛАКА
    static async getCloudList( nextpage) {
        var url =  'api/v1/asset-clouds/' ;

        const method = 'GET';
        const params = {};
        // if (filters) {
        //     if (filters.paymentFilter) {
        //         params.payment_method = filters.paymentFilter;
        //     };
        //     if (filters.searchText) {
        //         params.search = filters.searchText;
        //     };

        // };

        if (nextpage) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newCloud(cloud) {

        const url = 'api/v1/asset-clouds/';
        const method = 'POST';
        const data = cloud;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    static async patchCloud(cloud, id) {
        const url = `api/v1/asset-clouds/${id}/`;
        const data = cloud;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.status;
    }

    static async deleteCloud(id) {
        const url = `api/v1/asset-clouds/${id}/`;
     
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.status;
    }


    //СЕРВИСЫ
    static async getServiceList( nextpage, all) {
        var url =  all===true ? 'api/v1/asset-services/?page=all' : `api/v1/asset-services/`;

        const method = 'GET';
        const params = {};

        if (nextpage) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };
    static async newService(service) {
        const url = 'api/v1/asset-services/';
        const method = 'POST';
        const data = service;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };
    
    static async patchService(service, id) {
        const url = `api/v1/asset-services/${id}/`;
        const data = service;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.status;
    }

    static async deleteService(id) {
        const url = `api/v1/asset-services/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.status;
    }
    //ПОДПИСКИ
    static async getSubList( nextpage, all) {
        var url = all===true ?  'api/v1/asset-subscriptions/?page=all' : 'api/v1/asset-subscriptions/'

        const method = 'GET';
        const params = {};
        // if (filters) {
        //     if (filters.paymentFilter) {
        //         params.payment_method = filters.paymentFilter;
        //     };
        //     if (filters.searchText) {
        //         params.search = filters.searchText;
        //     };

        // };

        if (nextpage) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
        }
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newSub(sub) {

        const url = 'api/v1/asset-subscriptions/';
        const method = 'POST';
        const data = sub;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };

    static async patchSub(sub, id) {
        const url = `api/v1/asset-subscriptions/${id}/`;
        const data = sub;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.status;
    }
    static async getAssetSub(id) {

        const url = `api/v1/asset-subscriptions/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    static async deleteSub(id) {
        const url = `api/v1/asset-subscriptions/${id}/`;
     
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.status;
    }
    static async downloadDocs(file) {
    
        const token = localStorage.getItem('tokenFP');
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            responseType: 'arraybuffer',
        };

        const response = await axios.get(file.sla_document, config);
        return response;
    }

    
}