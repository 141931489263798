import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import AssetService from "../../API/AssetService";

function EditSub(props) {
    const changeDate = (date) => {

        const newDate = date.replace(/\./g, '-').split('-');

        newDate.reverse();

        return newDate.join('-');
    }
    const [isPreloader, setIsPreloader] = useState(false);
    const [dateSub, setDateSub] = useState(changeDate(props?.currentSub?.date_start) || '');
    const [durationSub, setDurationSub] = useState(props?.currentSub?.duration || 12)
    const [errors, setErrors] = useState(null);
    const [subList, setSubList] = useState([]);
    const [currentSub, setCurrentSub] = useState(props?.currentSub || null);
    const [validSub, setValidSub] = useState(false);
  

    useEffect(() => {
        (async () => {
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            const response = await ServiceService.getSubList(false, true);
            setSubList(response.results);
            setIsPreloader(false)
        })();
    }, []);

    const patchSubscription = async event => {
        event.preventDefault()

        setIsPreloader(true);
        const item ={};
        item.date_start = dateSub;
        item.duration = durationSub;
        if ( currentSub.id !==props?.currentSub.id) item.subscription = currentSub.id;
        await AssetService.patchSubAsset(props.id,props.currentSub.id, item).then(()=> setTimeout(()=>{props.setEditingSub(false);setIsPreloader(false); }, 1500)).catch((err)=>{setErrors(err.message); setIsPreloader(false)})
    }

    return (
        <Form onSubmit={patchSubscription} className='was-validated' style={{position: 'relative'}}>
            {isPreloader && <Preloader/>}
            <hr />
            {errors !== null && <Alert key="danger" variant="danger">{errors}</Alert>}
            <Row >
                <h5 className="mb-3">Изменить подписку</h5>
                <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Дата начала подписки</Form.Label>
                            <Form.Control
                                value={dateSub}
                                onChange={(e) => setDateSub(e.target.value)}
                                size="sm" type="date"
                                required={true} />
                        </Form.Group>
                    </Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="catDiscountField" >
                        <Form.Label>Срок мес.</Form.Label>
                        <Form.Control required={true} size="sm" type="integer" value={durationSub} placeholder="Введите.." onChange={(e) => setDurationSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col>
                
                <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Label size="sm">Подписка</Form.Label>

                            <Dropdown style={!validSub ? { border: "1px solid red", height: 31, borderRadius: 6, paddingLeft: '.5rem' } : { border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                                <Dropdown.Toggle as={MyToggle} id="dropdown-assetSub" >
                                    Подписка: {currentSub?.description || "выбрать"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    as={MyMenu}
                                    type={'assetEditSub'}
                                    filterQa={true}
                                >
                                    {subList?.map(sub =>
                                        <Dropdown.Item eventKey={"sub" + sub.id} key={"sub" + sub.id} onClick={() => { setCurrentSub(sub); setValidSub(true) }}>{sub.description}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                        </Form.Group>
                    </Col>
                </Row>
            <Row>
              
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Изменить 
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setEditingSub(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr /> 
        </Form>
       
    );
}
export default EditSub;