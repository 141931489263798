import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useParams } from 'react-router-dom';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import AssetService from "../../API/AssetService";
import AssetChangeForm from "./AssetChangeForm";
import AddSub from "./AddSub";
import MyModal from "../UI/MyModal/MyModal";
import ImgEdit from '../../icons/edit.png';
import EditSub from "./EditSub";
import ImgRemove from '../../icons/remove.png';
import AddService from "./AddService";
import EditService from "./EditService";
import AddCloud from "./AddCloud";
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";

const AssetDetail = (props) => {
    const navigate = useNavigate();
    const { idAss } = useParams();
    const [assetDetails, setAssetDetails] = useState();
    const [isAssetChanging, setIsAssetChanging] = useState(false);
    const [addingSub, setAddingSub] = useState(false);
    const [editingSub, setEditingSub] = useState(false);
    const [currentSub, setCurrentSub] = useState({})
    const [succesDelete, setSuccessDelete] = useState(false);
    const [addingService, setAddingService] = useState(false);
    const [editingService, setEditingService] = useState(false);
    const [currentService, setCurrentService] = useState({});
    const [addingCloud, setAddingCloud] = useState(false);
    const [errors, setErrors] = useState([]);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);

    const dateStyles = {
        "fontSize": 'x-small',
        'borderBottom': '.5px solid black'

    }

    const expStatusStyle = {
        "color": "red"
    }
    useEffect(() => {

        (async () => {
            const response = await AssetService.getItem(idAss).catch(()=> changeStatus());
            setAssetDetails(response);

        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idAss, isAssetChanging, addingSub, succesDelete, addingService, editingService, editingSub, addingCloud]);

    // useEffect(() => {

    //     (async () => {
    //         const response = await ServiceService.getAssetSub(idAss);
    //         setSubList(response);

    //     })();

    // }, [addingSub]);
    const removeSub = async (subId) => {

        await AssetService.deleteSubAsset(idAss, subId).then(() => { setSuccessDelete(!succesDelete) }).catch((err) => setErrors(err.message))
    }

    const removeService = async (serviceId) => {

        await AssetService.deleteServiceAsset(idAss, serviceId).then(() => { setSuccessDelete(!succesDelete) }).catch((err) => setErrors(err.message))
    }
    const removeCloud = async (cloudId) => {

        await AssetService.deleteCloudAsset(idAss, cloudId).then(() => { setSuccessDelete(!succesDelete) }).catch((err) => setErrors(err.message))
    }

    return (
        <Col className="col-9 mb-5">
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            <Row>
                <Col sm={2} colSpan={1}>
                    <Form.Group className="mb-4">
                        <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => { navigate(-1) }}
                        >Вернуться</Button>

                    </Form.Group>
                </Col>
            </Row>

            {/*ДЕТАЛИЗАЦИЯ УСТРОЙСТВА */}
            {assetDetails &&
                <>
                    <Row>
                        <Col className="col-md-auto">
                            <b>ID</b> <span> {assetDetails.id} </span> <b>Дата патча: </b>{assetDetails.patched_date}
                            <br />
                            <b>Серийный номер</b>  <span> {assetDetails.serial_nr}</span>
                            <br />
                            <b>Страна: </b> {assetDetails.country}
                            <br />
                            <b>Город: </b> {assetDetails.city}
                            <br />
                            <b>Организация</b> {assetDetails.organisation}
                            <br />
                            <b>Решение</b> {assetDetails.solution?.name}
                            <br />
                            <b>Платформа</b> {assetDetails.platform_name} <b>Тип</b> {assetDetails.platform_type}
                            <br />
                            <b>Версия ПО</b> {assetDetails.sw_version}
                            <br />
                            <b>Описание</b> <p style={{ overflow: "hidden" }}>{assetDetails.description}</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-md-auto"  >
                            <Form.Group className="mb-4">
                                <Button variant="outline-primary" size="sm" onClick={() => setIsAssetChanging(!isAssetChanging)}>Изменить устройство</Button>

                            </Form.Group>
                        </Col>
                        <Col className="col-md-auto"  >
                            <Form.Group className="mb-4">
                                <Button variant="outline-primary" size="sm" onClick={() => setAddingSub(!addingSub)}>Добавить подписку</Button>

                            </Form.Group>
                        </Col>
                        <Col className="col-md-auto"  >
                            <Form.Group className="mb-4">
                                <Button disabled={assetDetails?.service.length > 0 ? true : false} variant="outline-primary" size="sm" onClick={() => setAddingService(!addingService)}>{'Добавить сервис'}</Button>

                            </Form.Group>
                        </Col>
                        <Col className="col-md-auto"  >
                            <Form.Group className="mb-4">
                                <Button variant="outline-primary" size="sm" onClick={() => setAddingCloud(!addingCloud)}>Добавить облако</Button>

                            </Form.Group>
                        </Col>
                    </Row>
                    {addingSub &&
                        <MyModal visible={addingSub} setVisible={setAddingSub}>
                            <AddSub setAddingSub={setAddingSub} id={assetDetails.id} />

                        </MyModal>}
                    {editingSub &&
                        <MyModal visible={editingSub} setVisible={setEditingSub}>
                            <EditSub setEditingSub={setEditingSub} id={assetDetails.id} currentSub={currentSub} />

                        </MyModal>}
                    {addingService &&
                        <MyModal visible={addingService} setVisible={setAddingService}>
                            <AddService setAddingService={setAddingService} id={assetDetails.id} />

                        </MyModal>}
                    {editingService &&
                        <MyModal visible={editingService} setVisible={setEditingService}>
                            <EditService setEditingService={setEditingService} id={assetDetails.id} currentService={currentService} />

                        </MyModal>}
                    {addingCloud &&
                        <MyModal visible={addingCloud} setVisible={setAddingCloud}>
                            <AddCloud setAddingCloud={setAddingCloud} id={assetDetails.id} />

                        </MyModal>}

                    {/*ПОДПИСКИ */}
                    {isAssetChanging && <Row>
                        <AssetChangeForm setIsAssetChanging={setIsAssetChanging} asset={assetDetails} />
                    </Row>}

                    <Row>
                        <Col className="col-2 mb-3 mt-5">
                            <b>Подписки</b>
                        </Col>
                    </Row>
                    <Row style={dateStyles}>
{/* 
                        <Col className="col-1">
                            <b>id</b>
                        </Col> */}
                        <Col className="col-1">
                            <b>Статус</b>
                        </Col>
                        <Col className="col-2">
                            <b>SLUG</b>
                        </Col>
                        <Col className="col-1">
                            <b>Осталось</b>
                        </Col>
                        <Col className="col-2">
                            <b>Дата окончания</b>
                        </Col>
                        <Col className="col-4">
                            <b>Описание</b>
                        </Col>
                        <Col className="col-1">
                            <b>Изменить</b>
                        </Col>
                        <Col className="col-1">
                            <b>Удалить</b>
                        </Col>
                    </Row >
                    {assetDetails.subscriptions?.map(item => (
                        <Row style={dateStyles} key={`sub` + item.id}>
                            {/* <Col className="col-1" style={{}}>{item.id}

                            </Col> */}
                            <Col style={item.status === "ACTIVE" ? { color: "black" } : { color: "red" }} className="col-1" >{item.status}

                            </Col>
                            <Col className="col-2">{item.slug}

                            </Col >
                            <Col className="col-1">{item.time_left_verbose}

                            </Col>
                            <Col className="col-2">{item.date_end}

                            </Col>
                            <Col className="col-4">{item.description}

                            </Col>

                            <Col>
                                <img width="20" className="interactive_hover" alt="change" src={ImgEdit} disabled onClick={
                                    () => {
                                        setCurrentSub(item);
                                        setEditingSub(true);
                                    }
                                } />
                            </Col>
                            <Col>
                                <img width="30" src={ImgRemove} alt="delete" onClick={
                                    () => removeSub(item.id)
                                }
                                />
                            </Col>
                        </Row>)
                    )}

                    {/*СЕРВИСЫ */}
                    <Row>
                        <Col className="col-2 mb-3 mt-5">
                            <b>Сервисы</b>
                        </Col>
                    </Row>
                    <Row style={dateStyles}>

                        {/* <Col className="col-1">
                            <b>id</b>
                        </Col> */}
                        <Col className="col-1">
                            <b>Статус</b>
                        </Col>

                        <Col className="col-2">
                            <b>SLUG</b>
                        </Col>
                        <Col className="col-1">
                            <b>Осталось</b>
                        </Col>
                        <Col className="col-2">
                            <b>Дата окончания</b>
                        </Col>
                        <Col className="col-3">
                            <b>Описание</b>
                        </Col>
                        <Col className="col-1">
                            <b>RMA</b>
                        </Col>
                        <Col className="col-1">
                            <b>Изменить</b>
                        </Col>
                        <Col className="col-1">
                            <b>Удалить</b>
                        </Col>

                    </Row >
                    {assetDetails.service?.map(item =>
                        <Row style={dateStyles} key={`service` + item.id}>
                            {/* <Col className="col-1">{item.id}

                            </Col> */}
                            <Col className="col-1" style={item.status !== 'ACTIVE' ? expStatusStyle : { color: "black" }}>{item.status}

                            </Col>

                            <Col className="col-2">{item.slug}

                            </Col >
                            <Col className="col-1">{item.time_left_verbose}

                            </Col>
                            <Col className="col-2">{item.date_end}

                            </Col>
                            <Col className="col-3">{item.description}

                            </Col>
                            <Col className="col-1" style={item.rma === true ? { color: "blue" } : { color: "black" }}>{item.rma === true ? "Да" : "Нет"}

                            </Col>
                            <Col>
                                <img width="20" className="interactive_hover" alt="change" src={ImgEdit} disabled onClick={
                                    () => {
                                        setCurrentService(item);
                                        setEditingService(true);
                                    }
                                } />
                            </Col>
                            <Col>
                                <img width="30" src={ImgRemove} alt="delete" onClick={
                                    () => removeService(item.id)
                                }
                                />
                            </Col>
                        </Row>

                    )}
                    {/*ОБЛАКА */}
                    <Row>
                        <Col className="col-2 mb-3 mt-5">
                            <b>Облака</b>
                        </Col>
                    </Row>
                    <Row style={dateStyles}>

                        {/* <Col className="col-1">
                            <b>id</b>
                        </Col> */}
                        <Col className="col-2">
                            <b>Адрес</b>
                        </Col>
                        <Col className="col-2">
                            <b>SLUG</b>
                        </Col>
                        <Col className="col-2">
                            <b>Тип обновления</b>
                        </Col>
                        <Col className="col-5">
                            <b>Описание</b>
                        </Col>
                        {/* <Col className="col-1">
                            <b>Изменить</b>
                        </Col> */}
                        <Col className="col-1">
                            <b>Удалить</b>
                        </Col>

                    </Row >
                    {assetDetails.clouds?.map(item =>
                        <Row style={dateStyles} key={`cloud` + item.id}>
                            {/* <Col className="col-1">{item.id}

                            </Col> */}
                            <Col className="col-2" >{item.address}

                            </Col>
                            <Col className="col-2">{item.slug}

                            </Col >
                            <Col className="col-2">{item.update_type}

                            </Col>
                            <Col className="col-5">{item.description}

                            </Col>
                            {/* <Col>
                                <img width="20" className="interactive_hover" alt="change" src={ImgEdit} disabled onClick={
                                    () => {
                                        setCurrentCloud(item);
                                        setEditingCloud(true);
                                    }
                                } />
                            </Col> */}
                            <Col>
                                <img width="30" src={ImgRemove} alt="delete" onClick={
                                    () => removeCloud(item.id)
                                }
                                />
                            </Col>
                        </Row>
                    )}
                </>
            }


        </Col >
    );
}

export default AssetDetail;