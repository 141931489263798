import React, {useEffect, useState, useContext} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import DashboardService from '../../API/DashboardService';
import { AuthContext } from "../../context";
function DashboardContractGraph() {
    const [contractStats, setContractStats] = useState([]);
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,Filler,
        Legend
      );
      const options = {
        responsive: true,
        interaction: {
          mode: 'index' ,
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: 'Контракты по месяцам',
          },
        },
        scales: {
          y: {
            type: 'linear' ,
            display: true,
            position: 'left' ,
          },
          y1: {
            type: 'linear' ,
            display: true,
            position: 'right' ,
            grid: {
              drawOnChartArea: false,
            }, 
               
          },
        },
      };
      useEffect(() => {
        (async () => {
         await DashboardService.getContractStatsGraph().then((res)=>  setContractStats(res) ).catch(() => changeStatus());
       
        })()
    }, [])

      const data = {
        labels: contractStats!== null ? contractStats.map((item, index)=>{return item.month}) : '',
        datasets: [
          {
           
            label: 'Количество контрактов',
            data: contractStats!==null && contractStats.map((item, index)=>{return item.count}),
            datalabels: {
                color: 'black',
                opacity: 0
                // formatter:function(value) {
                //     return value + ' %';
                //   }
              },
              type: 'bar',
              barPercentage: .2,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(60, 179, 113, 0.2)',
            yAxisID: 'y',
          },
          {fill: true,
            label: 'Сумма контрактов',
            data: contractStats!==null && contractStats.map((item, index)=>{return item.sale_sum}),
            datalabels: {
                color: 'black',
                opacity: 0
                // formatter:function(value) {
                //     return value + ' %';
                //   }
              },
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, .2)',
            yAxisID: 'y1',
          },
        ],
      };
    return (
        <div style={{width: '100%'}}>
<Line options={options} data={data} /></div>
    )
}
export default DashboardContractGraph;