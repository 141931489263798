import Api from '.';

export default class PlatformService {

    static async getList() {

        const url = 'api/v1/platforms/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async newItem(item) {

        const url = 'api/v1/platforms/';
        const method = 'POST';
        const data = item;

        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    }

}