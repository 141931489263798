/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from 'react-router-dom';
import Preloader from "../UI/Preloader/Preloader";
import ContractService from "../../API/ContractService";
import ContractsList from "./ContractList";
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggleAsset from "../UI/MyDropdown/MyToggleAsset";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Form from 'react-bootstrap/Form';
import { paymentMethod } from "../../utils/constants";
import QuotaDescription from "../Quotator/QuotaDescription";
import { AuthContext } from "../../context";

function ContractArea(props) {

    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isContractLoading, setIsContractLoading] = useState(false);

    const location = useLocation();
    const [isPreloader, setIsPreloader] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isAddingContract, setIsAddingContract] = useState(false);

    // перечень всех контрактов
    const [contractItems, setContractItems] = useState();
    const [totalContractItems, setTotalContractItems] = useState();
    const [nextPage, setNextPage] = useState(0);

    //Фильтры
    const [paymentFilter, setPaymentFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [statusFilter, setStatusFilter] = useState(false);
    const [text, setText]=  useState('')

    //квота деталь
    const [viewQuota, setViewQuota] = useState(false);
    const [currentQuota, setCurrentQuota] = useState(null);
    const [viewOrg, setViewOrg] = useState(false);

        //контекст
        const {setStatus } = useContext(AuthContext);
        const changeStatus = () => setStatus(true);

        const fetchData = async (add)=> {
            let filters = false;
            if (paymentFilter || searchText || statusFilter) {
                filters = {
                    paymentFilter: paymentFilter.type,
                    searchText: searchText,
                    statusFilter: statusFilter
                };
            }
            setIsPreloader(true)
            setIsContractLoading(true);
            const id = props.orgId ? props.orgId : false;
            // const id = location.pathname === '/orglist' ? props.orgId : false;
    
        await ContractService.getList(id, filters,add ? nextPage : 1, props.enduser)
            .then((res) => {
                if (add === 'add') {setNextPage(res.next ? nextPage + 1 : null);  res.results?.map(item => setContractItems(arr => [...arr, item])); }
                else { setContractItems(res.results); setNextPage(res.next ? 2 : null); }
                setTotalContractItems(res?.count);
                setIsContractLoading(false);
            }).catch(()=> changeStatus()).finally(() => setIsPreloader(false))
        }
    useEffect(() => {

        setIsPreloader(true)
        setIsContractLoading(true);
        const timerRefresh = setTimeout(fetchData, 800);
        return () => {

            clearTimeout(timerRefresh)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddingContract, paymentFilter, searchText, statusFilter]);
   

    useEffect(() => {

        (async () => {

            if (needToLoad) {
                await fetchData('add');
                setNeedToLoad(false);
            }
        })();
    }, [needToLoad]);

    useEffect(() => {

        if (isContractLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && contractItems?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);


    }, [isContractLoading]);

    const clearFilters = () => {
        setPaymentFilter(false);
        setStatusFilter(false)
        setSearchText("");
        setText('')
    }
    return (<>

        {viewOrg === false && <Col >
            {location.pathname !== "/orglist" && isAddingContract === false &&
                <div className="mb-3"><h3>Договора (отображено {totalContractItems} договоров)</h3></div>}
            {location.pathname !== "/orglist" && isAddingContract === true &&
                <div><h3>Создать договор</h3></div>}
            <Row>
                <hr />
            </Row>
            {viewQuota === true && <QuotaDescription id={currentQuota} setViewQuota={setViewQuota} />}
            <Row >
                <Col style={{ position: "relative" }} >

                    <Row className="justify-content-start">
                        <Col className="mb-3 col-3">
                            <Form.Label size="sm">Тип оплаты</Form.Label>
                            <Dropdown style={{ width: "100%" }}>
                                <Dropdown.Toggle as={MyToggleAsset} id="dropdown-payment" >
                                    {paymentFilter.title || "выбрать"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    as={MyMenu}
                                    setPaymentFilter={setPaymentFilter}
                                    type='payment'
                                >
                                    {paymentMethod?.map(method =>
                                        <Dropdown.Item eventKey={"mpay" + method.title} key={"mpay" + method.title} onClick={() => { setPaymentFilter(method) }}>{method.title}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                        <Col className="mb-3 col-3" >
                        <Form.Label size="sm">Статус</Form.Label>
                        <Dropdown >
                            <Dropdown.Toggle as={MyToggleAsset} id="dropdown-status" >
                                {statusFilter|| "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                filter={setStatusFilter}
                                type='statusF'
                            >
                                <Dropdown.Item eventKey={"sf" + 1} key={"sf" + 1} onClick={() => { setStatusFilter('ACTIVE') }}>{'ACTIVE'}</Dropdown.Item>
                                <Dropdown.Item eventKey={"sf" + 2} key={"sf" + 2} onClick={() => { setStatusFilter('EXPIRED') }}>{'EXPIRED'}</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                        <Col className="mb-3 col-3" >
                            <Form.Label size="sm">Поиск</Form.Label>
                            <Form.Control onChange={(e) => {setSearchText(e.target.value)}}
                                placeholder="Начните вводить..."
                                size="sm"
                                style={{ fontSize: '1rem', height: 38 }}
                                value={searchText}
                               
                            />
                        </Col>
                        {(paymentFilter || searchText || statusFilter) &&
                        <Col className="col-2">

                            <Button variant="outline-info" size="sm" style={{ width: "100%", height: '38px', marginTop: 29 }} onClick={() => clearFilters()}>Сбросить фильтры</Button>
                        </Col>}

                    </Row>
                    <ContractsList contractItems={contractItems} setViewQuota={setViewQuota} viewQuota={viewQuota} setCurrentQuota={setCurrentQuota} viewOrg={viewOrg} setViewOrg={setViewOrg} />
                    {isPreloader && <Preloader />}
                    <div ref={lastItem} style={{ height: 20 }} />
                </Col>
             
            </Row>

        </Col>}
    </>
    );
}
export default ContractArea;