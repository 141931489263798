import React, { useEffect, useContext, useRef } from 'react'
import { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import FortimanagersList from './FortimanagersList';
import Row from 'react-bootstrap/esm/Row';
import Alert from 'react-bootstrap/Alert';
import LicenseService from '../../API/LicenseService';
import { AuthContext } from "../../context";
import Preloader from '../UI/Preloader/Preloader';
import Button from 'react-bootstrap/esm/Button';
import FortimanagersAddForm from './FortimanagersAddForm';
import FortimanagerEditForm from './ForimanagerEditForm';
import MyModal from '../UI/MyModal/MyModal';
import FortimanagerDeleteForm from './FortimanagerDeleteForm';
import FortimanagerFileUploadForm from './FortimanagerFileUploadForm';

function FortimanagersTab(props) {

    const [currentItem, setCurrentItem] = useState(null);
    const [errors, setErrors] = useState([]);
    const [fortimanagers, setFortimanagers] = useState();
    const [isFortimanagerLoading, setIsFortimanagerLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [addingFortimanager, setAddingFortimanager] = useState(false);
    const [deletingFortimanager, setDeletingFortimanager] = useState(false);
    const [editingFortimanager, setEditingFortimanager] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [operationSuccess, setOperationSuccess] = useState(false)
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);



    const fetchFortimanagersData = async (add) => {
        setIsLoading(true);
        setIsFortimanagerLoading(true)

        await LicenseService.getFortimanagersList(add ? nextPage : 1).then((res) => {
            if (add === 'add') { setNextPage(res.next ? nextPage + 1 : null); res.results?.map(item => setFortimanagers(arr => [...arr, item])); }
            else { setFortimanagers(res.results); setNextPage(res.next ? 2 : null); }
            setTimeout(() => setIsFortimanagerLoading(false), 500)
        })
            .catch(() => changeStatus()).finally(() => setIsLoading(false));

    }


    useEffect(() => {
        if(props.tabKey === 'fortimanagers'){
                if (!addingFortimanager) {
                    setIsLoading(true)
                    setIsFortimanagerLoading(true)
                    const timerRefresh = setTimeout(() => {fetchFortimanagersData()}, 800);
                    return () => {
                        clearTimeout(timerRefresh)
                    }
                }
            }
    }, [addingFortimanager, editingFortimanager, deletingFortimanager, props.tabKey])


    useEffect(() => {

        (async () => {

            if (needToLoad && nextPage) {

                fetchFortimanagersData('add');
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);


    useEffect(() => {

        if (isFortimanagerLoading) return;
        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage && fortimanagers?.length > 0) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

    }, [isFortimanagerLoading]);

    return (
        <>

            <Col style={{ position: 'relative' }}>
                {isLoading && <Preloader leftCoord={true} />}
                {<h3 className="mb-5">Fortimanagers</h3>}
                <Row>
                    <Col className="mb-3 col-3">
                        <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                            onClick={() => { setAddingFortimanager(true) }}
                        >Добавить</Button>
                    </Col>
                </Row>  <hr />
                <Row> {errors.length > 0 &&
                    errors.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Row>{addingFortimanager && <FortimanagersAddForm setIsAddingFortimanager={setAddingFortimanager} />} </Row>
                {editingFortimanager &&
                    <MyModal visible={editingFortimanager} setVisible={setEditingFortimanager}>
                        <FortimanagerEditForm
                            fortimanager={currentItem}
                            setVisible={setEditingFortimanager}
                        />
                    </MyModal>}
                {deletingFortimanager &&
                    <MyModal visible={deletingFortimanager} setVisible={setDeletingFortimanager}>
                        <FortimanagerDeleteForm
                            fortimanager={currentItem}
                            setVisible={setDeletingFortimanager}
                        />
                    </MyModal>
                }
                {uploadingFile &&
                    <MyModal visible={uploadingFile} setVisible={setUploadingFile}>
                        <FortimanagerFileUploadForm
                            fortimanager={currentItem}
                            setVisible={setUploadingFile}
                        />
                    </MyModal>
                }
            </Col>


            <FortimanagersList
                fortimanagersItems={fortimanagers}
                setErrors={setErrors}
                setEditItem={setEditingFortimanager}
                setCurrentItem={setCurrentItem}
                removeItem={setDeletingFortimanager}
                close={props.close}
                setUploadingFile={setUploadingFile}
            />

            <div ref={lastItem} style={{ height: 20 }} />
        </>
    )
}

export default FortimanagersTab