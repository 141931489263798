import Api from '.';
import { limit } from '../utils/constants';
export default class OrgService {

    static async getList(filters, nextpage) {
        var url = `api/v1/organisation/`;
        const method = 'GET';
        const params = {};
        params.limit = limit
        if (filters) {
            if (filters.rateFilter) {
                params.rating = filters.rateFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
        };
        if (nextpage){
            params.page = nextpage
        }
        // if (nextpage) {
        //      url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(23); 
        // }
        const api = new Api({url: url, headers: {}, params: params});
        const response = await api.request(method);
        return response.data;
    }

    static async my() {

        const url = 'api/v1/organisation/me/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }
    static async getStats() {

        const url = 'api/v1/organisation/stats/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async getItem(id) {

        const url = `api/v1/organisation/${id}/`;
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async newOrg(vat, id, org) {

        const url = 'api/v1/organisation/';
        const method = 'POST';
        const data = org;

        const api = new Api({url: url});
        const response = await api.request(method, data);
 
        return response.data;
    }

    static async delete(id) {

        const url = `api/v1/organisation/${id}/`;
        const method = 'DELETE';

        const api = new Api({url: url});
        const response = await api.request(method);
        return response.data;
    }
    static async patchOrg(vat, id, org) {

        const url  = `api/v1/organisation/${id}/`;
        const data ={};
        if (vat!==false) data.vat = vat;
        if (org!==false) {
            data.name=org.name;
            data.vat=org.vat;
            data.website=org.website;
        }
    
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }

}