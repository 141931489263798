import Api from '.';

export default class DashboardService {

    static async getContracts(nextpage) {
        var url = 'api/v1/contracts/?status=ACTIVE&ordering=contract_enddate&limit=10'

        const method = 'GET';


        if (nextpage) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
         }
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    
    static async getSubs(nextpage, active) {
        
        var url = active === true ? 'api/v1/asset-subscription-stats/?status=ACTIVE&ordering=date_end&limit=10': 'api/v1/asset-subscription-stats/?status=EXPIRED&ordering=-date_end&limit=10'

        const method = 'GET';

        if (nextpage) {
             url = process.env.REACT_APP_API_SERVER.includes('localhost') ? nextpage : nextpage.slice(22);
        }
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    
    static async getServs(nextpage) {
        var url = 'api/v1/asset-service-stats/?limit=10&ordering=date_end'

        const method = 'GET';


        if (nextpage !==null) {
            url = process.env.REACT_APP_API_SERVER.includes('localhost')  ? nextpage : nextpage.slice(22);
         }
       
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async getQuotas() {
        var url = 'api/v1/quota-stats/'

        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    
    static async getContractStats() {
        var url = 'api/v1/contract-stats/'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    
    static async getOrgStats() {
        var url = 'api/v1/organisation/stats/'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
  
    static async getCloudsStats() {
        var url = 'api/v1/asset-cloud-dist/'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async getSubsStats() {
        var url = 'api/v1/asset-subscription-dist/'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async getServsStats() {
        var url = 'api/v1/asset-service-dist/'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async getContractStatsGraph() {
        var url = 'api/v1/contracts/stats/?limit=monthAmount'

        const method = 'GET';
        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
}