import React, { useState, useEffect, useMemo } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PriceList from "../Price/PriceList";
import CoreService from "../../core/CoreService";
import QuotaItemsList from "./QuotaItemsList";
import QuotaService from "../../API/QuotaService";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import Alert from 'react-bootstrap/Alert';
import Dropdown from 'react-bootstrap/Dropdown';
import MyToggle from "../UI/MyDropdown/MyToggle";
import MyMenu from "../UI/MyDropdown/MyMenu";
import Badge from 'react-bootstrap/Badge';
import MySelect from "../UI/MySelect";
import MyValidation from "../../core/MyValidation";
import Preloader from "../UI/Preloader/Preloader";
function AddQuotaForm(props) {

    registerLocale('ru', ru)
    const [priceitems, setPriceitems] = useState();
    const [curPlatform, setCurPlatform] = useState("");
    const [platformFilter, setPlatformFilter] = useState(false);
    const [curCategory, setCurCategory] = useState("");
    const [categoryFilter, setCategoryFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [quotaItems, setQuotaItems] = useState([]);
    const [quotaDescription, setQuotaDescription] = useState("");
    const [quotaExpiringDate, setQuotaExpiringDate] = useState(CoreService.addMonths(new Date(), 1));
    const [errorSavingQuota, setErrorSavingQuota] = useState(false);
    const [currencyRate, setCurrencyRate] = useState();
    const [partnerDefaultDiscount, setPartnerDefaultDiscount] = useState();
    const [enduserDefaultDiscount, setEnduserDefaultDiscount] = useState();
    const [isMain, setIsMain] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [discount, setDiscount] = useState();
    const [errors, setErrors] = useState([]);
    const [curPriceVersion, setCurPriceVersion] = useState({});
    // eslint-disable-next-line no-unused-vars
    const [isPreloader, setIsPreloader] = useState(false);
    const [nextPage, setNextPage] = useState(null);
    const [needToLoad, setNeedToLoad] = useState(false);

    const scrollWindow = () => {
        setTimeout(() => {
            let elemPrice = document.getElementById('scrollQuotaPrice');
            if (elemPrice) {

                if (elemPrice.getBoundingClientRect().bottom < 1000) {
                    setNeedToLoad(true);
                    window.removeEventListener('scroll', scrollWindow);

                }
            } else window.removeEventListener('scroll', scrollWindow)
        }, 500)
    }

    const removeItem = (item, index) => {
        setQuotaItems([...quotaItems.slice(0, index), ...quotaItems.slice(index + 1)])
    };

    const addItem = (item) => {
        var cloned = JSON.parse(JSON.stringify(item));
        cloned.amount = 1;
        setQuotaItems(arr => [...arr, cloned]);
    };

    const changeItemQty = (item, amount, index) => {

        let itemIndex = quotaItems.indexOf(item);
        let newArr = [...quotaItems];
        newArr[itemIndex].amount = parseInt(amount);
        setQuotaItems(newArr);

    };
    const changeDiscount = (item, discount) => {
        let itemIndex = quotaItems.indexOf(item);
        if (itemIndex >= 0) {
            let newArr = [...quotaItems];
            newArr[itemIndex].discount = (discount);
            setQuotaItems(newArr);
        }

    }
    const quotaSum = useMemo(() => CoreService.quotaSum(quotaItems), [quotaItems]);
    useEffect(() => {

        (async () => {

            const config = await PriceService.getPriceConfig(1);
            setCurrencyRate(config.currency_rate);
            setPartnerDefaultDiscount(config.partner_discount);
            setEnduserDefaultDiscount(config.enduser_discount);
            setCurPriceVersion(
                {
                    dateCreated: config.active_priceversion_date,
                    decription: config.active_priceversion_comment
                }
            );
        })();

    }, []);

    useEffect(() => {
        (async () => {
         
            let filters = false;
            if (platformFilter || categoryFilter || searchText) {
                filters = {
                    platformFilter: platformFilter,
                    categoryFilter: categoryFilter,
                    searchText: searchText,
                };
            };
            const pricelist = await PriceService.getList(filters, false);
            setNextPage(pricelist.next)
            setPriceitems(pricelist.results);
        })();

    }, [platformFilter, categoryFilter, searchText]);

    useEffect(() => {
        if (props.partnerValue !== "") {
            setDiscount(partnerDefaultDiscount);
        } else {
            setDiscount(enduserDefaultDiscount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.partnerValue]);

    const saveQuota = async event => {
        event.preventDefault();
        const quota = {};
        setIsPreloader(true)
        quota.items = quotaItems;
        quota.enduser_org = props.enduserValue;
        quota.enduser_org = props.enduserValue;
        quota.is_main = isMain;
        if (props.partnerValue !== "") {
            quota.partner_org = props.partnerValue;
        };
        quota.description = quotaDescription;
        if (quotaDescription === "") {
            const description = CoreService.makeDecription(quotaItems).join(" ");
            quota.description = "Платформы: " + description;
        };
        quota.date_expires = quotaExpiringDate ? quotaExpiringDate.toISOString().split('T')[0] : null;
        quota.currency_rate = parseInt(currencyRate);
        // quota.discount = (discount || 0);
            await QuotaService.newQuota(quota).then(() => {props.setIsAddingQuota(false); props.clearFilters()}).catch((err) => { setErrors(err.message);}).finally(()=> {setIsPreloader(false)});

        
    }
    useEffect(() => {
        if (needToLoad === false)
            window.addEventListener('scroll', scrollWindow);
        else window.removeEventListener('scroll', scrollWindow);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    useEffect(() => {
        (async () => {
            if (needToLoad && nextPage !== null) {
                setIsPreloader(true);
                let filters = false;
                if (platformFilter || categoryFilter || searchText) {
                    filters = {
                        platformFilter: platformFilter,
                        categoryFilter: categoryFilter,
                        searchText: searchText,
                    };
                };
                // setIsOrgListLoading(true);
                const pricelist = await PriceService.getList(filters, nextPage, false).finally(() => setIsPreloader(false));
                await pricelist.results?.map(item => setPriceitems(arr => [...arr, item]));
                await setNextPage(pricelist.next);
                // setIsOrgListLoading(false);
                setNeedToLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToLoad]);

    return (
        <Form onSubmit={saveQuota}>

            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
{isPreloader && <Preloader/>}
            <Row className="mt-5">

                <Col className="col-2">

                    <Button variant="outline-danger" style={{ width: "100%", height: '38px' }} size="sm" onClick={() => {
                        props.setIsAddingQuota(false);
                        props.clearFilters();
                        setCurPlatform("");
                        setPlatformFilter(false);
                        setCurCategory("");
                        setCategoryFilter(false);
                    }}>
                        Отменить
                    </Button>

                </Col>
                <Col className="col-2">

                    <Button variant="outline-primary" style={{ width: "100%", height: '38px' }} size="sm" type="submit">
                        Сохранить квоту
                    </Button><br />

                </Col>
            </Row>
            {errorSavingQuota &&
                errorSavingQuota.map(error =>
                    <Row>
                        <Col>
                            <Alert key="danger" variant="danger">
                                {error}
                            </Alert>
                        </Col>
                    </Row>
                )
            }
            <Row className="mt-4">
                {/* <Col className="mb-3 col-2">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Main</Form.Label>
                        <Form.Select size="sm" id="isMain" onChange={(e) => setIsMain(e.target.value)} >
                        <option value={false}>Нет</option>
                            <option value={true}>Да</option>
                        </Form.Select>
                    </Form.Group>
                </Col> */}
                {/* <Col className="mb-3 col-2 mt-4">
                    <div className="form-check" style={{ alignItems: "center", display: "flex" }}>
                        <input onChange={(evt) => setIsMain(evt.target.checked)} className="form-check-input" type="checkbox" value="" id="isMain" style={{ height: '25px', width: '25px' }}></input>
                        <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '18px', fontWeight: 600, marginLeft: '10px' }}>
                            Main
                        </label>
                    </div></Col> */}
            </Row>
            <Row>
            <Col className="mb-4 col-2 ">
                    <div className="form-check" style={{ alignItems: "center", display: "flex" }}>
                        <input onChange={(evt) => setIsMain(evt.target.checked)} className="form-check-input" type="checkbox" value="" id="isMain" style={{ height: '25px', width: '25px' }}></input>
                        <label className="form-check-label" htmlFor="isMain" style={{ fontSize: '14px', fontWeight: 600, marginLeft: '10px' }}>
                            Main
                        </label>
                    </div></Col>
                <Col className="mb-4 col-3 ">
                    <Form.Group className="mb-4">
                        <Dropdown>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-orgs">
                                Заказчик: {props.enduserValue || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                orgAdded={props.orgAdded}
                                type='orgEnd'
                                as={MyMenu}
                                setIsOrgAdding={props.setIsOrgAdding}
                                isOrgAdding={props.isOrgAdding}
                                setOrgAddingType={props.setOrgAddingType}
                                orgType="ENDUSER"
                                hideOrgMenu={props.hideOrgMenu}
                                setHideOrgMenu={props.setHideOrgMenu}
                                filterQa={props.setEndUserFilter}
                            >
                                {props.organizations?.map(org =>
                                    <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { props.setCurEndUserFilter(org.name); props.setEndUserFilter(org.name) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                </Col>
                <Col className="mb-4 col-3 ">
                    <Form.Group className="mb-4">
                        <Dropdown>
                            <Dropdown.Toggle as={MyToggle} id="dropdown-partners">
                                Партнер: {props.partnerValue || "выбрать"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                                as={MyMenu}
                                setIsOrgAdding={props.setIsOrgAdding}
                                isOrgAdding={props.isOrgAdding}
                                setOrgAddingType={props.setOrgAddingType}
                                orgType="PARTNER"
                                hideOrgMenu={props.hideOrgMenu}
                                setHideOrgMenu={props.setHideOrgMenu}
                                filterQa={props.setPartnerFilter}
                            >
                                {props.partnerorganizations?.map(org =>
                                    <Dropdown.Item eventKey={"p" + org.id} key={"p" + org.id} onClick={() => { props.setCurPartnerFilter(org.name); props.setPartnerFilter(org.name) }}>{org.name}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                </Col>
                {
                    props.partnerValue !== "" &&
                    <Col sm={1}>
                        <Button variant="outline-info" size="sm" onClick={() => props.setCurPartnerFilter("")}>Сбросить</Button>
                    </Col>
                }
                <Col className="mb-4 col-1 ">
                    <Form.Group className="mb-4">
                        <Form.Label>Курс</Form.Label>
                        <Form.Control size="sm" type="text" value={currencyRate || ""} onChange={(e) => setCurrencyRate(e.target.value)} />
                    </Form.Group>
                </Col>
             
                    {/* <Form.Group className="mb-4">
                        <Form.Label>Скидка</Form.Label>
                            <Form.Control size="sm" type="text" value={discount} onChange={(e) => setDiscount(e.target.value)} />
                    </Form.Group> */}
         
                <Col sm={3}>
                    <div style={{ fontSize: 16, marginBottom: 10 }}><Form.Label>Прайс</Form.Label>: <Badge bg="info">{curPriceVersion.decription}<br /> загружен {curPriceVersion.dateCreated}</Badge></div>
                </Col>

            </Row>
            {/* <Row>
                
            </Row> */}
            <Row>
                <Col>
                    {quotaItems
                        && <QuotaItemsList
                            quotaItems={quotaItems}
                            quotaSum={quotaSum}
                            // discount={discount}
                            currencyRate={currencyRate}
                            removeItem={removeItem}
                            changeItemQty={changeItemQty}
                            changeDiscount={changeDiscount}
                        />
                    }
                </Col>
            </Row>
            {quotaItems.length > 0
                &&
                <Row>
                    <Col sm={4}>
                        <Form.Group className="mb-4">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                type="text"
                                placeholder="Описание квоты..."
                                value={quotaDescription}
                                size="sm"
                                onChange={(e) => setQuotaDescription(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={3}>
                        Срок действия квоты<br />
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            selected={quotaExpiringDate}
                            onChange={(date) => setQuotaExpiringDate(date)}
                            locale="ru"
                        />
                    </Col>
                    <hr />
                </Row>
            }
            <Row>
                <Col sm={2}>
                    {/* <InputGroup className="mb-3" size="sm"> */}
                    <Form.Control onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Начните вводить..."
                        size="sm"
                        value={searchText}
                    />
                    {/* </InputGroup> */}
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-4">
                        <MySelect
                            platforms={props.platforms}
                            setPlatformFilter={setPlatformFilter}
                            setCurPlatform={setCurPlatform}
                            value={curPlatform}
                        />
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    <Form.Group className="mb-4">
                        <MySelect
                            categories={props.categories}
                            setCategoryFilter={setCategoryFilter}
                            setCurCategory={setCurCategory}
                            value={curCategory}
                        />
                    </Form.Group>
                </Col>
                <Col sm={2}>
                    {
                        (platformFilter || categoryFilter || searchText) &&
                        <Button
                            // key="empyfilters"
                            variant="outline-info"
                            size="sm"
                            onClick={() => {
                                setCurPlatform("");
                                setPlatformFilter(false);
                                setCurCategory("");
                                setCategoryFilter(false);
                                setSearchText("");
                            }}>
                            Сбросить фильтр
                        </Button>
                    }
                </Col>
            </Row>

            <Row>
                <Col>
                    <PriceList
                        priceitems={priceitems}
                        isQuotaAdding={true}
                        addItem={addItem}
                    />
                </Col>
                <div id="scrollQuotaPrice" style={{ height: 20 }} />
            </Row>

        </Form>
    );
}
export default AddQuotaForm;