import axios from 'axios';
import { AuthContext } from '../context';
import MyException from '../core/MyException';
// require('dotenv').config();

class Api {
   static contextType = AuthContext;
    constructor ({url, headers={}, params={}, responseType={}, contentType={ 'content-type': 'application/json' }}) {

        var server = `https://${process.env.REACT_APP_API_SERVER}/`;
        if (['localhost', 'localhost:8000'].includes(process.env.REACT_APP_API_SERVER)) {
            server = `http://${process.env.REACT_APP_API_SERVER}/`;
        }
        
        if (url.includes(server)) {
            this._url = url;
        } else {
            this._url = server + url;
        }
        this._headers = headers;
        this._params = params;
        if (responseType) {
            this._responseType = responseType;
        }
        if (contentType) {
            this._contentType = contentType;
        }
 
    }
 
   
    catchError(e) {
    
         var message = [];
         
          if (e.response.status === 401) localStorage.setItem('tokenReviewFP', 'true');

        if (e.message !== "Network Error") {
            if (e.response.status !== 500) {
              
                if ((typeof e.response.data) === 'object') {
                    for (const [, value] of Object.entries(e.response.data)) {
                 
                        if ((typeof value) === 'object') {
                            for (const [, value2] of Object.entries(value)) {
                              
                                if ((typeof value2) === 'object') {
                                    for (const [, value3] of Object.entries(value2)) {
                                     
                                        message.push(`${value3}`);
                                    }}
                                    else {
                                        message.push(`${value2}`);
                                    }
                                
                            
                       } } else {
                            message.push(`${value}`);
                        };
                    }
                } else { 
                    message = e.response.data;
               
                };
    
            } else {
                message = ["Ошибка сервера"];
            };
        } else {
            message = ["Ошибка соединенния с сервером"];
        };
        throw new MyException(message);
    }
  
    async request(method, data) {

        try {
            const token = localStorage.getItem('tokenFP');
            if (token) {
                this._headers = {
                    ...this._headers,
                    'Authorization': `Bearer ${token}`
                  }
            }
            const response = await axios({
                method: method,
                url: this._url,
                data: data,
                headers: this._headers,
                params: this._params,
                responseType: this._responseType
            });
            return response;
        } catch(error) {
           
            this.catchError(error);
        };
    }
  
    
}
export default Api;
  