import React from "react";
import ImgEdit from '../../icons/edit.png';
import ImgRemove from '../../icons/remove.png';
import PriceService from "../../API/PriceService";
import Button from 'react-bootstrap/Button';

const PriceItem = (props) => {

    return (
        <tr>
            <td>{props.item.platform_type}</td>
            <td>{props.item.platform_name}</td>
            <td>{props.item.category}</td>
            <td>{props.item.part_number}</td>
            <td>{props.item.name}</td>
            <td>{props.item.price}</td>
            {
                !props.isQuotaAdding 
                &&
                    <td>
                    <img width="20" src = {ImgEdit} alt={'edit'} onClick={
                        () => {
                            props.setModal(true);
                            props.setIsItemEditing(true);
                            props.setItemToEdit(props.item);
                            props.setCurrentDescription(props.item.name);
                            props.setCurrentPrice(props.item.price);
                        }
                    } /> 
                    </td>
            }
            {
                !props.isQuotaAdding 
                ?
                    <td>
                        <img width="30" src = {ImgRemove} alt={'delete'} onClick={
                            async () => 
                            {
                                props.setModal(true);
                                props.setCurrentPriceItem(props.item);
                                props.setDeletePriceItem(true)
                            }
                        } />
                    </td>
                :
                <td>
                    <Button size="sm" variant="outline-dark" onClick={() => {props.addItem(props.item)}}>
                        Добавить
                    </Button>
                </td>    
                
            }
            
        </tr>
    );
}

export default PriceItem;