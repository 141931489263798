import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import LicenseService from "../../API/LicenseService";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";

function FortimanagerDeleteForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [errors, setErrors] = useState([]);


    const deleteSolution = async () => {
        setIsPreloader(true)
        await LicenseService.deleteFortimanager(props?.fortimanager?.id)
            .then(() => { setTimeout(() => { props.setVisible(false); setErrors(null); setIsPreloader(false) }, 500) })
            .catch((e) => {setErrors(e.message); setIsPreloader(false) });
    }
    const height = errors.length === 0? 200 : 350 
    return (
        <div style={{ position: 'relative', width: 400, height: height , textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
            {isPreloader && <Preloader />}
            <Row>
                <h1 className="mb-3">Удалить "{props.fortimanager?.name}"?</h1>
            </Row>
            <Row> {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }</Row>
            <div style={{display:'flex', flexDirection:'column', gap: '2rem'}}>
            <Row>
            <Col sm={6}>
                    <Button variant="outline-danger" size="sm" onClick={() => props.setVisible(false)} style={{ width: '100%', height: 50 }}>
                        Нет
                    </Button>
                </Col>
                <Col sm={6}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%', height: 50 }} onClick={(e) => { e.preventDefault(); deleteSolution() }}>
                        Да
                    </Button>

                </Col>
            </Row>
            </div>
        </div>

    );
}
export default FortimanagerDeleteForm;