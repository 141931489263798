import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Preloader from "../UI/Preloader/Preloader";
import ServiceService from "../../API/ServiceService";

function AddSubForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);

    const [slugSub, setSlugSub] = useState('');
    const [commentSub, setCommentSub] = useState('');
    const [descriptionSub, setDescriptionSub] = useState('');
    const [errors, setErrors] = useState(null);

    const addNewSub = async event => {
        event.preventDefault()
        setIsPreloader(true);
        const item = {};
        item.slug = slugSub;
        if (commentSub !== '') item.comment = commentSub;
        item.description = descriptionSub;
        await ServiceService.newSub(item).then(() => props.setAddSub(!props.addSub)).catch((err) => { setIsPreloader(false); setErrors(err.message) })
    }

    return (
        <Form onSubmit={addNewSub} className='was-validated' style={{ position: 'relative' }}>
            {isPreloader && <Preloader />}
            <hr />
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row >
                <h3 className="mb-3">Добавить подписку</h3>
                <Col className="mb-3 col-4">

                    <Form.Group controlId="SubSlug" >
                        <Form.Label>SLUG</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={slugSub}
                            placeholder="Введите.." onChange={(e) => setSlugSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
                <Col className="mb-3 col-4">
                    <Form.Group controlId="SubAddr" >
                        <Form.Label>Комментарий (опционально ) </Form.Label>
                        <Form.Control size="sm" type="text" value={commentSub} placeholder="Введите.." onChange={(e) => setCommentSub(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group>
                </Col></Row>
            <Row>

                <Col className="mb-3 col-4">
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            required={true}
                            type="text"
                            placeholder="Описание "
                            value={descriptionSub}
                            size="sm"
                            onChange={(e) => setDescriptionSub(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Button variant="outline-primary" size="sm" type="submit " style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={2}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddSub(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default AddSubForm;