import React, { useContext } from "react";
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { UserContext } from "../../context";


const SideBar = function () {

    const { userinfo } = useContext(UserContext);

    return (
        <Col sm={2}>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link disabled className="nav-link" to="/dashboard">Главная</Link>
                </li>

                {
                    userinfo['organizationType'] === "VENDOR" &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/price">Прайс-лист</Link>
                    </li>
                }
                {
                    userinfo['organizationType'] === "VENDOR" &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/quotator">Квотатор</Link>
                    </li>
                }
                {
                    userinfo['organizationType'] === "VENDOR" &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/orglist">Организации</Link>
                    </li>
                }
                {userinfo['organizationType'] === "VENDOR" &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/contract">Договора</Link>
                    </li>}
                {userinfo['organizationType'] === "VENDOR" &&
                    <li className="nav-item">
                        <Link className="nav-link" to="/asset">Устройства</Link>
                    </li>}
                {userinfo['organizationType'] === "VENDOR" && <li className="nav-item">
                    <Link className="nav-link" to="/service">Сервисы</Link>
                </li>}
                {userinfo['organizationType'] === "VENDOR" && <li className="nav-item">
                    <Link className="nav-link" to="/warehouse">Склад</Link>
                </li>}
                {userinfo['organizationType'] === "VENDOR" && <li className="nav-item">
                    <Link className="nav-link" to="/license">Лицензирование</Link>
                </li>}
                {/* {userinfo['organizationType'] === "VENDOR" && <li className="nav-item">
                    <Link className="nav-link" to="/status_files">Статус файлы обновлений</Link>
                </li>} */}

            </ul>
        </Col>
    );
}
export default SideBar;