import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import LicenseService from "../../API/LicenseService";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Preloader from "../UI/Preloader/Preloader";

function FortimanagerFileUploadForm(props) {
    const [isPreloader, setIsPreloader] = useState(false)
    const [file, setFile] = useState(null);
    const [fileInfo, setFileInfo] = useState({});
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    useEffect(() => {
        if (!file) {
            setFileInfo({})
        }
    }, [file])


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            if (selectedFile.size > 1024 * 1024) {
                setErrors(['Файл превышает размер 1 МБ.']);
                setFile(null);
                setFileInfo({});
            }
            else {
                setFile(selectedFile);
                setFileInfo({
                    name: selectedFile.name,
                    size: selectedFile.size,
                    type: selectedFile.type,
                });
                setMessage('');
                setErrors([]);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            setErrors(['Пожалуйста, выберите файл.']);
            return;
        }

        const formData = new FormData();
        formData.append('lic_file', file);

        setIsPreloader(true);
        await LicenseService.addLicense(props.fortimanager, formData).then((res) => {
            setMessage('Файл успешно загружен');
            setFile(null);
            setFileInfo({});
            setIsPreloader(false);
            setIsFormSubmitted(true)
        }).catch((err) => {
            setErrors([err.message || 'Произошла ошибка при загрузке файла.']);
            setIsPreloader(false)
        })


    };
    const height = errors.length === 0 ? 300 : 450 
    return (
        <div style={{ position: 'relative', width: 400, height: height, display:'flex', flexDirection:'column', justifyContent:'center'}}>
            {isPreloader && <Preloader />}
            {!isFormSubmitted && <>

                <Row>
                    <h1 className="mb-3">Загрузить файл</h1>
                </Row>
                <Row> {errors?.length > 0 &&
                    errors?.map(error =>
                        <Alert key="danger" variant="danger">
                            {error}
                        </Alert>
                    )
                }</Row>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control
                            size="sm"
                            required={true}
                            type="file"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                    <hr />
                        <div>
                            <p><strong>Имя файла:</strong> {fileInfo? fileInfo.name : ''}</p>
                            <p><strong>Размер файла:</strong> {fileInfo? fileInfo.size : '0'} байт</p>
                            <p><strong>Тип файла:</strong> {fileInfo? fileInfo.type : ''}</p>
                        </div>
                    {file ? (
                        <Row>
                            <Col sm={6}>
                                <Button variant="outline-danger" size="sm" type="button" style={{ width: '100%' }} onClick={() => props.setVisible(false)}>
                                    Отменить
                                </Button></Col>
                            <Col sm={6}>
                                <Button variant="outline-primary" type="submit" size="sm" style={{ width: '100%' }}>
                                    Загрузить
                                </Button></Col>
                        </Row>
                    ) : (<Row>
                        <Col sm={12}>
                            <Button variant="outline-danger" size="sm" type="button" style={{ width: '100%' }} onClick={() => props.setVisible(false)}>
                                Отменить
                            </Button></Col></Row>)}
                </Form> </>}
            {isFormSubmitted && message &&
                <div style={{textAlign:'center', display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
                    <h2>{message}</h2>
                    <Row>
                        <Col sm={12}>
                            <Button variant="outline-primary" size="sm" type="button" style={{ width: '100%', height: 50 }} onClick={() => props.setVisible(false)}>
                                Закрыть
                            </Button></Col></Row>
                </div>
            }
        </div>

    );
}
export default FortimanagerFileUploadForm;
