import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PriceService from "../../API/PriceService";
import Alert from 'react-bootstrap/Alert';

function CategoryEditForm(props) {
   
    const [categoryName, setCategoryName] = useState(props.category.name);
    const [categoryDescription, setCategoryDescription] = useState(props.category.description || "");
    const [categoryDiscount, setCategoryDiscount] = useState(props.category.discount);
    const [errors, setErrors] = useState([]);

    const editCategory = async event => {
        event.preventDefault();
        props.setCategoryEdited(false)
        const category={};
        category.name  = categoryName;
        category.discount = categoryDiscount;
        category.description = categoryDescription;

await PriceService.patchCategory(category, props.category.id)
.then(() => { props.setCategoryEdited(true); props.setCategoryEdited(true); props.setVisible(false); setErrors(null)})
.catch((e) => setErrors(e.message));
    }

    return (
        <Form
            onSubmit={editCategory}
        >
            {errors.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Form.Group className="mb-4">
                <Form.Label>Название</Form.Label>
                <Form.Control type="text" required={true} value={categoryName}onChange={(e) => setCategoryName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Скидка</Form.Label>
                <Form.Control type="text" value={categoryDiscount} onChange={(e) => setCategoryDiscount(e.target.value)}/>
            </Form.Group>
            <Form.Group className="mb-4">
                <Form.Label>Описание</Form.Label>
                <Form.Control type="text" value={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)}/>
            </Form.Group>

            <Button variant="primary" type="submit">
                Сохранить
            </Button>
        </Form>
    );
}
export default CategoryEditForm;