import Api from '.';
import axios from 'axios';
import { limit } from '../utils/constants';
export default class PriceService {

    static async getList(filters, nextpage) {

        var url = `api/v1/priceitems/`;
        const method = 'GET';
        const params = {};
        params.limit = limit
        if (filters) {
            if (filters.platformTypeFilter) {
                params.platform__type = filters.platformTypeFilter;
            };
            if (filters.platformFilter) {
                params.platform__name = filters.platformFilter;
            };
            if (filters.categoryFilter) {
                params.category__name = filters.categoryFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
        };
        if (nextpage) {
            // url = process.env.REACT_APP_API_SERVER === 'localhost:8000' ? nextpage : nextpage.slice(22); 
            params.page = nextpage
        }

        const api = new Api({ url: url, headers: {}, params: params });
        const response = await api.request(method);
        return response.data;
    }

    static async getCategoryList() {
        const url = 'api/v1/categories/';
        const method = 'GET';
        
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
        return response.data;

    };

    static async patchCategory(category, id) {
        const url  = `api/v1/categories/${id}/`;
        const data = category;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.status;
    }

    static async getPlatformTypesList() {
        const url = 'api/v1/platform-types/';
        const method = 'GET';

        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
        return response.data;

    };

    static async newItem(item) {

        const url = 'api/v1/priceitems/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async updateItem(item) {

        const url = `api/v1/priceitems/${item.id}/`;
        const method = 'PATCH';
        const data = item;
        data.price = item.price.replace(/,/g, '.');
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async deleteItem(id) {

        const url = `api/v1/priceitems/${id}/`;
        const method = 'DELETE';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async uploadFilePrice(file) {

        const url = 'api/v1/priceitems/upload/';
        const method = 'POST';

        var formData = new FormData();
        formData.append("file", file);
        const contentType = { "Content-Type": "multipart/form-data" };

        const api = new Api({ url: url, contentType: contentType });
        const response = await api.request(method, formData);
        return response.data;
    }

    // static async downloadFilePrice() {

    //     const url = 'api/v1/priceitems/download/';
    //     const method = 'GET';
    //     const responseType = {responseType: 'arraybuffer'};

    //     const api = new Api({url: url, responseType: responseType});
    //     const response = await api.request(method);
    //     return response;
    // }

    static async downloadFilePrice() {
        try {
            const token = localStorage.getItem('tokenFP');
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'arraybuffer',
            };
            var server = `https://${process.env.REACT_APP_API_SERVER}`;
            if (process.env.REACT_APP_API_SERVER.includes('localhost') ) {
                server = `http://${process.env.REACT_APP_API_SERVER}`;
            }
            const response = await axios.get(`${server}/api/v1/priceitems/download/`, config);
            return response;
        } catch (e) {
            Api.catchError(e);
   
        }
    }

    static async getVersionList() {
        const url = 'api/v1/priceversions/';
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;

    };

    static async switchVersion(id, description) {
        const url = `api/v1/priceversions/${id}/`;
        const method = 'PATCH';
        var data = { "is_active": true };
        if (description !== "") {
            data = {
                ...data,
                'comment': description
            };
        }

        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async deleteVersion(id) {
        const url = `api/v1/priceversions/${id}/`;
        const method = 'DELETE';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async getPriceConfig() {
        const url = 'api/v1/priceconfig/';
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    };
    static async patchPriceConfig(id, currentRate) {
        const url = `api/v1/priceconfig/${id}/`;
        const method = 'PATCH';
        const data = {};
        data.currency_rate = currentRate;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response;
    };

    static async newCat(item) {

        const url = 'api/v1/categories/';
        const method = 'POST';
        const data = item;

        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

}