import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

function DashboardPlatform(props) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );
    let summAssets = 0;
    const options = {
        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    font: {
                        size: 8,
                    }
                }
            }
        },
        elements: {

            bar: {
                borderWidth: 1,
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: false,
                text: `Устройства по платформам ${props.platType}  ${summAssets}`,
            },
            tooltips: {
                enabled: false

            }
        },
    };

    const labels = props.assetsStats !== undefined ? props?.assetsStats.map((item, index) => { summAssets += item.count; return item.platform__name }) : ''

    const data = props.assetsStats !== undefined ? {
        labels,
        datasets: [
            {
                label: `Устройств по типу платформы ${props.platType}:  ${summAssets} шт.`,
                data: labels.map((item, index) => props?.assetsStats[index].count),
                borderColor: 'rgb(60, 179, 113, 0.8)',
                backgroundColor: 'rgba(60, 179, 113, 0.5)',
                barPercentage: .2,
                maxBarThickness: 8,
                datalabels: {
                    opacity: 0
                },
            }
        ],
    } : {}

    return (

        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

            <div style={{ minHeight: '500px' }}>
                {props.assetsStats !== undefined && <Bar options={options} data={data} />}
            </div>
        </div>
    );
}
export default DashboardPlatform;