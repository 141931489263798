import Api from '.';

export default class SolutionService {

    static async getList() {

        const url = 'api/v1/asset-solutions/dropdown/';
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }

    static async newItem(item) {

        const url = 'api/v1/asset-solutions/';
        const method = 'POST';
        const data = item;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async deleteSolution(id){
        const url = `api/v1/asset-solutions/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);

        return response.status;
    }

    static async patchSolution(solution, id) {
        const url = `api/v1/asset-solutions/${id}/`;
        const data = solution;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);

        return response.status;
    }
}