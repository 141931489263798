import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import WarehouseService from "../../API/WarehouseService";
import Preloader from "../UI/Preloader/Preloader";

function WarehouseCopyForm(props) {
    const refactDate = (date) => {
        if (date !== null) {
            const arr = date.split('.').reverse();
            return arr.join('-')
        }
        else return null
    }
    // const [patchDate, setPatchDate] = useState(CoreService.addMonths(new Date(), 0));
    const [loading, setLoading] = useState(false);
    const [is_documents, ] = useState(props.asset.is_documents)
    const [platform, ]=useState(props.asset.platform)
    const [condition, ] = useState(props.asset.condition)
    const [serialNumber, setSerialNumber] = useState(props.asset.serial_nr);
    const [is_in_box, ] = useState(props.asset.is_in_box);
    const [equipment_location, ] = useState(props.asset.equipment_location);
    const [comment, setComment] = useState(props.asset.comment);
    const [purchase_price, ] = useState(props.asset.purchase_price);
    const [purchase_date, ] = useState(refactDate(props.asset.purchase_date));
    const [supplier, ] = useState(props.asset.supplier);
    const [errors, setErrors] = useState([]);
    const [testing_organisation, ] = useState(props.asset.testing_organisation);
    const [testing_end_date,] = useState(props.asset.testing_end_date)


    const duplicateWarehouse = async event => {
        setLoading(true);
        const asset={};
        asset.serial_nr = serialNumber;
        asset.platform = platform;
        asset.is_documents = is_documents;
        asset.condition = condition;
        asset.is_in_box = is_in_box;
        asset.equipment_location = equipment_location;
        asset.comment = comment;
        asset.purchase_price = purchase_price;
        asset.purchase_date = purchase_date;
        asset.supplier = supplier;
        asset.testing_organisation = testing_organisation;
        asset.testing_end_date = testing_end_date;
        event.preventDefault();
        await WarehouseService.newAsset(asset).then(() => {setLoading(false); props.setIsCopyAsset(false); props.setCopyAsset(!props.copyAsset)}).catch((e) => { setErrors(e.message) })

    }
    return (
        <>
        {loading && <Preloader/>}
        <Form id="form" onSubmit={duplicateWarehouse} className="was-validated" >
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }

            
            <Row>
            <h5 className="mb-3">Дублировать устройство</h5>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Серийный номер устройства</Form.Label>
                        <Form.Control size="sm" type="text" required={true} placeholder={'Введите серийный номер'}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Платформа</Form.Label>
                        <Form.Control size="sm" type="text" value={platform} disabled

                        />
                        {/* <Dropdown style={{ border: "1px solid green", height: 31, borderRadius: 6, paddingLeft: '.5rem' }}>
                                <Dropdown.Toggle as={MyToggle} id="dropdown-orgs" >
                                    Организация: {assetOrg || "выбрать"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                    as={MyMenu}
                                    type={'assOrg'}
                                    filterQa={true}
                                >
                                    {allOrganisations?.map(org =>
                                        <Dropdown.Item eventKey={"e" + org.id} key={"e" + org.id} onClick={() => { setAssetOrg(org.name) }}>{org.name}</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown> */}

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">

                        <Form.Label size="sm">Документы</Form.Label>
                        <Form.Control size="sm" type="text" value={is_documents? 'Да': 'Нет'} disabled

                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Состояние</Form.Label>
                        <Form.Control size="sm" type="text" value={condition} disabled
                        />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Коробка</Form.Label>
                        <Form.Control size="sm" type="text" value={is_in_box? 'Да' : 'Нет'} disabled
                        />

                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Находится</Form.Label>
                        <Form.Control size="sm" type="text" value={equipment_location==='warehouse'? 'Склад' : ` ${testing_organisation} - до ${testing_end_date}`} 
                             disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>

            <Row>

                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Коммент</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Коммент"
                            value={comment}
                            size="sm"
                            onChange={(e)=>{setComment(e.target.value)}}
                            />
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Цена</Form.Label>
                        <Form.Control size="sm" type="text" value={purchase_price} 
                             disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Дата покупки</Form.Label>
                        <Form.Control
                            value={purchase_date || ""}
                            size="sm" type="date"
                            disabled
                        />
                        {/* <DatePicker
                                id="datePicker_contract"
                                dateFormat="dd.MM.yyyy"
                                selected={patchDate}
                                onChange={(date) => setPatchDate(date)}
                                locale="ru"
                            /> */}
                    </Form.Group>
                </Col>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Label size="sm">Поставщик</Form.Label>
                        <Form.Control size="sm" type="text" value={supplier || ""} 
                             disabled
                        />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                </Col>
                {/* {assetPlatformType === "VM" && <Col sm={4}>
                    <Form.Group className="mb-4" >
                        <Form.Label size="sm">Гипервизор</Form.Label>
                        <Form.Control
                            value={currentHypervisor?.hv_type || ""}
                            size="sm" 
                            disabled
                        />
                    </Form.Group>
                </Col>} */}
            </Row>

            <Row style={{marginTop: 30}}>
                <Col sm={1} style={{ width: 100 }}>
                    <Form.Group className="mb-4">
                        <Button variant="outline-danger" size="sm" onClick={() => {
                            props.setIsCopyAsset(false);

                        }}>
                            Отменить
                        </Button>
                    </Form.Group>
                </Col>
                <Col >
                    <Form.Group className="mb-4">
                        <Button variant="outline-primary" size="sm" type="submit">
                            Дублировать
                        </Button><br />
                    </Form.Group>
                </Col>
            </Row>

            <hr />

        </Form >
        </>
        
    );
}
export default WarehouseCopyForm;