import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { useNavigate } from "react-router-dom";
import DashboardService from "../../API/DashboardService";
import touchIcon from '../../icons/touch.svg';
import { AuthContext } from "../../context";
function DashboardServ(props) {
    const navigate = useNavigate();
    const [assetsServStat, setAssetsServStat] = useState([])
    const [page, setPage] = useState(null);
    const url = process.env.REACT_APP_API_SERVER;
    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const basicStyles = {
        'fontSize': '10px'
    }
    const linkStyles = {
        'color': 'blue',
        'textDecoration': 'underline',
        'cursor': "pointer",
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap',
        'overflow': 'hidden'
    }

    const contractStatus = (status) => {
        if (status.includes('дн') && (Number(status.split(' ')[0]) <= 14)) return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-danger">{status}</span>;
        else if (status.includes('дн') && (Number(status.split(' ')[0]) > 14)) return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-warning">{status}</span>;
        else return <span style={{ marginRight: 5, width: 40, padding: '3px 0', fontSize: '10px' }} className="badge bg-success">{status}</span>
    }
    const paginationString = (count) => {
        let arr = [];

        let cof = Math.floor(count / 10);
        if (count % 10 === 0) cof = Math.floor(count / 10) - 1;
        if (cof >= 5) cof = 4
        for (let i = 0; i <= cof; i++) { arr.push(i) }
        return arr.map((item, index) => <li key={'serv' + item} className="page-item"><button className="page-link" onClick={() => handleClick(index)}>{index + 1}</button></li>)
    }

    useEffect(() => {
        (async () => {

            await DashboardService.getServs(page).then((res) => setAssetsServStat(res)).catch((e) => changeStatus());
          
        })()
    }, [page])

    const handleClick = (index) => {

        if (index === 0) setPage(null)

        else {
            if (url.includes('local')) setPage(`http://${url}/api/v1/asset-service-stats/?limit=10&ordering=date_end&page=${index + 1}`)
            else setPage(`https://${url}/api/v1/asset-service-stats/?limit=10&ordering=date_end&page=${index + 1}`)
        }

    }

    return (

        <>

            <Table className="mb-3" striped bordered hover>
                <thead style={basicStyles}>
                    <tr>
                        <th >Дата окончания</th>
                        <th >Организация</th>
                        <th >Серийник</th>
                        <th >SLUG</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={basicStyles}>
                    {assetsServStat?.results?.map((item, index) =>
                        <tr key={'assetsStats' + item.id + index}>
                            <td>{contractStatus(item.time_left_verbose)}{item.date_end}</td>
                            <td onClick={() => navigate(`/orglist/${item.equipment__organisation__id}`)}>{item.equipment__organisation__name.length > 25 ? item.equipment__organisation__name.substring(0, 20) + " ..." : item.equipment__organisation__name}</td>
                            <td>{item.equipment__serial_nr}</td>
                            <td>{item.equipment__platform__name}-{item.service__slug}</td>
                            <td> <img width="20" className="img__asset" src={touchIcon} alt="linkToAsset" onClick={() => navigate(`/asset/${item.equipment__id}`)
                            } /></td>
                        </tr>
                    )
                    }

                </tbody>

            </Table >
            <nav aria-label="...">
                <ul className="pagination justify-content-center">
                    {paginationString(assetsServStat.count)}
                </ul>
            </nav>
        </>
    );
}
export default DashboardServ;