export const limit = 20;
const linkStyle = {
    "cursor": 'pointer',
    "color": 'blue',
    'textDecoration': "underline",
}
export const paymentMethod = [
    {type: "prepaid",
    title: 'предоплата 100% '
    },
    {type: "monthly",
    title: 'по-месячно '
    },
    {type: "quarterly",
    title: 'по-квартально '
    },
]
export const organisationRating = (rating, org) => {
    let badge;
    // eslint-disable-next-line default-case
    switch(rating) {
        default:
            badge ='bg-dark'
            break;
        case(4):
        badge ='bg-info'
            break;
        ;
        case(3):
        badge ='bg-success'
        break;
        ;
        case(2):
        badge ='bg-primary'
        break;

        ;
        case(1):
        badge ='bg-danger'
        break;
        
      
    }
return <div style={{display: "flex", flexWrap: 'nowrap'}}><span style={{ marginRight: 5,  padding: '3px 5px', fontSize: '10px', boxSizing:'border-box', height: '16px' }} className={`badge ${badge} `}>{rating}</span><span style={linkStyle}>{org}</span></div>;
}