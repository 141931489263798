/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import Table from 'react-bootstrap/Table';
import ServiceService from "../../API/ServiceService";
import Preloader from "../UI/Preloader/Preloader";
import ImgEdit from '../../icons/edit.png';
import MyModal from "../UI/MyModal/MyModal";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "react-bootstrap/esm/Button";
import AddServiceForm from "./AddServiceForm";
import EditServiceForm from "./EditServiceForm";
import ImgRemove from '../../icons/remove.png';
import Alert from 'react-bootstrap/Alert';
import { AuthContext } from "../../context";
import ImgSave from '../../icons/save.svg';
import ServiceDeleteForm from "./ServiceDeleteForm";

const ServiceList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }
    const [addService, setAddService] = useState(false);
    const [currentService, setCurrentService] = useState({});
    const [editService, setEditService] = useState(false);
    const [deleteService, setDeleteService] = useState(false)
    const [serviceList, setServiceList] = useState([]);
    const [isPreloader, setIsPreloader] = useState(false);
    const [serviceAmount, setServiceAmount] = useState(0);
    const [succesDelete, setSuccessDelete] = useState(false);
    const [errors, setErrors] = useState([]);


    //пагинация 
    const [needToLoad, setNeedToLoad] = useState(false);
    const lastItem = useRef();
    const observer = useRef();
    const [isServiceLoading, setIsServiceLoading] = useState(false);
    const [nextPage, setNextPage] = useState(null);

    //контекст
    const { setStatus } = useContext(AuthContext);
    const changeStatus = () => setStatus(true);
    const typeFileApp = (value) => {
        let type = ''
        switch (value) {
            case 'txt': type = ''; break;
            case 'zip': type = 'application/zip'; break;
            case 'xlsx': type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'; break;
            case 'xls': type = 'application/vnd.ms-excel'; break;
            case 'doc': type = 'application/msword'; break;
            case 'docx': type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'; break;
            default: type = `application/${value}`;
        }
        return type
    }

    useEffect(() => {
        (async () => {
            setNextPage(null)
            setIsServiceLoading(true)
            setIsPreloader(true);
            let filters = false;
            if (filters) {
                filters.searchText = false;
            }
            await ServiceService.getServiceList(false).then((res) => {
                setServiceList(res.results);
                setServiceAmount(res.count)
                setNextPage(res.next);
                setIsServiceLoading(false)
            }).catch(() => changeStatus());
            setIsPreloader(false)

        })();
    }, [addService, editService, succesDelete]);

    useEffect(() => {

        if (isServiceLoading) return;

        if (observer.current) observer.current.disconnect();

        var callback = function (entries, observer) {
            if (entries[0].isIntersecting && nextPage) {
                setNeedToLoad(true);
            }
        };
        observer.current = new IntersectionObserver(callback);
        observer.current.observe(lastItem.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServiceLoading]);
    useEffect(() => {

        (async () => {

            if (needToLoad) {

                setIsPreloader(true);
                setIsServiceLoading(true);
                await ServiceService.getServiceList(nextPage).then((res => {
                    res.results?.map(item => setServiceList(arr => [...arr, item]));
                    setNextPage(res.next);
                    setIsServiceLoading(false);
                    setNeedToLoad(false);
                })).finally(() => setIsPreloader(false));

            }
        })();
    }, [needToLoad]);

    // const removeService = async (serviceId) => {

    //     await ServiceService.deleteService(serviceId).then(() => { setSuccessDelete(!succesDelete); setErrors([]) }).catch((err) => setErrors(err.message))
    // }
    const downloadFile = async (file) => {
        var now = (new Date()).toISOString();
        now = now.replace('.', '-');
        const string = file.sla_document.slice(-5);
        const index = string.indexOf('.');
        const response = await ServiceService.downloadDocs(file);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: typeFileApp(string.slice(index + 1)) }));
        const link = document.createElement('a');
        const effectiveFileName = `sla-${file.slug}-${now}`;
        link.setAttribute('href', url)
        link.setAttribute('download', effectiveFileName)
        link.style.display = 'none'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)

    }

    return (
        <>
            {errors.length > 0 &&
                errors.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            <Row className="mb-3">
                <h5>Сервисы (всего {serviceAmount})</h5>
            </Row>
            <Row>
                <Col className="mb-3 col-2">
                    <Button variant="outline-success" size="sm" style={{ fontSize: '1rem', width: '100%' }}
                        onClick={() => setAddService(true)}

                    >Добавить сервис</Button>
                </Col>
            </Row>

            {addService === true && <AddServiceForm setAddService={setAddService} addService={addService} />}
            {editService === true &&
                <MyModal visible={editService} setVisible={setEditService}>

                    <EditServiceForm setEditService={setEditService} editService={editService} currentService={currentService} />

                </MyModal>}
            <Table striped bordered hover style={{ position: 'relative' }}>
                {deleteService === true &&
                    <MyModal visible={deleteService} setVisible={setDeleteService}>

                        <ServiceDeleteForm setSuccessDelete = {setSuccessDelete} setDeleteService = {setDeleteService} currentService={currentService} />

                    </MyModal>
                }
                <thead style={basicStyles}>
                    <tr>
                        <th >SLUG</th>
                        <th >RMA</th>
                        <th >Описание</th>
                        <th >Контакты Сервисного центра</th>
                        <th >SLA</th>
                        <th >SLA Файл</th>
                        <th >Изменить</th>
                        <th >Удалить</th>
                    </tr>
                </thead>
                <tbody>
                    {serviceList?.map(item => (
                        <tr style={basicStyles} key={'service' + item.id}>

                            <td >{item.slug}</td>
                            <td>{item.rma === true ? 'Да' : 'Нет'}</td>
                            <td>{item.description}</td>
                            <td>{item.service_center_contact}</td>
                            <td>{item.sla_description}</td>
                            <td> {item.sla_document !== null && <img width="20" className="interactive_hover" alt={'save'} src={ImgSave} disabled onClick={
                                () => {

                                    downloadFile(item)
                                }
                            } />}
                            </td>
                            <td><img width="20" className="interactive_hover" alt={'edit'} src={ImgEdit} disabled onClick={
                                () => {

                                    setCurrentService(item);
                                    setEditService(true)
                                }
                            } />
                            </td>
                            <td><img width="20" className="interactive_hover" alt="delete" src={ImgRemove} disabled onClick={
                                () => {
                                    setCurrentService(item)
                                    setDeleteService(true)

                                }
                            } /> </td>
                        </tr>
                    ))}
                    <tr ref={lastItem} style={{ height: 20 }} ></tr>
                </tbody>
            </Table >
            {isPreloader && <Preloader />}
        </>
    )
}

export default ServiceList;