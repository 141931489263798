import React, {useState } from "react";
import Table from 'react-bootstrap/Table';
import ImgEdit from '../../icons/edit.png';
import ImgRemove from '../../icons/remove.png';
import MyModal from "../UI/MyModal/MyModal";
import SolutionAddForm from "./SolutionAddForm";
import SolutionEditForm from "./SolutionEditForm";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SolutionDeleteForm from "./SolutionDeletingForm";

function SolutionList(props) {

    const [solutionEditing, setSolutionEditing] = useState(false);
    const [solutionAdding, setSolutionAdding] = useState(false);
    const [solutionDeleting, setSolutionDeleting] = useState(false)
    const [currentSolution, setCurrentSolution] = useState(null);
    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (

        <>
            {solutionEditing &&
                <MyModal visible={solutionEditing} setVisible={setSolutionEditing}>

                    <SolutionEditForm
                        solution={currentSolution}
                        setVisible={setSolutionEditing}
                        setEditSol={props.setSolutionEdit}
                        solEdit={props.solutionEdit}
                    />

                </MyModal>}
            {solutionAdding && <MyModal visible={solutionAdding} setVisible={setSolutionAdding}>


                <SolutionAddForm setAddSolution={setSolutionAdding} setSolutionAdd={props.setSolutionAdd} solutionAdd={props.solutionAdd} />


            </MyModal>}

            {solutionDeleting && <MyModal visible={solutionDeleting} setVisible={setSolutionDeleting}>
                <SolutionDeleteForm 
                solution={currentSolution} 
                setVisible={setSolutionDeleting}
                setDeleteSol = {props.setSolutionDelete}
                solDel = {props.solutionDelete}
                />
            </MyModal>}
            <Row>
                <Col className="mb-3 col-3 ">
                    <Button style={{ width: '100%' }} onClick={() => { setSolutionAdding(true) }} variant="outline-dark" > Добавить решение</Button>
                </Col>
            </Row>
            <Table className="mb-5">
                <thead style={basicStyles}>
                    <tr>
                        <th sm={1}>ID</th>
                        <th sm={5}>Название</th>
                        <th sm={1}>Описание</th>
                        <th sm={1}>Изменить</th>
                        <th sm={1}>Удалить</th>

                    </tr>
                </thead>
                <tbody style={basicStyles}>
                    {
                        props.solution?.map(solution =>
                            <tr key={solution.id}>
                                <td>
                                    {solution.id}
                                </td>
                                <td>
                                    {solution.name}
                                </td>
                                <td>
                                    {solution.description}
                                </td>
                                <td>
                                    <img width="20" className="interactive_hover" src={ImgEdit} disabled alt={"edit"} onClick={
                                        () => {
                                            setSolutionEditing(true);
                                            // props.setCategoryName(category.name);
                                            // props.setCategoryDiscount(category.discount);
                                            // props.setCategoryDescription(category.description);
                                            setCurrentSolution(solution)
                                        }
                                    } />
                                </td>
                                <td>
                                    <img width="30" src={ImgRemove} alt="remove-solution" onClick={() => {
                                        setSolutionDeleting(true);
                                        setCurrentSolution(solution)
                                    }} />
                                </td>
                            </tr>
                        )

                    }

                </tbody>

            </Table>

        </>
    );
}
export default SolutionList;