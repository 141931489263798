import Api from '.';

export default class Auth {

    static async getToken(email, pass) {
        const url = 'api/auth/token/login/';
        const method = 'POST';
        const data = { email: email, password: pass};
        const api = new Api({url: url});
        const response = await api.request(method, data);
        const token = response.data;
        return token;
    }

    static async verifyToken(token) {
        const url = 'api/auth/token/verify/';
        const method = 'POST';
        const data = { token: token};
        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response;
    }
    static async refreshToken(token) {
        const url = 'api/auth/token/refresh/';
        const method = 'POST';
        const data = { refresh: token};
        const api = new Api({url: url});
        const response = await api.request(method, data);
       
        return response
        
    }
    static async passRecover(email) {

        const url = 'api/v1/auth/users/reset_password/';
        const method = 'POST';
        const data = {email: email};

        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    }

    static async passRecoverConfirm(uid, token, new_password, re_new_password) {

        const url = 'api/v1/auth/users/reset_password_confirm/';
        const method = 'POST';
        const data = {uid: uid, token: token, new_password: new_password, re_new_password: re_new_password};

        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    }


    static async passSet(newPass, curPass) {

        const url = 'api/v1/auth/users/set_password/';
        const method = 'POST';
        const data = {new_password: newPass, current_password: curPass};

        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.status;
    }

}