import Api from '.';

export default class User {

    static async getMe() {
        const url = 'api/v1/users/me/';
        const method = 'GET';

        const api = new Api({url: url});
        const response = await api.request(method);
        const userdata = response.data;
        const userinfo = {
            'username': userdata.username,
            'first_name': userdata.first_name,
            'last_name': userdata.last_name,
            'job_title': userdata.job_title,
            'phone': userdata.phone,
            'email': userdata.email,
            'organization': userdata.organisation.name,
            'organizationType': userdata.organisation.type,
            'isActive': userdata.is_active,
            'role': userdata.role,
        };
        return userinfo;
    };

    static async deactivate(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = {"is_active": false};

        const api = new Api({url: url});
        const response = await api.request(method, data);

        return response.data;
    };

    static async activate(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = {"is_active": true};

        const api = new Api({url: url});
        const response = await api.request(method, data);

        return response.data;
    };

    static async makePrimary(id) {
        const url = `api/v1/users/${id}/`;
        const method = 'PATCH';
        const data = {"role": "PRIMARY_USER"};

        const api = new Api({url: url});
        const response = await api.request(method, data);

        return response.data;
    };

    static async new(user, orgId) {
        var url = 'api/v1/users/';
        if (orgId === "me") {
            url = 'api/v1/users/colleague_create/';
        };
        const method = 'POST';
        const data = user;
        data.is_active = false;
        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response.data;
    }

    static async updateMe(data) {
        const url = 'api/v1/users/me/';
        const method = 'PATCH';
        const api = new Api({url: url});
        const response = await api.request(method, data);
        return response;
    };

}