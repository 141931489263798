import React from "react";
import { useLocation } from "react-router-dom";

const MyToggle = React.forwardRef(({ children, onClick }, ref) => {

  const location= useLocation();
  return (
    <a
    id ={location.pathname.includes('/contract') || location.pathname.includes('asset') || location.pathname.includes('quota')? "contract" : ''}
      href="#none"
      
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {/* &#x25bc; */}
    </a>
)});

export default MyToggle;