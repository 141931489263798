import Api from '.';
import { limit } from '../utils/constants';
export default class ContractService {

    static async getList(id, filters, nextpage, enduser) {
        var url =  'api/v1/contracts/'
        const method = 'GET';
        const params = {};
        if (filters) {
            if (filters.paymentFilter) {
                params.payment_method = filters.paymentFilter;
            };
            if (filters.searchText) {
                params.search = filters.searchText;
            };
            if (filters.statusFilter) {
                params.status = filters.statusFilter;
            }
        };

        if (nextpage) {
            params.page = nextpage;
        }
        if (id) {
            params.page= 'all'
            if (!enduser) params.contract_partner_org__id=id
            else params.contract_enduser_org__id=id
        }
        params.limit = limit;
        const api = new Api({ url: url, params: params });
        const response = await api.request(method);
        return response.data;
    };

    static async newContract(contract) {

        const url = 'api/v1/contracts/';
        const method = 'POST';
        const data = contract;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    };

    static async getItem(id) {

        const url = `api/v1/contracts/${id}/`;
        const method = 'GET';

        const api = new Api({ url: url });
        const response = await api.request(method);
        return response.data;
    }
    
    static async patchContract(contract) {
        const url  = `api/v1/contracts/${contract.id}/`;
        const data =contract;

        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
       
        return response.data;
    }
    static async deleteContract(id ) {
        const url  = `api/v1/contracts/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
       
        return response.data;
    }

}