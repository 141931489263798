import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import SolutionService from "../../API/SolutionService";
import Preloader from "../UI/Preloader/Preloader";

function SolutionAddForm(props) {
    const [isPreloader, setIsPreloader] = useState(false);
    const [solutionName, setSolutionName] = useState('');
    const [solutionDescription, setSolutionDescription] = useState('');
    const [errors, setErrors] = useState('');

    const addSolution = async event => {
        event.preventDefault()
        setIsPreloader(true);
        const item = {};
        item.name = solutionName;
        if (solutionDescription !== "") item.description = solutionDescription;
        await SolutionService.newItem(item).then((data) => { setTimeout(() => {  setIsPreloader(false); props.setSolutionAdd(!props.solutionAdd); props.setAddSolution(false) }, 1000) }).catch((e) => { setIsPreloader(false); setErrors(e.message) })
    }


    return (
        <Form onSubmit={addSolution} className='was-validated' style={{ position: 'relative', width:'100%' }}>
            {errors?.length > 0 &&
                errors?.map(error =>
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {isPreloader && <Preloader />}
            <hr />
            <Row style={{width: '400px'}}>
                <h3 className="mb-3">Добавить решение</h3>
                <Col>
                    <Form.Group controlId="catNameField" >
                        <Form.Label>Название</Form.Label>
                        <Form.Control size="sm" type="text"
                            required={true}
                            value={solutionName}
                            placeholder="Введите.." onChange={(e) => setSolutionName(e.target.value)} />
                        <Form.Text muted>

                        </Form.Text>
                    </Form.Group></Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Описание (опционально)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            type="text"
                            placeholder="Описание "
                            value={solutionDescription}
                            size="sm"
                            onChange={(e) => setSolutionDescription(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Button variant="outline-primary" size="sm" type="submit " onClick={(e) => { e.preventDefault(); addSolution(e) }} style={{ width: '100%' }}>
                        Добавить
                    </Button>

                </Col>
                <Col sm={6}>
                    <Button variant="outline-danger" size="sm" onClick={(e) => { e.preventDefault(); props.setAddSolution(false) }} style={{ width: '100%' }}>
                        Отменить
                    </Button>
                </Col> </Row>
            <hr />
        </Form>

    );
}
export default SolutionAddForm;