import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OrgService from "../../API/OrgService";
import Alert from 'react-bootstrap/Alert';
import Preloader from "../UI/Preloader/Preloader";

function OrgCreateForm(props) {

    const [isPreloader, setIsPreloader] = useState(false);
    const [orgName, setOrgName] = useState(props?.org?.name || "");
    const [vat, setVat] = useState(props?.org?.vat || "");
    const [website, setWebSite] = useState(props?.org?.website || "");
    const [email, setEmail] = useState("");
    const [firstName, setFristName] = useState();
    const [lastName, setLastName] = useState();
    const [errors, setErrors] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [orgType, setOrgType] = useState(props?.org?.type || props?.orgAddingType)

    const createItem = async event => {
    
        setIsPreloader(true)
        event.preventDefault();
        const org = {};
        org.type = props.orgAddingType;
        org.name = orgName;
        if (vat !== "") {
            org.vat = vat;
        };
        if (website !== "") org.website = website;
        if (lastName && firstName && email) {

            org.users = [{
                "email": email,
                "password": email,
                "first_name": firstName,
                "last_name": lastName
            }];
        };

        if (props?.org) {

            await OrgService.patchOrg(false, props.org.id, org).then(() => {
                setTimeout(() => {
                    props.setIsOrgAdding(false); setIsPreloader(false); setErrors([])
                }, 1500)
            }).catch((e) => { setIsPreloader(false); setErrors(e.message) })
        } else {
            await OrgService.newOrg(false, false, org)
            .then(() => {
                props.setOrgAdded(true);
                props.setIsOrgAdding(false);
                if (org.type === "ENDUSER") {
                    if (props.setCurEndUserFilter!==undefined)props.setCurEndUserFilter(org.name)
                    if (props?.setEndUserFilter!==undefined)props?.setEndUserFilter(org.name)
                };
                if (org.type === "PARTNER") {
                    if (props.setCurPartnerFilter!==undefined)props.setCurPartnerFilter(org.name)
                    if (props?.setPartnerFilter!==undefined) props?.setPartnerFilter(org.name)
                };
            
                setErrors([]);
            })
            .catch((e) => { setIsPreloader(false); setErrors(e.message)})
        }
    }

    return (
        <Row className="mb-3">
            {isPreloader && <Preloader />}
            <Col sm={4}>
                <Form onSubmit={createItem}>
                    <hr />
                    {errors?.length > 0 &&
                        errors?.map((error, index) =>(
                            <Alert key={`'danger' + ${index}`} variant="danger">
                                {error}
                            </Alert>)
                        )
                    }
                    <h5>Добавление новой организации</h5>
                    {
                        props.allTypes
                            ?
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Тип организации</Form.Label>
                                <Form.Select size="sm" onChange={(e) => props.setOrgAddingType(e.target.value)}>
                                    <option value="ENDUSER">Конечный пользователь</option>
                                    <option value="PARTNER">Партнер</option>
                                </Form.Select>
                            </Form.Group>
                            :
                            <Form.Group className="mb-4">
                                <Form.Label size="sm">Тип организации</Form.Label>
                                <Form.Control size="sm" value={orgType} disabled />
                            </Form.Group>

                    }

                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Наименование</Form.Label>
                        <Form.Control size="sm" type="text" value={orgName || ""} onChange={(e) => setOrgName(e.target.value)} />
                        <Form.Text className="text-muted">
                            пример: ООО "Ромашка"
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">ИНН (опционально)</Form.Label>
                  
                        <Form.Control size="sm" type="text" value={vat || ""} onChange={(e) => setVat(e.target.value)} />
                        <Form.Text className="text-muted">
                            введите 9, 10 или 12 цифр без разделителей
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Веб-сайт (опционально)</Form.Label>
                     
                        <Form.Control size="sm" type="text" value={website} onChange={(e) => setWebSite(e.target.value)} />
                        <Form.Text className="text-muted">
                            пример: https://www.test-org.ru
                        </Form.Text>
                    </Form.Group>
               
                    <Row>

                        <Col className="col-md-auto">
                            <Button style={{width: 90}} variant="outline-success" type="submit" size="sm">
                                Создать
                            </Button></Col>
                        <Col className="col-md-auto"><Button style={{width: 90}} variant="outline-danger" onClick={(e) => props.setIsOrgAdding(false)} size="sm">
                                Отменить
                        </Button></Col>
                    </Row>
                </Form>
            </Col>
            {!props.org && (<Col sm={4}>
                <Form onSubmit={createItem}>
                    <hr />
                    <h5>Основной контакт (опционально)</h5>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Фамилия</Form.Label>
                        <Form.Control size="sm" type="text" value={lastName|| ""} onChange={(e) => setLastName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">Имя</Form.Label>
                        <Form.Control size="sm" type="text" value={firstName || ""} onChange={(e) => setFristName(e.target.value)} />

                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label size="sm">email</Form.Label>
                        <Form.Control size="sm" type="text" value={email || ""} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                </Form>
            </Col>)}
        </Row>
    );
}
export default OrgCreateForm;