import React from "react";
import Table from 'react-bootstrap/Table';
import FortimanagersItem from "./FortimanagersItem";

const FortimanagersList = (props) => {

    const basicStyles = {
        "fontSize": 'smaller',
    }

    return (
        <>
            <Table striped bordered hover id='tableExcell' >
                <thead style={basicStyles}>
                    <tr>

                        <th>Название</th>
                        <th>Хост</th>
                        <th>Изменено</th>
                        <th style={{width:'5%'}}>Загрузить</th>
                        <th style={{width:'5%'}}>Изменить</th>
                        <th style={{width:'5%'}}>Удалить</th>
                    </tr>
                </thead>
                <tbody>

                    {props.fortimanagersItems?.map((item, index) =>

                        <FortimanagersItem
                            item={item}
                            key={item.id + "fortIt" + index}
                            close={props.close}
                            setIsEditItem={props.setEditItem}
                            setCurrentItem={props.setCurrentItem}
                            setIsFileUploading = {props.setUploadingFile}
                            removeItem={props.removeItem}
                            setErrors={props.setErrors}
                            setItemDetails = {props.setFortimanagerDetails}
                        />
                    )
                    }
                </tbody>
            </Table></>
    )
}

export default FortimanagersList;

