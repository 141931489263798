import Api from ".";

export default class LicenseService {
    static async getFortimanagersList(page){
        const url = `api/v1/licence/fortimanager/hosts/?limit=20&page=${page}`
        const method = 'GET'
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    }

    static async newFortimanager(fortimanager){
        const url = 'api/v1/licence/fortimanager/hosts/';
        const method = 'POST';
        const data = fortimanager;
        const api = new Api({ url: url });
        const response = await api.request(method, data);
        return response.data;
    }

    static async patchFortimanager(fortimanager, id){
        const url  = `api/v1/licence/fortimanager/hosts/${id}/`;
        const data = fortimanager;
        const method = 'PATCH';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
        return response.data;
    }

    static async deleteFortimanager(id){
        const url = `api/v1/licence/fortimanager/hosts/${id}/`;
        const method = 'DELETE';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
        return response.status;
    }


    static async fortimanagerDetails(id){
        const url  = `api/v1/licence/fortimanager/hosts/${id}/`;
        const method = 'GET';
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method);
        return response.data;
    }

    static async addLicense(fortimanager, lic_file){
        const url = `api/v1/licence/fortimanager/hosts/${fortimanager.id}/upload/`;
        const method = 'POST';
        const data = lic_file;
        const api = new Api({ url: url, headers: {}, params: {} });
        const response = await api.request(method, data);
        return response;

    }

    static async getHistoryList(page){
        const url = `api/v1/licence/fortimanager/upload-history/?limit=20&page=${page}`
        const method = 'GET'
        const api = new Api({ url: url});
        const response = await api.request(method);
        return response.data;
    }
}