import React, { useState, useEffect} from "react";
import PriceService from "../../API/PriceService";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';


function PriceVersionList(props) {

    const basicStyles = {
        "fontSize": 'smaller',
    }
    const [priceVersions, setPriceVersions] = useState();
    const [errors, setErrors] = useState([]);
    const [message, setMessage] = useState(false);
    const [needToReload, setNeedToReload] = useState(false);

    async function switchVersion(id) {
        try {
            await PriceService.switchVersion(id);
            props.setSwitchPriceVersion(false);
            props.setPriceChanged(true);
            setErrors([]);
        } catch(e) {
            setErrors(e.message);
        };
        
    }

    async function deleteVersion(id) {
        try {
            await PriceService.deleteVersion(id);
            setMessage("Версия прайс-листа успешно удалена.");
            setErrors([]);
            setNeedToReload(true);
        } catch(e) {
            setErrors(e.message);
        };
        
    }

    useEffect(() => {

        (async () => {
            const versions = await PriceService.getVersionList(1);
            setPriceVersions(versions);
            setNeedToReload(false);
        })();

    }, [needToReload]);


    return (
        <Table>
            {errors.length > 0 &&
                errors.map(error => 
                    <Alert key="danger" variant="danger">
                        {error}
                    </Alert>
                )
            }
            {message &&
                <Alert key="primary" variant="primary">
                    {message}
                </Alert>
            }
            <thead style={basicStyles}>
                <tr>
                    <th sm={1}>ID</th>
                    <th sm={2}>Дата создания</th>
                    <th sm={2}>Кем</th>
                    <th sm={3}>Описание</th>
                    <th sm={1}>Позиций</th>
                    <th sm={1}>Статус</th>
                    <th sm={1}></th>
                </tr>
            </thead>
            <tbody style={basicStyles}>
                {
                    priceVersions?.map(version => 
                        <tr key={version.id}>
                            <td>
                                {version.id}
                            </td>
                            <td>
                                {version.date_created}
                            </td>
                            <td>
                                {version.created_by}
                            </td>
                            <td>
                                {version.comment}
                            </td>
                            <td>
                                {version.items_count} шт
                            </td>
                            <td>
                                {version.is_active 
                                ? "Активный" 
                                :   
                                    <Form.Group controlId="formFile" className="mb-4">
                                        <Button variant="outline-primary" size="sm" onClick={(e) => {e.preventDefault(); switchVersion(version.id);}}>
                                            Переключиться
                                        </Button>
                                        <Button variant="outline-danger" size="sm" onClick={(e) => {e.preventDefault(); deleteVersion(version.id);}}>
                                            Удалить
                                        </Button>
                                    </Form.Group>
                                }
                            </td>

                        </tr>
                    )
                }
                
            </tbody>
        </Table>
    );
}
export default PriceVersionList;